<h1 class="leading-none mb-4">Xin chào!</h1>
<p class="text-gray-500 text-base leading-6 mb-8">
  Tiếp tục với email hoặc username <span class="inline-block">và mật khẩu để đăng nhập</span>
</p>
<form nz-form [formGroup]="form" (ngSubmit)="onClickLogin()" nzLayout="vertical">
  <nz-form-item>
    <nz-form-label>Email hoặc username</nz-form-label>
    <nz-form-control [nzErrorTip]="errorEmail">
      <nz-input-group [nzSuffix]="suffixEmail" nzSize="large">
        <input
          type="text"
          nz-input
          formControlName="email"
          [placeholder]="'common.usernameOrEmail' | translate"
          class="h-full"
        />
      </nz-input-group>
      <ng-template #suffixEmail>
        <ng-container *ngIf="!form.get('email').pristine">
          <i
            nz-icon
            class="!text-tryme-green"
            nzType="icons/outline:check-all"
            *ngIf="form.get('email').valid"
          ></i>
        </ng-container>
      </ng-template>
    </nz-form-control>
    <ng-template #errorEmail let-control>
      <p *ngIf="control.hasError('required')">
        {{ 'form.validate.require' | translate: { field: 'email hoặc username' } }}
      </p>
    </ng-template>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Mật khẩu</nz-form-label>
    <nz-form-control [nzErrorTip]="errorPassword">
      <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          nz-input
          formControlName="password"
          [placeholder]="'common.password' | translate"
        />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <i
          nz-icon
          class="!text-gray-500"
          nzType="icons/outline:{{passwordVisible ? 'hide' : 'show'}}"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </ng-template>
    </nz-form-control>
    <ng-template #errorPassword let-control>
      <p *ngIf="control.hasError('minlength')">Mật khẩu tối thiếu 8 ký tự</p>
      <p *ngIf="control.hasError('required')">
        {{
          'form.validate.require' | translate: { field: 'common.password' | translate | lowercase }
        }}
      </p>
    </ng-template>
  </nz-form-item>
  <p class="text-right mb-10">
    <button nz-button nzType="link" type="button" (click)="onClickForgotPassword()" class="!p-0">
      {{'forgot-password' | translate}}
    </button>
  </p>
  <button
    nz-button
    nzSize="large"
    nzType="primary"
    nzBlock
    class="rounded-lg !mb-6"
    [nzLoading]="loading$ | async"
  >
    <strong class="text-base">{{ 'login.txtLogin' | translate }}</strong>
  </button>
  <footer class="text-center">
    <span class="text-gray-500 mr-1">{{ 'login.txtNotAccount' | translate }}</span>
    <a (click)="onClickRegister()" class="text-tryme-primary font-semibold">{{
      'login.txtRegister' | translate
    }}</a>
  </footer>
</form>
