import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { NgxsModule } from '@ngxs/store'

import { ConfirmDialogModule } from '../../ui/confirm-dialog/confirm-dialog.module'
import { AuthState } from './store/state'
import { authRoutes } from './util'

@NgModule({
  imports: [
    NgxsModule.forFeature([AuthState]),
    RouterModule.forChild(authRoutes),
    ConfirmDialogModule
  ]
})
export class AuthModule {}
