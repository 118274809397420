import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SuccessDialogComponent } from './success-dialog.component'
import { NzIconModule } from 'ng-zorro-antd/icon'

const nzModules = [NzIconModule]

@NgModule({
  declarations: [SuccessDialogComponent],
  imports: [CommonModule, nzModules],
  exports: [SuccessDialogComponent]
})
export class SuccessDialogModule {}
