import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SegmentTrackingService {
  writeKey = environment.writeKey
  env = environment.production
  private analytics: AnalyticsBrowser
  userId = ''

  connect() {
    this.analytics = AnalyticsBrowser.load({ writeKey: this.writeKey })
  }

  page(category: string, properties?: any) {
    if (this.userId) {
      properties.viewer = this.userId
    }
    return this.analytics.page(category, properties)
  }

  pageView() {
    this.analytics.pageView(window.location.href)
  }

  identity(userId) {
    this.analytics.identify(userId)
  }

  alias(data: any) {
    this.analytics.alias(data)
  }

  track(eventName: string, data: any) {
    if (this.userId) {
      data.viewer = this.userId
    }
    this.analytics.track(eventName, data)
  }

  trackSubmit(form: any, eventName: string, properties: any) {
    if (this.userId) {
      properties.viewer = this.userId
    }
    this.analytics.trackSubmit(form, eventName, properties)
  }

  trackClick(element: any, event: string, properties: any) {
    if (this.userId) {
      properties.viewer = this.userId
    }
    this.analytics.trackClick(element, event, properties)
  }

  setUserId(userId: string) {
    this.userId = userId
  }

  onRemoveUserId() {
    delete this.userId
  }
}
