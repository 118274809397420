<h1 class="leading-none mb-4">Đặt mật khẩu</h1>
<p class="text-gray-500 text-base mb-8">Đặt lại mật khẩu mới</p>
<form nz-form [formGroup]="form" (ngSubmit)="onClickReset()" nzLayout="vertical">
  <nz-form-item>
    <nz-form-label>Mật khẩu mới</nz-form-label>
    <nz-form-control [nzErrorTip]="errorPassword">
      <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          nz-input
          formControlName="password"
          placeholder="Nhập mật khẩu mới"
        />
      </nz-input-group>
      <ng-template #suffixTemplate>
        <i
          nz-icon
          class="!text-gray-500"
          nzType="icons/outline:{{ passwordVisible ? 'hide' : 'show' }}"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </ng-template>
    </nz-form-control>
    <p
      class="text-gray-500 text-xs text-right leading-5 mb-0"
      *ngIf="form.get('password').pristine"
    >
      Mật khẩu mới phải có ít nhất 8 kí tự phải gồm chữ và số
    </p>
    <ng-template #errorPassword let-control>
      <ng-container *ngIf="control.touched && control.dirty">
        <div *ngIf="control.hasError('required')">
          {{
            'form.validate.require'
              | translate: { field: 'mật khẩu mới' }
          }}
        </div>
        <div *ngIf="control.hasError('minlength')">
          {{
            'form.validate.minLength' | translate: { field: 'Mật khẩu mới', min: 8 }
          }}
        </div>
        <div *ngIf="control.hasError('maxlength')">Mật khẩu mới không vượt quá 70 kí tự</div>
        <div *ngIf="control.hasError('pattern')">Mật khẩu mới phải có ít nhất 1 chữ và 1 số</div>
      </ng-container>
    </ng-template>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Nhập lại mật khẩu mới</nz-form-label>
    <nz-form-control [nzErrorTip]="errorRepassword">
      <nz-input-group [nzSuffix]="suffixRepasswordTemplate" nzSize="large">
        <input
          [type]="repasswordVisible ? 'text' : 'password'"
          nz-input
          formControlName="repassword"
          placeholder="Xác nhận mật khẩu mới"
        />
      </nz-input-group>
      <ng-template #suffixRepasswordTemplate>
        <i
          nz-icon
          class="!text-gray-500"
          nzType="icons/outline:{{ repasswordVisible ? 'hide' : 'show' }}"
          (click)="repasswordVisible = !repasswordVisible"
        ></i>
      </ng-template>
    </nz-form-control>
    <ng-template #errorRepassword let-control>
      <p *ngIf="control.hasError('required')">
        {{ 'form.validate.require' | translate: { field: 'Mật khẩu xác nhận' } }}
      </p>
      <p *ngIf="control.hasError('isMatching')">Mật khẩu xác nhận phải khớp với mật khẩu mới</p>
    </ng-template>
  </nz-form-item>
  <button
    nz-button
    nzSize="large"
    nzType="primary"
    nzBlock
    class="rounded-lg !mb-6"
    [disabled]="loading$ | async"
  >
    <strong class="text-base">{{'confirm' | translate}}</strong>
  </button>
</form>
