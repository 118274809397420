/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateQuestionDTO } from './create-question-dto';
import { ColorModel } from './color-model';


export interface CreateQuizDTO { 
    avatarUrl?: string;
    timer?: number;
    privacy: CreateQuizDTO.PrivacyEnum;
    commentable: boolean;
    questions: Array<CreateQuestionDTO>;
    description: string;
    topicId?: string;
    color: ColorModel;
    soundId?: string;
}
export namespace CreateQuizDTO {
    export type PrivacyEnum = 'PP_EVERYONE' | 'PP_FOLLOWER' | 'PP_ONLY_ME';
    export const PrivacyEnum = {
        EVERYONE: 'PP_EVERYONE' as PrivacyEnum,
        FOLLOWER: 'PP_FOLLOWER' as PrivacyEnum,
        ONLYME: 'PP_ONLY_ME' as PrivacyEnum
    };
}


