import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ReactiveFormsModule } from '@angular/forms'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { AppSearchComponent } from './app-search.component'
import { NzIconModule } from 'ng-zorro-antd/icon'

const nzModules = [
  NzLayoutModule,
  NzGridModule,
  NzInputModule,
  NzDropDownModule,
  NzSkeletonModule,
  NzAvatarModule,
  NzIconModule
]

@NgModule({
  declarations: [AppSearchComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, nzModules],
  exports: [AppSearchComponent]
})
export class AppSearchModule {}
