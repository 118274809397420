<ul class="w-full max-h-[352px] overflow-auto flex flex-wrap gap-3 justify-center mb-4" *ngLet="me$ | async as me">
  <ng-container *ngIf="{statisticsPaging: statisticsPaging$ | async} as view">
    <li *ngFor="let answer of view.statisticsPaging.data" class="inline-flex justify-between items-center min-w-[80%] h-[72px]
        px-6 py-4 gap-x-4 rounded-xl relative text-2xl bg-white" [ngClass]="{
        'border-tryme-green': answer.correct,
        'border-tryme-primary': answer.correct === false,
        'border-2': answer.selected,
        border: answer.selected === false
      }">
      <div class="absolute top-0 left-0 rounded-xl h-full" [ngClass]="{
          'bg-tryme-green': answer.correct,
          'bg-tryme-primary': answer.correct === false,
          'bg-opacity-20': answer.selected,
          'bg-opacity-10': answer.selected === false
        }" [style.width]="answer.total / (joined || 1) | percent"></div>
      <strong class="text-gray-700 z-10">{{ answer.answer }}</strong>
      <div class="flex items-center space-x-2 z-10">
        <nz-avatar [nzSrc]="me.avatar" [nzSize]="24" *ngIf="me && answer.selected"></nz-avatar>
        <strong class="text-gray-800">
          {{ answer.total | numberToText }}/{{ joined | numberToText }}
        </strong>
      </div>
    </li>
  </ng-container>
</ul>
