import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CloseDialogDirective } from './close-dialog.directive'
import { NzModalModule } from 'ng-zorro-antd/modal'

@NgModule({
  declarations: [CloseDialogDirective],
  imports: [CommonModule, NzModalModule],
  exports: [CloseDialogDirective]
})
export class CloseDialogDirectiveModule {}
