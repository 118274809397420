import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Store } from '@ngxs/store'
import { LanguageState } from 'src/app/features/base/language/store'

@Injectable({ providedIn: 'root' })
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<Record<string, string>>,
    next: HttpHandler
  ): Observable<HttpEvent<Record<string, string>>> {
    const language = this.store.selectSnapshot(LanguageState.getLanguage)
    const appendedRequest = request.clone({
      setHeaders: { 'accept-language': language ?? 'vi' }
    })
    return next.handle(appendedRequest)
  }
}
