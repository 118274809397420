import { I18nPluralPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

const getPluralMap = (word: string) => ({
  '=0': `PLURAL_WORDS.${word.toUpperCase()}.none`,
  '=1': `PLURAL_WORDS.${word.toUpperCase()}.singular`,
  other: `PLURAL_WORDS.${word.toUpperCase()}.plural`
})

@Pipe({ name: 'pluralTranslate' })
export class PluralTranslatePipe implements PipeTransform {
  constructor(private i18nPluralPipe: I18nPluralPipe, private translateService: TranslateService) {}

  transform(count: number, word: string): string {
    return this.translateService.instant(this.i18nPluralPipe.transform(count, getPluralMap(word)), {
      count
    })
  }
}
