import { FirebaseResponse } from './state.model'
import {
  LikedPostResponse,
  STATE_NAME,
  CommentedPostResponse,
  FollowedResponse,
  SharedPostResponse
} from './state.model'

const ACTIONS = {
  ADD_TOKEN: `[${STATE_NAME}] Add token`,
  RECEIVE_FIREBASE_MESSAGE: `[${STATE_NAME}] Receive Firebase Message`,
  RECEIVE_LIKED_POST: `[${STATE_NAME}] Receive Firebase LikedPost message`,
  RECEIVE_COMMENTED_POST: `[${STATE_NAME}] Receive Firebase CommentedPost message`,
  RECEIVE_SHARED_POST: `[${STATE_NAME}] Receive Firebase SharedPost message`,
  RECEIVE_FOLLOWED: `[${STATE_NAME}] Receive Firebase Followed message`
}

export class AddToken {
  static readonly type = ACTIONS.ADD_TOKEN
  constructor(public readonly browserToken: string) {}
}
export class ReceiveFirebaseMessage {
  static readonly type = ACTIONS.RECEIVE_FIREBASE_MESSAGE
  constructor(public readonly response: FirebaseResponse<unknown>) {}
}
export class ReceiveLikedPost {
  static readonly type = ACTIONS.RECEIVE_LIKED_POST
  constructor(public readonly response: LikedPostResponse) {}
}
export class ReceiveCommentedPost {
  static readonly type = ACTIONS.RECEIVE_COMMENTED_POST
  constructor(public readonly response: CommentedPostResponse) {}
}
export class ReceiveSharedPost {
  static readonly type = ACTIONS.RECEIVE_SHARED_POST
  constructor(public readonly response: SharedPostResponse) {}
}
export class ReceiveFollowed {
  static readonly type = ACTIONS.RECEIVE_FOLLOWED
  constructor(public readonly response: FollowedResponse) {}
}
