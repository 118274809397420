<ng-container
  *ngIf="{
    post: post$ | async,
    comments: comments$ | async,
    replyInfo: replyInfo$ | async,
    editing: editing$ | async,
    commentsLoading: commentsLoading$ | async,
    selectedCommentId: selectedCommentId$ | async,
    me: me$ | async
  } as view"
>
  <main
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="1500"
    [scrollWindow]="false"
    (scrolled)="loadMoreComments.next(true)"
    class="flex-grow flex flex-col overflow-auto"
    *ngIf="view.post; else loadingTemplate"
  >
    <div class="flex flex-col pt-8 px-main">
      <header class="w-full flex items-center mb-4">
        <nz-avatar
          [nzSize]="56"
          routerLink="/h/{{ view.post.user?.username }}"
          closeDialog="one"
          class="avatar flex-shrink-0"
          [nzSrc]="view.post.user?.avatar || 'assets/images/logo.svg'"
        ></nz-avatar>
        <header class="ml-3 flex-grow truncate">
          <h4 class="text-lg leading-tight mb-0">
            <a routerLink="/h/{{ view.post.user?.username }}" closeDialog="one">
              @{{ view.post.user?.username }}
            </a>
            <i
              nz-icon
              nzType="icons/fill:verified"
              *ngIf="view.post.user?.verified"
              class="!text-base align-icon"
            ></i>
          </h4>
          <span class="text-gray-600">
            {{ view.post.user?.name }}
            <span class="text-[4px]" style="vertical-align: 0.2rem">&#x25cf;</span>
            {{ view.post.createdAt | date: 'dd/MM' }}
          </span>
        </header>
        <div class="flex-shrink-0 flex items-center ml-2">
          <app-follow-button
            [friendId]="view.post.user?._id"
            [relation]="view.post.user?.relation"
          ></app-follow-button>
          <app-post-actions-button
            [isMyPost]="view.me?._id === view.post.userId"
            [postType]="view.post.type"
            [downloadable]="view.post.downloadable"
            (clickAction)="onClickAction($event)"
            class="ml-1"
          ></app-post-actions-button>
        </div>
      </header>
      <div *ngIf="view.post.challenge.gift" class="mb-2">
        <app-post-gift-countdown
          [endTime]="view.post.challenge.gift.endTime"
        ></app-post-gift-countdown>
      </div>
      <section class="text-base border-b">
        <ng-container *ngIf="view.post.type !== challengeTypes.Quiz">
          <h4>
            <a
              class="break-all"
              routerLink="/h/challenge/{{ view.post.challenge._id }}"
              closeDialog="one"
            >
              #{{ view.post.hashtags[0] }}
            </a>
          </h4>
          <pre
            *ngIf="!view.editing; else descriptionTextareaTemplate"
            (click)="seeMore = !seeMore"
            [class.line-clamp-6]="!seeMore"
            class="break-all text-sm leading-5"
            >{{ view.post.description }}</pre
          >
          <ng-template #descriptionTextareaTemplate>
            <form [formGroup]="editingForm" (submit)="onClickUpdatePost()" class="my-4">
              <textarea
                nz-input
                [placeholder]="view.post.description"
                [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                formControlName="description"
              ></textarea>
              <p class="mt-3">
                <span class="align-middle text-base mr-2">Cho phép bình luận</span>
                <nz-switch formControlName="commentable"></nz-switch>
              </p>
              <p>
                <span class="align-middle text-base mr-2">Cho phép tải xuống</span>
                <nz-switch formControlName="downloadable"></nz-switch>
              </p>

              <footer class="text-right">
                <button nz-button nzType="primary" class="mr-2">{{'update' | translate}}</button>
                <button nz-button type="button" nzType="text" (click)="onClickDiscard()">
                  {{'cancel' | translate}}
                </button>
              </footer>
            </form>
          </ng-template>
        </ng-container>
      </section>
    </div>
    <ul
      class="actions px-main"
      [class.disabled]="!view.me || view.editing"
      nz-tooltip
      [nzTooltipTitle]="!!view.me ? '' : loginTooltipTemplate"
    >
      <li class="action-item">
        <i
          nz-icon
          nzType="icons/{{ view.post.wasLiked ? 'fill' : 'outline' }}:heart"
          [class.!text-tryme-primary]="view.post.wasLiked"
          (click)="onClickLike()"
        ></i>
        <strong>
          <a role="button" (click)="onClickViewLikes()" class="hover:underline">
            {{ view.post.liked | numberToText }}
          </a>
        </strong>
      </li>
      <li class="action-item" [class.disabled]="!view.post.commentable">
        <i nz-icon nzType="icons/outline:message" (click)="focusCommentArea()"></i>
        <strong>{{ view.post.commented | numberToText }}</strong>
      </li>
      <li class="action-item">
        <i nz-icon nzType="icons/outline:send" (click)="onClickShare()"></i>
        <strong>{{ view.post.shared | numberToText }}</strong>
      </li>
      <li
        class="action-item"
        [class.non-clickable]="!view.post.challenge.gift"
        (click)="onClickScore()"
      >
        <i nz-icon nzType="icons/outline:badge"></i>
        <strong>{{ view.post.score | numberToText }}</strong>
      </li>
    </ul>
    <section class="px-main mb-4">
      <a
        nz-button
        nzType="primary"
        nzSize="large"
        [nzBlock]="true"
        routerLink="/challenge/join/{{ view.post.challengeId }}"
        closeDialog="one"
      >
        {{'TITLE.challenge-join' | translate}}
      </a>
    </section>
    <section class="comments">
      <ng-container *ngIf="view.comments; else commentLoadingTemplate">
        <ng-container *ngIf="view.comments.data.length > 0; else noOneCommentYet">
          <ng-container *ngFor="let comment of view.comments?.data">
            <ng-template
              [ngTemplateOutlet]="commentTemplate"
              [ngTemplateOutletContext]="{ comment: comment, level: 0 }"
            ></ng-template>
          </ng-container>
        </ng-container>
        <ng-template #noOneCommentYet>
          <div class="text-center">Hãy là người bình luận đầu tiên 🔥</div>
        </ng-template>
      </ng-container>
    </section>
  </main>

  <ng-container *ngIf="!!view.me">
    <section class="px-main py-5">
      <ng-container *ngIf="view.post?.commentable; else cannotCommentTemplate">
        <div
          class="flex justify-between text-xs mb-2 -mt-2 ml-14 mr-8"
          *ngIf="view.replyInfo?.username"
        >
          <ng-container *ngIf="view.commentsLoading; else commentNotLoadingTemplate">
            {{'sendingTo' | translate}}
          </ng-container>
          <ng-template #commentNotLoadingTemplate>{{'comment.reply' | translate}}</ng-template>
          <strong class="ml-1">{{ view.replyInfo?.username }}</strong>
          <a
            role="button"
            class="ml-auto text-gray-400 hover:text-gray-500"
            (click)="clearReplyInfo()"
          >
            {{'common.cancel' | translate}}
          </a>
        </div>
        <div class="w-full flex-shrink-0 flex items-center space-x-3">
          <nz-avatar
            class="avatar flex-shrink-0"
            [nzSize]="40"
            [nzSrc]="view.me?.avatar || 'assets/images/logo.svg'"
          ></nz-avatar>
          <nz-input-group
            class="w-full flex-grow !rounded-xl ml-2"
            [nzPrefix]="prefix"
            [nzSuffix]="emojiImport"
          >
            <textarea
              #commentTextarea
              nzFocus
              nz-input
              nzBorderless
              [nzAutosize]="{ minRows: 1, maxRows: 3 }"
              [placeholder]="'comment.addComment' | translate"
              [formControl]="commentControl"
              (keydown.enter)="onPressEnter($event)"
              (keydown.esc)="clearReplyInfo()"
              class="message-input"
            ></textarea>
          </nz-input-group>
          <i
            nz-icon
            nzType="icons/fill:send"
            (click)="onClickSend()"
            class="send-button"
            [class.disabled]="!commentControl.value"
            nz-tooltip
            nzTooltipTitle="Gửi"
          ></i>
        </div>
        <ng-template #emojiImport>
          <i
            nz-icon
            nzType="icons/outline:emoji"
            nz-dropdown
            [nzDropdownMenu]="emoji"
            [nzPlacement]="'topRight'"
            class="!text-gray-500 hover:!text-gray-700 cursor-pointer"
          ></i>
          <nz-dropdown-menu #emoji="nzDropdownMenu">
            <emoji-mart
              set="twitter"
              [isNative]="true"
              emoji="heart_eyes"
              (emojiSelect)="addEmoji($event)"
              title="TryMe"
            ></emoji-mart>
          </nz-dropdown-menu>
        </ng-template>
      </ng-container>

      <ng-template #cannotCommentTemplate>
        <div class="text-center text-gray-600">
          <a
            routerLink="/h/{{ view.post?.user.username }}"
            closeDialog="one"
            class="primary font-medium"
          >
            @{{ view.post?.user.username }}
          </a>
          đã tắt bình luận về bài viết này.
        </div>
      </ng-template>
    </section>
  </ng-container>
  <ng-template #commentTemplate let-comment="comment" let-level="level">
    <nz-comment
      id="comment-{{ comment._id }}"
      class="relative"
      [class.selected]="
        view.selectedCommentId?.commentId === comment._id ||
        view.selectedCommentId?.originalCommentId === comment._id
      "
    >
      <nz-avatar
        routerLink="/h/{{ comment?.user?.username }}"
        closeDialog="one"
        nz-comment-avatar
        [nzSize]="36"
        class="avatar !mt-2"
        [nzSrc]="comment?.user?.avatar || 'assets/images/logo.svg'"
      ></nz-avatar>
      <nz-comment-content>
        <strong class="mr-5">
          <a routerLink="/h/{{ comment?.user?.username }}" closeDialog="one">
            @{{ comment?.user?.username | truncate: 80 }}
          </a>
          <i
            nz-icon
            nzType="icons/fill:verified"
            *ngIf="comment?.user?.verified"
            class="!text-base align-icon"
          ></i>
        </strong>
        <ng-container *ngIf="view.post.type === challengeTypes.Quiz && comment.answers.length > 0">
          <ng-container *ngFor="let answer of comment.answers">
            <div class="flex justify-between items-center w-3/4 p-2 bg-opacity-10 rounded-xl my-1" [ngClass]="{
                          'bg-tryme-green': answer.correct,
                          'bg-tryme-primary': answer.correct === false
                        }">
              <i nz-icon nzType="icons/outline:message" class="rounded-full bg-white bg-opacity-40 p-1"></i>
              <strong class="ml-2 mr-auto">{{ answer.answer }}</strong>
              <i nz-icon nzType="icons/fill:success-circle" class="!text-tryme-green" *ngIf="answer.correct"></i>
              <i nz-icon nzType="icons/fill:close-circle" class="!text-tryme-primary" *ngIf="answer.correct === false"></i>
            </div>
            <div class="mb-1">
              <strong *ngIf="comment.giftRank"
                class="inline-flex items-center gap-x-1 bg-rank-badge bg-opacity-10 px-2 py-1 rounded-full">
                <span class="text-white">TOP #{{ comment.giftRank }}</span>
              </strong>
              <strong *ngIf="comment.random > 0"
                class="inline-flex items-center gap-x-1 bg-tryme-yellow bg-opacity-10 px-2 py-1 rounded-full">
                <i nz-icon nzType="icons/fill:coin"></i>
                <span class="text-tryme-yellow">{{ comment.random }}</span>
              </strong>
              <strong *ngIf="comment.giftScore && view.post.type === challengeTypes.Quiz"
                class="inline-flex items-center gap-x-1 bg-tryme-light-red bg-opacity-10 px-2 py-1 rounded-full">
                <i nz-icon nzType="icons/fill:gift"></i>
                <span class="text-tryme-red">{{ comment.giftScore | numberToText }}</span>
              </strong>
            </div>
          </ng-container>
        </ng-container>

        <p class="mr-5 !mb-1">{{ comment.content }}</p>
        <div class="space-x-6 text-gray-500 text-xs">
          <span>{{ comment?.createdAt | timeago | async }}</span>
          <strong
            *ngIf="view.post?.commentable"
            (click)="
              setReplyInfo({
                commentId: level === 1 ? comment.originalId : comment._id,
                userId: comment.userId,
                username: comment.user?.username
              })
            "
            class="cursor-pointer hover:text-gray-600"
          >
            {{'comment.reply' | translate}}
          </strong>
          <strong
            *ngIf="comment.userId !== view.me?._id"
            (click)="onClickReportComment(comment._id, view.post._id)"
            class="cursor-pointer hover:text-gray-600"
          >
            {{'report' | translate}}
          </strong>
        </div>
        <section class="absolute top-0 right-0 text-center" [ngClass]="{'comment-like': comment.answers.length > 0}">
          <i
            nz-icon
            nzType="icons/{{ comment.wasLiked ? 'fill' : 'outline' }}:heart"
            class="cursor-pointer !text-lg"
            [class.!text-tryme-primary]="comment.wasLiked"
            (click)="toggleLikeComment(comment._id, comment.wasLiked)"
          ></i>
          <br />
          <small>{{ comment.liked }}</small>
        </section>
      </nz-comment-content>
      <ng-container *ngIf="comment.children && comment.children.length">
        <ng-template ngFor let-child [ngForOf]="comment.children">
          <ng-template
            [ngTemplateOutlet]="commentTemplate"
            [ngTemplateOutletContext]="{ comment: child, level: 1 }"
          ></ng-template>
        </ng-template>
      </ng-container>
    </nz-comment>
  </ng-template>
</ng-container>

<ng-template #loadingTemplate>
  <section class="px-main py-8">
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 2 }"></nz-skeleton>
    <nz-skeleton [nzParagraph]="{ rows: 4 }"></nz-skeleton>
    <nz-skeleton [nzParagraph]="{ rows: 4 }"></nz-skeleton>
    <nz-space nzSize="middle">
      <nz-skeleton-element *nzSpaceItem nzType="avatar" nzActive="true"></nz-skeleton-element>
      <nz-skeleton-element *nzSpaceItem nzType="avatar" nzActive="true"></nz-skeleton-element>
      <nz-skeleton-element *nzSpaceItem nzType="avatar" nzActive="true"></nz-skeleton-element>
      <nz-skeleton-element *nzSpaceItem nzType="avatar" nzActive="true"></nz-skeleton-element>
    </nz-space>
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 3 }"></nz-skeleton>
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 2 }"></nz-skeleton>
  </section>
</ng-template>

<ng-template #commentLoadingTemplate>
  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 3 }"></nz-skeleton>
</ng-template>
