import { ChangeDetectionStrategy, Component } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { LoadingState, MyPagingModel } from 'src/app/data-access/missing.model';
import { PageViewEvent, SegmentEvent } from 'src/app/util/services/constants/segment-event.constant';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { SegmentTrackingService } from 'src/app/util/services/segment.service';
import { TrendingService, TrendingSuggestionResponse } from 'src/app/data-access/generated/trending';

@Component({
  selector: 'app-search',
  templateUrl: './app-search.component.html',
  styleUrls: ['./app-search.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class AppSearchComponent {
  searchControl = new FormControl()

  loading$ = this.state.select('loading')
  suggestions$ = this.searchControl.valueChanges.pipe(
    tap(() => this.state.set({ loading: true })),
    filter((value: string) => value?.length >= 2),
    debounceTime(500),
    distinctUntilChanged(),
    switchMap(q => this.trendingService.trendingControllerSuggest({ fullTextSearch: q })),
    tap(() => this.state.set({ loading: false })),
    map(response => response.data as MyPagingModel<TrendingSuggestionResponse>)
  )

  constructor(
    private trendingService: TrendingService,
    private router: Router,
    private state: RxState<LoadingState>,
    private segmentTrackingService: SegmentTrackingService
  ) {}

  routeTo(item: TrendingSuggestionResponse) {
    if (
      item.type === TrendingSuggestionResponse.TypeEnum.Challenge ||
      item.type === TrendingSuggestionResponse.TypeEnum.Hashtag
    ) {
      this.segmentTrackingService.track(SegmentEvent.Searching, {
        destination: PageViewEvent.Challenge,
        searchValue: this.searchControl.value
      })
      return this.router.navigate([`/h/challenge/${item._id}`])
    }
    if (item.type === TrendingSuggestionResponse.TypeEnum.User) {
      this.segmentTrackingService.track(SegmentEvent.Searching, {
        destination: PageViewEvent.User,
        searchValue: this.searchControl.value
      })
      return this.router.navigate([`/h/${item.title}`])
    }
    if (item.type === TrendingSuggestionResponse.TypeEnum.Post) {
      this.segmentTrackingService.track(SegmentEvent.Searching, {
        destination: PageViewEvent.Post,
        searchValue: this.searchControl.value
      })
      return this.router.navigate([`/post/${item._id}`])
    }
    this.segmentTrackingService.track(SegmentEvent.Searching, {
      destination: PageViewEvent.Explore,
      searchValue: this.searchControl.value
    })
    this.router.navigateByUrl(`/explore?q=${item.title}`)
  }

  goToExplorePage() {
    const q = this.searchControl.value
    this.segmentTrackingService.track(SegmentEvent.Searching, {
      destination: PageViewEvent.Explore,
      searchValue: q
    })
    this.router.navigate(['/explore'], { queryParams: { q } })
  }
}
