<h1 class="leading-none mb-4">Đăng ký</h1>
<p class="text-gray-500 text-base leading-6 mb-6">
  Đăng ký tài khoản nhanh chóng và tham gia thử thách ngay!
</p>
<form nz-form [formGroup]="form" (ngSubmit)="submitForm()" nzLayout="vertical">
  <nz-form-item>
    <nz-form-label>
      Email
      <app-require-sign></app-require-sign>
    </nz-form-label>
    <nz-form-control [nzErrorTip]="errorEmail">
      <nz-input-group [nzSuffix]="suffixEmail" nzSize="large">
        <input
          type="text"
          nz-input
          formControlName="email"
          placeholder="Địa chỉ email"
          class="h-full"
        />
      </nz-input-group>
      <ng-template #suffixEmail>
        <ng-container
          *ngTemplateOutlet="suffixValidTemplate; context: { name: 'email' }"
        ></ng-container>
      </ng-template>
      <ng-template #errorEmail let-control>
        <ng-container *ngIf="control.touched && control.dirty">
          <div *ngIf="control.hasError('required')">
            {{ 'form.validate.require' | translate: { field: 'email' } }}
          </div>
          <div *ngIf="control.hasError('email')">
            {{ 'form.validate.inValid' | translate: { field: 'Email' } }}
          </div>
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>
      Mật khẩu
      <app-require-sign></app-require-sign>
    </nz-form-label>
    <nz-form-control [nzErrorTip]="errorPassWordTemplate">
      <nz-input-group [nzSuffix]="suffixPassword" nzSize="large">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          nz-input
          formControlName="password"
          [placeholder]="'common.password' | translate"
        />
      </nz-input-group>
      <p
        class="text-gray-500 text-xs text-right leading-5 mb-0"
        *ngIf="form.get('password').pristine"
      >
        Mật khẩu phải có ít nhất 8 kí tự phải gồm chữ và số
      </p>
      <ng-template #suffixPassword>
        <ng-container
          *ngTemplateOutlet="
            suffixValidTemplate;
            context: {
              name: 'password',
              tooltip: form.get('password').hasError('pattern')
                ? 'Mật khẩu phải có ít nhất 1 chữ và 1 số'
                : ''
            }
          "
        ></ng-container>
        <i
          nz-icon
          nzType="icons/outline:{{ passwordVisible ? 'hide' : 'show' }}"
          (click)="passwordVisible = !passwordVisible"
        ></i>
      </ng-template>
      <ng-template #errorPassWordTemplate let-control>
        <ng-container *ngIf="control.touched && control.dirty">
          <div *ngIf="control.hasError('required')">
            {{
              'form.validate.require'
                | translate: { field: 'common.password' | translate | lowercase }
            }}
          </div>
          <div *ngIf="control.hasError('minlength')">
            {{
              'form.validate.minLength'
                | translate: { field: 'common.password' | translate, min: 8 }
            }}
          </div>
          <div *ngIf="control.hasError('maxlength')">Mật khẩu không vượt quá 70 kí tự</div>
          <div *ngIf="control.hasError('pattern')">Mật khẩu phải có ít nhất 1 chữ và 1 số</div>
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>
      {{ 'common.fullname' | translate }}
      <app-require-sign></app-require-sign>
    </nz-form-label>
    <nz-form-control [nzErrorTip]="errorFullNameTemplate">
      <nz-input-group [nzSuffix]="suffixFullname" nzSize="large">
        <input type="text" nz-input formControlName="fullname" placeholder="Nhập họ & tên" />
      </nz-input-group>
    </nz-form-control>
    <ng-template #suffixFullname>
      <ng-container
        *ngTemplateOutlet="suffixValidTemplate; context: { name: 'fullname' }"
      ></ng-container>
    </ng-template>
    <ng-template #errorFullNameTemplate let-control>
      <ng-container *ngIf="control.touched && control.dirty">
        <div *ngIf="control.hasError('required')">
          {{
            'form.validate.require'
              | translate: { field: 'common.fullname' | translate | lowercase }
          }}
        </div>
        <div *ngIf="control.hasError('minlength')">
          {{
            'form.validate.minLength' | translate: { field: 'common.fullname' | translate, min: 5 }
          }}
        </div>
        <div *ngIf="control.hasError('maxlength')">Họ và tên không vượt quá 200 kí tự</div>
        <div *ngIf="control.hasError('pattern')">Họ và tên phải có ít nhất 1 kí tự là chữ</div>
      </ng-container>
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>
      Username
      <app-require-sign></app-require-sign>
    </nz-form-label>
    <nz-form-control [nzErrorTip]="errorUsernameTemplate">
      <nz-input-group [nzSuffix]="suffixUsername" nzSize="large">
        <input
          type="text"
          nz-input
          formControlName="username"
          nzSize="large"
          placeholder="Nhập username"
        />
      </nz-input-group>
    </nz-form-control>
    <ng-template #suffixUsername>
      <ng-container
        *ngTemplateOutlet="suffixValidTemplate; context: { name: 'username' }"
      ></ng-container>
    </ng-template>
    <ng-template #errorUsernameTemplate let-control>
      <ng-container *ngIf="control.touched && control.dirty">
        <div *ngIf="control.hasError('required')">
          {{ 'form.validate.require' | translate: { field: 'username' } }}
        </div>
        <div *ngIf="control.hasError('minlength')">
          {{ 'form.validate.minLength' | translate: { field: 'Username', min: 6 } }}
        </div>
        <div *ngIf="control.hasError('maxlength')">Username không vượt quá 70 kí tự</div>
        <div *ngIf="control.hasError('pattern')">Username phải có ít nhất 1 kí tự chữ hoặc số</div>
        <div *ngIf="control.hasError('noSpace')">Username không được chứa khoảng trắng</div>
      </ng-container>
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>Ngày sinh</nz-form-label>
    <nz-form-control>
      <nz-date-picker
        formControlName="birthday"
        class="w-full"
        nzSize="large"
        nzFormat="dd/MM/yyyy"
        [nzPlaceHolder]="'common.birthday' | translate"
        [nzDisabledDate]="disabledDate"
      ></nz-date-picker>
      <ng-template #suffixBirthday>
        <ng-container
          *ngTemplateOutlet="suffixValidTemplate; context: { name: 'birthday' }"
        ></ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="!mb-10">
    <nz-form-label>{{ 'common.gender' | translate }}</nz-form-label>
    <nz-form-control>
      <nz-select formControlName="gender" nzPlaceHolder="--Vui lòng chọn--" nzSize="large" [nzOptionHeightPx]="38">
        <nz-option nzValue="1" [nzLabel]="'common.male' | translate"></nz-option>
        <nz-option nzValue="2" [nzLabel]="'common.female' | translate"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <button nz-button nzSize="large" nzType="primary" nzBlock class="rounded-lg !mb-6">
    <strong class="text-base">{{ 'register.createAccount' | translate }}</strong>
  </button>
</form>
<p class="text-center mb-10">
  <span class="text-gray-500 mr-1">{{ 'register.txtHasAccount' | translate }}</span>
  <a (click)="onClickLogin()" class="text-tryme-primary font-semibold">
    {{ 'register.txtLogin' | translate }}
  </a>
</p>
<p
  class="text-center"
  [innerHTML]="'register.termsAndConditions' | translate: { link1: '/terms-of-service' }"
></p>

<ng-template #suffixValidTemplate let-name="name" let-tooltip="tooltip">
  <ng-container *ngIf="!form.get(name).pristine">
    <i
      nz-icon
      class="!text-tryme-green"
      nzType="icons/outline:check-all"
      *ngIf="form.get(name).valid"
    ></i>
    <!-- TODO: Implement tooltip for error icon when using multi language, get error message by key -->
    <i
      nz-icon
      class="!text-tryme-red"
      nzType="icons/outline:info"
      [nz-tooltip]="tooltip"
      *ngIf="form.get(name).invalid"
    ></i>
  </ng-container>
</ng-template>
