import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { QuizComponent } from './quiz.component';
import { QuizComponentStore } from './quiz.store';
import { QuizJoinedCountModule } from '../quiz-joined-count/quiz-joined-count.module';
import { QuizStatisticDialogModule } from '../quiz-statistic-dialog/quiz-statistic-dialog.module';
import { QuizStatisticModule } from '../quiz-statistic/quiz-statistic.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiMapperPipeModule } from 'src/app/util/pipes';

const nzModules = [NzIconModule, NzButtonModule, NzInputModule]

@NgModule({
  declarations: [QuizComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiMapperPipeModule,
    nzModules,
    QuizJoinedCountModule,
    QuizStatisticDialogModule,
    QuizStatisticModule,
    TranslateModule,
  ],
  exports: [QuizComponent],
  providers: [QuizComponentStore],
})
export class QuizModule {}
