/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MatchGroupResponse } from './match-group-response';


export interface MatchCategoryResponse { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    type: MatchCategoryResponse.TypeEnum;
    icon: string;
    name: string;
    groups: Array<MatchGroupResponse>;
}
export namespace MatchCategoryResponse {
    export type TypeEnum = 'trending' | 'popular' | 'newest';
    export const TypeEnum = {
        Trending: 'trending' as TypeEnum,
        Popular: 'popular' as TypeEnum,
        Newest: 'newest' as TypeEnum
    };
}


