import { Routes } from '@angular/router'

export const conversationRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ' '
  },
  {
    path: ':id',
    loadChildren: () =>
      import('../components/conversation/conversation.module').then((m) => m.ConversationModule)
  }
]
