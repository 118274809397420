<h1 class="leading-none mb-4">Lấy lại mật khẩu</h1>
<p class="text-gray-500 text-base leading-6 mb-8">
  Nhập địa chỉ email đã đăng ký, chúng tôi sẽ gửi mã OTP qua email để đặt lại mật khẩu.
</p>
<form nz-form [formGroup]="form" (ngSubmit)="onSubmit()" nzLayout="vertical" class="mb-12">
  <nz-form-item>
    <nz-form-label>Email</nz-form-label>
    <nz-form-control [nzErrorTip]="errorEmail">
      <nz-input-group [nzSuffix]="suffixEmail" nzSize="large">
        <input
          type="text"
          nz-input
          formControlName="email"
          [placeholder]="'common.usernameOrEmail' | translate"
          class="h-full"
        />
      </nz-input-group>
      <ng-template #suffixEmail>
        <ng-container *ngIf="!form.get('email').pristine">
          <i
            nz-icon
            class="!text-tryme-green"
            nzType="icons/outline:check-all"
            *ngIf="form.get('email').valid"
          ></i>
          <i
            nz-icon
            class="!text-tryme-red"
            nzType="icons/outline:info"
            *ngIf="form.get('email').invalid"
          ></i>
        </ng-container>
      </ng-template>
      <ng-template #errorEmail let-control>
        <div *ngIf="control.hasError('required')">
          {{ 'form.validate.require' | translate: { field: 'email' } }}
        </div>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <button
    nz-button
    nzSize="large"
    nzType="primary"
    nzBlock
    [nzLoading]="loading$ | async"
    class="rounded-lg !mb-6"
  >
    <strong class="text-base">Gửi mã OTP</strong>
  </button>
</form>
