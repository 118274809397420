import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PostGiftCountdownComponent } from './post-gift-countdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { TuiMapperPipeModule } from 'src/app/util/pipes';

const nzModules = [NzIconModule]

@NgModule({
  declarations: [PostGiftCountdownComponent],
  imports: [CommonModule, TuiMapperPipeModule, nzModules, TranslateModule],
  exports: [PostGiftCountdownComponent]
})
export class PostGiftCountdownModule {}
