import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PostActionsButtonComponent } from './post-actions-button.component'
import { TuiFilterPipeModule } from 'src/app/util/pipes'
import { MoreActionsButtonModule } from 'src/app/ui/more-actions-button'

@NgModule({
  declarations: [PostActionsButtonComponent],
  imports: [CommonModule, MoreActionsButtonModule, TuiFilterPipeModule],
  exports: [PostActionsButtonComponent]
})
export class PostActionsButtonModule {}
