import { Observable, pipe, UnaryFunction } from 'rxjs'
import { filter, map, withLatestFrom } from 'rxjs/operators'

export function distinctWith<T>(
  comparation: Observable<unknown>
): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
  return pipe(
    withLatestFrom(comparation),
    filter(([origin, comparation]) => JSON.stringify(origin) !== JSON.stringify(comparation)),
    map(([origin]) => origin)
  )
}
