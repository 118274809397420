import { Component, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
  templateUrl: './score-dialog.component.html',
  styleUrls: ['./score-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreDialogComponent {
  @Input() giftScore = 0
  @Input() giftRank = 0
  @Input() score = 0
}
