import { Component, ChangeDetectionStrategy } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { timer } from 'rxjs'
import { first } from 'rxjs/operators'

@Component({
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessDialogComponent {
  constructor(modalRef: NzModalRef) {
    timer(4000)
      .pipe(first())
      .subscribe(() => modalRef.close())
  }
}
