import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuizJoinedCountComponent } from './quiz-joined-count.component'
import { NumberToTextPipeModule } from 'src/app/util/pipes'

@NgModule({
  declarations: [QuizJoinedCountComponent],
  imports: [CommonModule, NumberToTextPipeModule],
  exports: [QuizJoinedCountComponent]
})
export class QuizJoinedCountModule {}
