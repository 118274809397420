import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'
import { ActionItem } from './types.model'

@Component({
  selector: 'more-actions-button',
  templateUrl: './more-actions-button.component.html',
  styleUrls: ['./more-actions-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreActionsButtonComponent {
  @Input() actions: Array<ActionItem> = []
  @Output() clickAction = new EventEmitter<ActionItem>()

  visible = false

  onClickAction(action: ActionItem) {
    this.visible = false
    this.clickAction.emit(action)
  }
}
