import { Directive, HostListener, Input, Optional } from '@angular/core'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'

@Directive({
  selector: '[closeDialog]'
})
export class CloseDialogDirective {
  @Input() closeDialog: 'all' | 'one'
  @HostListener('click')
  clickListener() {
    if (this.closeDialog === 'all') this.nzModalService.closeAll()
    else this.modalRef?.close()
  }

  constructor(@Optional() private modalRef: NzModalRef, private nzModalService: NzModalService) {}
}
