import { NgModule } from '@angular/core'
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging'
import { AngularFireModule } from '@angular/fire'
import { environment } from 'src/environments/environment'
import { NgxsModule } from '@ngxs/store'
import { FirebaseState } from './store/state'

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    NgxsModule.forFeature([FirebaseState])
  ],
  providers: [AngularFireMessaging]
})
export class FirebaseRootModule {}
