<nz-header class="!py-2" *ngLet="me$ | async as me">
  <div class="h-12 flex justify-between items-center tryme-container" *ngLet="currentUrl$ | async as currentUrl">
    <a class="flex items-center cursor-pointer w-1/4" routerLink="/">
      <img src="assets/images/logo.png" alt="TryMe logo" class="w-10 mr-2 flex-shrink-0" />
      <strong class="text-2xl">
        <strong class="text-tryme-primary font-extrabold">TRY</strong>
        <strong class="text-tryme-secondary font-extrabold">ME</strong>
      </strong>
    </a>

    <app-search
      *ngIf="(!me || me.status === 1) &&
        (currentUrl.includes('/challenge/create') || currentUrl.includes('/challenge/join')) ===
        false
      "
      class="w-full h-full max-w-2xl pl-14 pr-10 flex mr-auto"
    ></app-search>

    <section class="flex justify-end items-center w-1/4 space-x-2 ml-4">
      <button
        nz-button
        *ngIf="(!me || me.status === 1) && currentUrl.includes('/challenge/create') === false"
        nzType="primary"
        routerLink=""
        [queryParams]="{'challenge-create': true}"
        class="!h-10 leading-none text-base"
      >
        Tạo thử thách
      </button>
      <i *ngIf="!me || me.status === 1"
        nz-icon
        nzType="icons/outline:trophy"
        routerLink="/h/match"
        class="icon"
        nz-tooltip="Top thách đấu"
        nzTooltipPlacement="bottom"
      ></i>
      <ng-container *ngIf="me; else notLogin">
        <ng-container *ngIf="me.status === 1">
          <i nz-icon nzType="icons/outline:messages" routerLink="/messages" class="icon" nz-tooltip="Tin nhắn"
            nzTooltipPlacement="bottom">
            <b class="badge" *ngIf="unreadMessageCount$ | async as unreadMessageCount">
              {{ unreadMessageCount }}
            </b>
          </i>
          <i nz-icon nzType="icons/outline:notification" nz-dropdown nzTrigger="click" [nzDropdownMenu]="notification"
            nzPlacement="bottomRight" [(nzVisible)]="notificationsDropdownVisible" (click)="onClickNotification()" class="icon"
            nz-tooltip="Thông báo" nzTooltipPlacement="bottom">
            <b class="badge" *ngIf="unreadNotiCount$ | async as unreadNotiCount">
              {{ unreadNotiCount }}
            </b>
          </i>
          <nz-dropdown-menu #notification="nzDropdownMenu">
            <ng-container *ngIf="notifications$ | async as notifications">
              <app-notify [notifications]="notifications?.data" (readAll)="readAll()"
                (loadMore)="notifications.page < notifications.total && loadMoreNotification()" (readOne)="readOne($event)">
              </app-notify>
              </ng-container>
              </nz-dropdown-menu>
        </ng-container>

        <a class="h-16 flex items-center" nz-dropdown [nzDropdownMenu]="menu">
          <nz-avatar [nzSrc]="me.avatar" [nzSize]="40" class="avatar flex-shrink-0"></nz-avatar>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu class="dropdown-menu">
              <a
                *ngFor="let action of myActions"
                [routerLink]="action.url"
                (click)="!action.url && onClickAction(action)"
              >
                <li nz-menu-item [ngClass]="action.class">
                  <i nz-icon [nzType]="action.iconType"></i>
                  <span>{{ action.title }}</span>
                </li>
              </a>
            </ul>
          </nz-dropdown-menu>
        </a>
      </ng-container>

      <ng-template #notLogin>
        <i
          nz-icon
          nzType="icons/outline:document"
          routerLink="/terms-of-service"
          class="icon"
          nz-tooltip="Điều khoản sử dụng"
          nzTooltipPlacement="bottom"
        ></i>
        <button
          nz-button
          nzType="primary"
          (click)="onClickLogin()"
          class="!h-10 leading-none text-base"
        >
          {{ 'login.txtLogin' | translate }}
        </button>
      </ng-template>
    </section>
  </div>
</nz-header>
