import { Injectable } from '@angular/core'
import { Router, CanActivate } from '@angular/router'
import { Store } from '@ngxs/store'
import { AuthState } from '../../store'

@Injectable({ providedIn: 'root' })
export class IsNotLoggedInGuard implements CanActivate {
  constructor(private router: Router, private _store: Store) {}

  canActivate() {
    const isLoggedIn = this._store.selectSnapshot(AuthState.isLoggedIn)
    if (isLoggedIn) this.router.navigateByUrl('/')
    return !isLoggedIn
  }
}
