import {
  MessageControllerPaginateRequestParams,
  MessageControllerSendRequestParams
} from 'src/app/data-access/generated/messenger'
import { RelationControllerGetFollowingsRequestParams } from 'src/app/data-access/generated/relation'
import { MessengerSocketAction, NewMessagePayload } from './socket.model'
import { STATE_NAME, StateModel } from './state.model'

const ACTIONS = {
  SET_CONVERSATIONS: `[${STATE_NAME}] Set conversions`,
  LOAD_CONVERSATION_BY_ID: `[${STATE_NAME}] Load conversation by ID`,
  LOAD_CONVERSATION_BY_USER_ID: `[${STATE_NAME}] Load conversation by User ID`,
  LOAD_MESSAGES_BY_CON_ID: `[${STATE_NAME}] Load messages by conversation ID`,
  INSERT_TOP_CONVERSATION: `[${STATE_NAME}] Insert top conversation`,
  LOAD_FOLLOWING_USERS: `[${STATE_NAME}] Load following users`,
  BLOCK_CONVERSATION_BY_ID: `[${STATE_NAME}] Block conversation by ID`,
  UNBLOCK_CONVERSATION_BY_ID: `[${STATE_NAME}] Unblock conversation by ID`,
  DELETE_CONVERSATION_BY_ID: `[${STATE_NAME}] Delete conversation by ID`,
  TOGGLE_NOTIFICATION_BY_ID: `[${STATE_NAME}] Toggle conversation notification by ID`,
  LOAD_UNREAD_MESSAGE_COUNT: `[${STATE_NAME}] Load unread message count`,
  MARK_AS_READ: `[${STATE_NAME}] Mark conversation as read`,
  SEND_MESSAGE: `[${STATE_NAME}] Send message`,
  LIKE_MESSAGE: `[${STATE_NAME}] Like message`,
  UNLIKE_MESSAGE: `[${STATE_NAME}] Unlike message`,
  INSERT_NEW_MESSAGE: `[${STATE_NAME}] Insert new message`,
  RECEIVE_NEW_MESSAGE: `[${STATE_NAME}] WS Receive ${MessengerSocketAction.NewMessage} message`,
  UPDATE_LIKE_MESSAGE: `[${STATE_NAME}] Update like mesasge by id`,
  UPDATE_READ_MESSAGE: `[${STATE_NAME}] Update read message`,
  UPDATE_BLOCK_CONVERSATION: `[${STATE_NAME}] Update block conversation`,
  UPDATE_COUNT: `[${STATE_NAME}] Update count`
}

export class SetConversations {
  static readonly type = ACTIONS.SET_CONVERSATIONS
  constructor(public readonly conversations: StateModel['conversations']) {}
}

export class LoadConversationById {
  static readonly type = ACTIONS.LOAD_CONVERSATION_BY_ID
  constructor(public readonly id: string) {}
}

export class LoadConversationByUserId {
  static readonly type = ACTIONS.LOAD_CONVERSATION_BY_USER_ID
  constructor(public readonly userId: string) {}
}
export class InsertTopConversation {
  static readonly type = ACTIONS.INSERT_TOP_CONVERSATION
  constructor(public readonly conversationId: string) {}
}

export class LoadFollowingUsers {
  static readonly type = ACTIONS.LOAD_FOLLOWING_USERS
  constructor(
    public readonly params: RelationControllerGetFollowingsRequestParams,
    public readonly willLoadMore: boolean = false
  ) {}
}

export class LoadMessagesByConId {
  static readonly type = ACTIONS.LOAD_MESSAGES_BY_CON_ID
  constructor(
    public readonly params: MessageControllerPaginateRequestParams,
    public readonly willLoadMore: boolean = false
  ) {}
}

export class BlockConversation {
  static readonly type = ACTIONS.BLOCK_CONVERSATION_BY_ID
  constructor(public readonly conversationId: string) {}
}

export class UnblockConversation {
  static readonly type = ACTIONS.UNBLOCK_CONVERSATION_BY_ID
  constructor(public readonly conversationId: string) {}
}

export class DeleteConversationById {
  static readonly type = ACTIONS.DELETE_CONVERSATION_BY_ID
  constructor(public readonly conversationId: string) {}
}
export class ToggleConversationNotificationById {
  static readonly type = ACTIONS.TOGGLE_NOTIFICATION_BY_ID
  constructor(public readonly conversationId: string) {}
}
export class MarkAsRead {
  static readonly type = ACTIONS.MARK_AS_READ
  constructor(public readonly conversationId: string) {}
}

export class SendMessage {
  static readonly type = ACTIONS.SEND_MESSAGE
  constructor(public readonly params: MessageControllerSendRequestParams) {}
}

export class LikeMessage {
  static readonly type = ACTIONS.LIKE_MESSAGE
  constructor(public readonly messageId: string) {}
}
export class UnlikeMessage {
  static readonly type = ACTIONS.UNLIKE_MESSAGE
  constructor(public readonly messageId: string) {}
}

export class LoadUnreadMessageCount {
  static readonly type = ACTIONS.LOAD_UNREAD_MESSAGE_COUNT
}

export class ReceiveNewMessage {
  static readonly type = ACTIONS.RECEIVE_NEW_MESSAGE
  constructor(public readonly payload: NewMessagePayload) {}
}

export class UpdateLikeMessage {
  static readonly type = ACTIONS.UPDATE_LIKE_MESSAGE
  constructor(public readonly messageId: string, public readonly type: 'like' | 'unlike') {}
}

export class UpdateReadMessage {
  static readonly type = ACTIONS.UPDATE_READ_MESSAGE
  constructor(public readonly conversationId: string) {}
}

export class UpdateBlockConversation {
  static readonly type = ACTIONS.UPDATE_BLOCK_CONVERSATION
  constructor(
    public readonly params: { conversationId: string; userId?: string; type: 'block' | 'unblock' }
  ) {}
}

export class UpdateCount {
  static readonly type = ACTIONS.UPDATE_COUNT
  constructor(public readonly isIncreased: boolean = true) {}
}
