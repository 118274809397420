import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MoreActionsButtonComponent } from './more-actions-button.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzButtonModule } from 'ng-zorro-antd/button'

const nzModules = [NzIconModule, NzButtonModule, NzDropDownModule, NzMenuModule]

@NgModule({
  declarations: [MoreActionsButtonComponent],
  imports: [CommonModule, nzModules],
  exports: [MoreActionsButtonComponent]
})
export class MoreActionsButtonModule {}
