import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { AddToken, FirebaseResponse, ReceiveFirebaseMessage } from 'src/app/features/base/firebase-root';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AuthDialogService } from './features/auth/util/auth-dialog';
import { AuthState, LoadToken } from './features/auth/store';
import { AutoTitleService } from './util/services/auto-title.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoadSettings } from './features/core/data-access/store';
import { Login, LoginFacebook, LoginGoogle } from './features/auth/store/actions';
import { map, switchMap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { MessageSocketService } from './features/messages/data-access';
import { SegmentTrackingService } from './util/services/segment.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private subscription = new Subscription()

  constructor(
    private afMessaging: AngularFireMessaging,
    private socketService: MessageSocketService,
    private store: Store,
    private actions: Actions,
    private autoTitle: AutoTitleService,
    private authDialogService: AuthDialogService,
    private segmentTrackingService: SegmentTrackingService
  ) {}

  ngOnInit() {
    this.autoTitle.setupAutoTitleListener({ postfix: ' | TryMe' })
    this.authDialogService.registerOpenDialogByQueryParams()
    const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn)
    this.store.dispatch(new LoadSettings())
    this.segmentTrackingService.connect()
    this.segmentTrackingService.page('Home')
    if (isLoggedIn) {
      this.store.dispatch(new LoadToken())
      this.registerTryMeWS()
      this.registerFirebaseMessaging()
    }
    this.whenLoginSuccess()
  }

  private whenLoginSuccess(): void {
    const loginSuccess$ = this.actions.pipe(ofActionSuccessful(Login))
    const loginFBSuccess$ = this.actions.pipe(ofActionSuccessful(LoginFacebook))
    const loginGoogleSuccess$ = this.actions.pipe(ofActionSuccessful(LoginGoogle))
    const whenSendSuccess$ = merge(loginSuccess$, loginFBSuccess$, loginGoogleSuccess$)
    whenSendSuccess$.subscribe(() => {
      this.registerTryMeWS()
      this.registerFirebaseMessaging()
    })
  }

  private registerFirebaseMessaging() {
    this.subscription.unsubscribe()
    this.subscription = this.afMessaging.requestPermission
      .pipe(
        switchMap(() => this.afMessaging.tokenChanges),
        switchMap(token => this.store.dispatch(new AddToken(token))),
        switchMap(() => this.afMessaging.messages),
        map((message: FirebaseResponse<string>) => ({
          ...message,
          data: { ...message.data, payload: JSON.parse(message.data.payload) }
        }))
      )
      .subscribe({
        next: message => this.store.dispatch(new ReceiveFirebaseMessage(message)),
        error: console.warn
      })
  }

  private registerTryMeWS() {
    this.socketService.connection()
  }
}
