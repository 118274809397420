/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './user-model';


export interface ActivityModelResponse { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    readonly userId: string;
    readonly actorId: string;
    readonly type: ActivityModelResponse.TypeEnum;
    readonly avatarUrl: string;
    readonly matchId: string;
    readonly postId: string;
    readonly challengeId: string;
    readonly challengeType: string;
    readonly originalCommentId: string;
    readonly commentId: string;
    readonly commentContent: string;
    readonly hashtag: string;
    readonly feedbackId: string;
    readonly feedbackMessageId: string;
    readonly reportUserId: string;
    readonly reportCommentId: string;
    readonly reportPostId: string;
    readonly readAt: number;
    readonly content: string;
    readonly time: string;
    readonly actor: UserModel;
    readonly relation: string;
}
export namespace ActivityModelResponse {
    export type TypeEnum = 'AcceptedGage' | 'CanceledGage' | 'CommentedPost' | 'Followed' | 'LikedPost' | 'LikedComment' | 'RejectedGage' | 'RepliedComment' | 'SentGage' | 'SharedPost' | 'NewFeedbackMessage' | 'RejectedVerification' | 'SuccessfulVerification' | 'SuccessReport' | 'RejectReport' | 'WarningReport' | 'AdminInactiveUser' | 'InactivePost' | 'InactiveComment' | 'UserReportPostRejected' | 'UserReportPostSuccessful' | 'UserReportPostWarning' | 'UserReportCommentRejected' | 'UserReportCommentSuccessful' | 'UserReportCommentWarning' | 'UpdateAppSettings' | 'FeedbackGiftChallenge' | 'ExpiredChallengeEvent' | 'RewardChallengeEvent' | 'RemindChallengeEvent' | 'NewChallengeReminder' | 'ChallengeApproved' | 'ChallengeRejected' | 'ViewPost' | 'ViewChallenge' | 'ViewMatch';
    export const TypeEnum = {
        AcceptedGage: 'AcceptedGage' as TypeEnum,
        CanceledGage: 'CanceledGage' as TypeEnum,
        CommentedPost: 'CommentedPost' as TypeEnum,
        Followed: 'Followed' as TypeEnum,
        LikedPost: 'LikedPost' as TypeEnum,
        LikedComment: 'LikedComment' as TypeEnum,
        RejectedGage: 'RejectedGage' as TypeEnum,
        RepliedComment: 'RepliedComment' as TypeEnum,
        SentGage: 'SentGage' as TypeEnum,
        SharedPost: 'SharedPost' as TypeEnum,
        NewFeedbackMessage: 'NewFeedbackMessage' as TypeEnum,
        RejectedVerification: 'RejectedVerification' as TypeEnum,
        SuccessfulVerification: 'SuccessfulVerification' as TypeEnum,
        SuccessReport: 'SuccessReport' as TypeEnum,
        RejectReport: 'RejectReport' as TypeEnum,
        WarningReport: 'WarningReport' as TypeEnum,
        AdminInactiveUser: 'AdminInactiveUser' as TypeEnum,
        InactivePost: 'InactivePost' as TypeEnum,
        InactiveComment: 'InactiveComment' as TypeEnum,
        UserReportPostRejected: 'UserReportPostRejected' as TypeEnum,
        UserReportPostSuccessful: 'UserReportPostSuccessful' as TypeEnum,
        UserReportPostWarning: 'UserReportPostWarning' as TypeEnum,
        UserReportCommentRejected: 'UserReportCommentRejected' as TypeEnum,
        UserReportCommentSuccessful: 'UserReportCommentSuccessful' as TypeEnum,
        UserReportCommentWarning: 'UserReportCommentWarning' as TypeEnum,
        UpdateAppSettings: 'UpdateAppSettings' as TypeEnum,
        FeedbackGiftChallenge: 'FeedbackGiftChallenge' as TypeEnum,
        ExpiredChallengeEvent: 'ExpiredChallengeEvent' as TypeEnum,
        RewardChallengeEvent: 'RewardChallengeEvent' as TypeEnum,
        RemindChallengeEvent: 'RemindChallengeEvent' as TypeEnum,
        NewChallengeReminder: 'NewChallengeReminder' as TypeEnum,
        ChallengeApproved: 'ChallengeApproved' as TypeEnum,
        ChallengeRejected: 'ChallengeRejected' as TypeEnum,
        ViewPost: 'ViewPost' as TypeEnum,
        ViewChallenge: 'ViewChallenge' as TypeEnum,
        ViewMatch: 'ViewMatch' as TypeEnum
    };
}


