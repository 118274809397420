/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RankingUserResponse } from './ranking-user-response';


export interface RankingResponse { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    type: RankingResponse.TypeEnum;
    order: string;
    icon: string;
    backgroundColor: string;
    prizeIcons: Array<string>;
    name: string;
    description: string;
    users: Array<RankingUserResponse>;
}
export namespace RankingResponse {
    export type TypeEnum = 'highestScore' | 'matchWinner' | 'reaction';
    export const TypeEnum = {
        HighestScore: 'highestScore' as TypeEnum,
        MatchWinner: 'matchWinner' as TypeEnum,
        Reaction: 'reaction' as TypeEnum
    };
}


