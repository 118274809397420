import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChooseCreateTypeDialogComponent } from './choose-create-type-dialog.component'
import { ChooseCreateTypeDialogService } from './choose-create-type-dialog.service'
import { RouterModule } from '@angular/router'
import { NzButtonModule } from 'ng-zorro-antd/button'

@NgModule({
  declarations: [ChooseCreateTypeDialogComponent],
  imports: [CommonModule, RouterModule, NzButtonModule]
})
export class ChooseCreateTypeDialogModule {
  static forRoot(): ModuleWithProviders<ChooseCreateTypeDialogModule> {
    return {
      ngModule: ChooseCreateTypeDialogModule,
      providers: [ChooseCreateTypeDialogService]
    }
  }
}
