import {
  AuthControllerLoginRequestParams,
  AuthControllerGetTokenAfterFacebookSignInRequestParams,
  AuthControllerGetTokenAfterGoogleSignInRequestParams,
  AuthControllerGetTokenAfterAppleSignInRequestParams,
  AuthControllerRegisterRequestParams,
  UserModel,
  LoginSuccessResponse
} from 'src/app/data-access/generated/iam'
import { STATE_NAME } from './state.model'

const ACTIONS = {
  LOGIN: `[${STATE_NAME}] Login`,
  LOGIN_FACEBOOK: `[${STATE_NAME}] Login by Facebook`,
  LOGIN_GOOGLE: `[${STATE_NAME}] Login by Google`,
  LOGIN_APPLE: `[${STATE_NAME}] Login by Apple`,
  LOG_OUT: `[${STATE_NAME}] Log out`,
  LOAD_TOKEN: `[${STATE_NAME}] Load token to OpenApiModule Configuration`,
  SET_REDIRECT_URL: `[${STATE_NAME}] Set redirect URL for using after login`,
  SAVE_REGISTER_PARAMS: `[${STATE_NAME}] Save register params`,
  SAVE_FORGOT_PASSWORD_TOKEN: `[${STATE_NAME}] Save forgot password token`,
  UPDATE_PROFILE: `[${STATE_NAME}] Update profile`,
  SAVE_LOGIN_INFO: `[${STATE_NAME}] Save login info`
}

export class Login {
  static readonly type = ACTIONS.LOGIN
  constructor(public readonly params: AuthControllerLoginRequestParams) {}
}
export class LoginFacebook {
  static readonly type = ACTIONS.LOGIN_FACEBOOK
  constructor(public readonly params: AuthControllerGetTokenAfterFacebookSignInRequestParams) {}
}

export class LoginGoogle {
  static readonly type = ACTIONS.LOGIN_GOOGLE
  constructor(public readonly params: AuthControllerGetTokenAfterGoogleSignInRequestParams) {}
}

export class LoginApple {
  static readonly type = ACTIONS.LOGIN_APPLE
  constructor(public readonly params: AuthControllerGetTokenAfterAppleSignInRequestParams) {}
}
export class LoadToken {
  static readonly type = ACTIONS.LOAD_TOKEN
}

export class Logout {
  static readonly type = ACTIONS.LOG_OUT
  constructor(public readonly willConfirm = true) {}
}

export class SaveRegisterParams {
  static readonly type = ACTIONS.SAVE_REGISTER_PARAMS
  constructor(public readonly params: AuthControllerRegisterRequestParams['registerDTO']) {}
}

export class UpdateProfile {
  static readonly type = ACTIONS.UPDATE_PROFILE
  constructor(public readonly profile: UserModel) {}
}

export class SaveLoginInfo {
  static readonly type = ACTIONS.SAVE_LOGIN_INFO
  constructor(public readonly loginInfo: Partial<LoginSuccessResponse>) {}
}
