<ng-container *ngIf="{ paused: paused$ | async, muted: muted$ | async } as view">
  <ng-container *ngIf="videoUrl">
    <progress [value]="percent$ | async" max="100" *ngIf="showProgress$ | async"></progress>
    <video
      #videoPlayer
      (click)="onClickVideo($event)"
      autoplay
      [src]="videoUrl"
      [muted]="view.muted ? 'muted' : ''"
      [poster]="poster"
      loop
    >
      Browser not supported
    </video>
  </ng-container>
  <section class="handler">
    <i
      nz-icon
      nzType="icons/fill:{{ view.paused ? 'play' : 'pause' }}"
      nz-tooltip
      [nzTooltipTitle]="view.paused ? 'Play' : 'Pause'"
      (click)="onClickVideo($event)"
    ></i>
    <i
      nz-icon
      nzType="icons/outline:volume-{{ view.muted ? 'off' : 'high' }}"
      nz-tooltip
      [nzTooltipTitle]="view.muted ? 'Turn on' : 'Mute'"
      (click)="onClickVideoMute($event)"
    ></i>
  </section>
</ng-container>
