import insertTextAtCursor from 'insert-text-at-cursor';
import { ActionItem } from 'src/app/ui/more-actions-button';
import { ActivatedRoute } from '@angular/router';
import { AuthState } from 'src/app/features/auth/store';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { CanShowUnsavedDialog } from 'src/app/util/services';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommentsState } from './comments-state.model';
import { delay, delayWhen, map, take } from 'rxjs/operators';
import { distinctUntilIdChanged, distinctWith, hasValue } from 'src/app/util/custom-rxjs';
import { DOCUMENT } from '@angular/common';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PageViewEvent, SegmentEvent } from 'src/app/util/services/constants/segment-event.constant';
import { PostActionType } from 'src/app/features/post/ui/post-item/post-item-state.model';
import { PostDetailComponentStore } from '../post-detail/post-detail.store';
import { PostItemComponentStore } from '../post-item/post-item.store';
import { PostResponse } from 'src/app/data-access/generated/challenge';
import { ReportDialogService } from 'src/app/ui/report-dialog/report-dialog.service';
import { RxState } from '@rx-angular/state';
import { SegmentTrackingService } from 'src/app/util/services/segment.service';
import { Store } from '@ngxs/store';

// Depend on Post Detail Component
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class CommentsComponent implements OnInit, CanShowUnsavedDialog {
  @ViewChild('loginIconTemplate') loginIconTemplate: TemplateRef<void>
  @ViewChild('commentTextarea') commentTextarea: ElementRef<HTMLTextAreaElement>
  @Output() onShare = new EventEmitter<void>()

  readonly challengeTypes = PostResponse.TypeEnum
  commentControl = this.postDetailComponentStore.commentControl
  editingForm = this.postComponentStore.editingForm
  willShowUnsavedDialog = false

  me$ = this.store.select(AuthState.profile).pipe(distinctUntilIdChanged())
  editing$ = this.postComponentStore.select('editing')
  post$ = this.postDetailComponentStore.select('post')
  comments$ = this.postDetailComponentStore.select('comments')
  commentsLoading$ = this.postDetailComponentStore.select('commentsLoading')
  replyInfo$ = this.postDetailComponentStore.select('replyInfo')
  selectedCommentId$ = this.activatedRoute.queryParams.pipe(
    map(({ commentId, originalCommentId }) => ({ commentId, originalCommentId }))
  )

  loadMoreComments = new BehaviorSubject(false)
  seeMore = false

  constructor(
    private store: Store,
    private clipboardService: Clipboard,
    private messageService: NzMessageService,
    private reportDialogService: ReportDialogService,
    private postComponentStore: PostItemComponentStore,
    private postDetailComponentStore: PostDetailComponentStore,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private segmentTrackingService: SegmentTrackingService
  ) {}

  ngOnInit(): void {
    this.registerLoadComments()
    this.scrollToComment()
    this.commentInputChange()
    this.postDetailComponentStore.connect('post', this.postComponentStore.select('post'))
    this.postComponentStore.connect(
      'post',
      this.postDetailComponentStore
        .select('post')
        .pipe(distinctWith(this.postComponentStore.select('post')))
    )
  }

  copyUrl() {
    this.clipboardService.copy(location.href)
    this.messageService.success('Sao chép liên kết thành công. Chia sẻ ngay! 🔥')
  }

  onPressEnter(event: KeyboardEvent): void {
    if (!event.shiftKey) {
      event.preventDefault()
      this.onClickSend()
    }
  }

  toggleLikeComment(commentId: string, wasLiked: boolean) {
    if (this.preCheckAuthorization('Like comment'))
      this.postDetailComponentStore.toggleLike({ commentId, current: wasLiked })
  }

  onClickViewLikes() {
    this.postComponentStore.openLikesDialog()
  }

  addEmoji(event: EmojiEvent) {
    insertTextAtCursor(this.commentTextarea.nativeElement, event.emoji.native + ' ')
  }

  onClickReportChallenge() {
    if (this.preCheckAuthorization('Report Challenge')) {
      const { _id } = this.postDetailComponentStore.get('post')
      this.reportDialogService.reportPost(_id)
    }
  }

  onClickReportUser() {
    if (this.preCheckAuthorization('Report User')) {
      const { _id } = this.postDetailComponentStore.get('post')
      this.reportDialogService.reportUser(_id)
    }
  }

  onClickReportComment(commentId: string, postId: string) {
    if (this.preCheckAuthorization('Report Comment'))
      this.reportDialogService.reportComment({ postId, commentId })
  }

  onClickLike() {
    if (this.preCheckAuthorization('Like Post')) this.postComponentStore.toggleLike()
  }

  focusCommentArea() {
    if (this.preCheckAuthorization('Focus Comment')) this.commentTextarea.nativeElement.focus()
  }

  setReplyInfo(replyInfo: CommentsState['replyInfo']) {
    if (this.preCheckAuthorization('Reply')) {
      this.postDetailComponentStore.set({ replyInfo })
      this.commentTextarea.nativeElement.focus()
    }
  }

  clearReplyInfo() {
    this.postDetailComponentStore.set({
      replyInfo: { commentId: undefined, userId: undefined, username: undefined }
    })
  }

  onClickSend() {
    const replyInfo = this.postDetailComponentStore.get('replyInfo')
    const { value: content } = this.commentControl
    this.postDetailComponentStore.comment({
      content,
      originalId: replyInfo?.commentId,
      replyFor: replyInfo?.userId
    })
  }

  onClickShare() {
    if (this.preCheckAuthorization('share')) this.onShare.emit()
  }

  onClickAction(action: ActionItem) {
    switch (action.id) {
      case PostActionType.CopyLink:
        this.postComponentStore.copyUrl()
        break
      case PostActionType.Download:
        this.postComponentStore.downloadMedia()
        break
      case PostActionType.ReportPost:
        this.postComponentStore.reportPost()
        break
      case PostActionType.BlockUser:
        this.postComponentStore.blockUser()
        break
      case PostActionType.EditPost:
        this.postComponentStore.setEditingMode(true)
        break
      case PostActionType.RemovePost:
        this.postComponentStore.removePost()
        break

      default:
        break
    }
  }

  onClickScore() {
    this.postComponentStore.openScoreDialog()
  }

  onClickUpdatePost() {
    this.postComponentStore.updatePost()
  }

  onClickDiscard() {
    this.postComponentStore.setEditingMode(false)
  }

  onClickRemovePost() {
    this.postComponentStore.removePost()
  }

  private registerLoadComments() {
    const postIdParams$: Observable<string> = this.activatedRoute.params.pipe(
      map(({ id }) => id),
      hasValue()
    )
    const postIdQueryParams$: Observable<string> = this.activatedRoute.queryParams.pipe(
      map(({ post }) => post),
      hasValue()
    )
    const root$ = combineLatest([merge(postIdParams$, postIdQueryParams$), this.loadMoreComments])
    this.postDetailComponentStore.hold(root$, ([postId, willLoadMore]) => {
      this.postDetailComponentStore.loadComments({ limit: 20, page: 1, postId }, willLoadMore)
    })
  }

  private scrollToComment() {
    this.selectedCommentId$
      .pipe(
        delayWhen(() => this.comments$),
        delay(300),
        take(1)
      )
      .subscribe(selectedCommentId => {
        const commentId = selectedCommentId.originalCommentId || selectedCommentId.commentId
        if (!commentId) return
        this.document
          .querySelector(`#comment-${commentId}`)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
  }

  private preCheckAuthorization(eventRequest: string) {
    const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn)
    if (!isLoggedIn) {
      this.segmentTrackingService.track(SegmentEvent.RequestLogin, {
        current: PageViewEvent.Post,
        request: eventRequest
      })
      this.postComponentStore.openLoginDialog()
    }
    return !!isLoggedIn
  }

  private commentInputChange() {
    this.postComponentStore.hold(this.commentControl.valueChanges, value => {
      this.willShowUnsavedDialog = !!value
    })
  }
}
