<ng-container
  *ngIf="{
    suggestions: suggestions$ | async,
    loading: loading$ | async
  } as view"
>
  <nz-input-group
    nz-dropdown
    [nzDropdownMenu]="menu"
    nzTrigger="click"
    [nzPrefix]="suffixIconButton"
    [nzSuffix]="inputClearTpl"
    class="h-full"
  >
    <input
      nz-input
      nzBorderless
      type="text"
      [formControl]="searchControl"
      (keydown.enter)="searchControl.value?.length >= 3 && goToExplorePage()"
      [placeholder]="'search.common' | translate"
      class="!text-base"
    />
  </nz-input-group>
  <ng-template #inputClearTpl>
    <i
      *ngIf="searchControl.value"
      nz-icon
      (click)="searchControl.setValue('')"
      nzType="icons/outline:close"
      class="cursor-pointer text-gray-600"
    ></i>
  </ng-template>
  <ng-template #suffixIconButton>
    <i nz-icon nzType="icons/outline:search" class="!text-gray-500"></i>
  </ng-template>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <ng-container *ngIf="view.loading; else loaded">
        <li nz-menu-item nzSpan="24" class="py-2 rounded-lg" *ngFor="let i of [1, 2, 3, 4]">
          <nz-skeleton
            [nzAvatar]="true"
            [nzActive]="true"
            [nzTitle]="false"
            [nzParagraph]="{ rows: 2 }"
          ></nz-skeleton>
        </li>
      </ng-container>

      <ng-template #loaded>
        <ng-container *ngIf="view.suggestions?.length; else empty">
          <li
            nz-menu-item
            *ngFor="let item of view.suggestions"
            class="suggestion-item"
            (click)="routeTo(item)"
          >
            <nz-avatar
              *ngIf="item.image; else notHaveImage"
              [nzSrc]="item.image"
              nzSize="large"
              nzIcon="user"
              class="flex-shrink-0"
            ></nz-avatar>
            <ng-template #notHaveImage>
              <i
                nz-icon
                nzType="icons/outline:search"
                class="search-icon bg-gray-200"
              ></i>
            </ng-template>
            <div class="flex-grow flex flex-col w-full">
              <div
                class="truncate max-w-[15rem]"
                [innerHTML]="
                  item.title.replace(
                    searchControl.value,
                    '<strong>' + searchControl.value + '</strong>'
                  )
                "
              ></div>
              <p class="text-gray-500 mb-0 text-sm font-light">
                {{ 'search.' + (item.type | lowercase) | translate }}
              </p>
            </div>
            <div class="flex items-center flex-shrink-0">
              <i nz-icon nzType="icons/outline:chevron-forward"></i>
            </div>
          </li>
          <li nz-menu-item class="suggestion-item" (click)="goToExplorePage()">
            <i nz-icon nzType="icons/outline:search" class="search-icon bg-primary-500 !text-white"></i>
            <div class="flex-grow text-primary-500">
              <div class="truncate max-w-[15rem]">
                Tìm kiếm cho
                <strong>{{ searchControl.value }}</strong>
              </div>
            </div>
            <div class="flex items-center flex-shrink-0">
              <i nz-icon nzType="icons/outline:chevron-forward"></i>
            </div>
          </li>
        </ng-container>
        <ng-template #empty>
          <section class="flex flex-col items-center pb-4">
            <img src="assets/images/searc-not-found.png" class="w-7/12 max-w-xs" />
            <span class="font-semibold">{{ 'search.notFound' | translate }}</span>
          </section>
        </ng-template>
      </ng-template>
    </ul>
  </nz-dropdown-menu>
</ng-container>
