<ng-container *ngLet="{ me: me$ | async, relation: relation$ | async } as view">
  <button
    *ngIf="view.me?._id !== friendId"
    nz-button
    [nzSize]="size"
    [nzType]="view.relation === 'friend' || view.relation === 'following' ? 'default' : 'primary'"
    (click)="!!view.me && onClickFollow($event)"
    [nzLoading]="loading$ | async"
    [disabled]="!friendId"
    nz-tooltip
    [nzTooltipTitle]="!!view.me ? '' : followLoginTooltipTemplate"
  >
    {{ 'user.BUTTONS.' + (view.relation || 'none') | translate }}
  </button>
</ng-container>
<ng-template #followLoginTooltipTemplate>
  <section class="text-center p-2">
    <p class="mb-1">Đăng nhập để theo dõi những thử thách thú vị</p>
    <button nz-button nzType="primary" routerLink="" [queryParams]="{ dialog: 'login' }">
      Đăng nhập ngay
    </button>
  </section>
</ng-template>
