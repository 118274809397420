import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { ReasonResponse } from 'src/app/data-access/generated/iam'
import { MyPagingModel } from 'src/app/data-access/missing.model'

@Component({
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportDialogComponent {
  @Input() title: string
  @Input() reasons: MyPagingModel<ReasonResponse>

  selectedId: string = null

  constructor(private modalRef: NzModalRef<ReportDialogComponent, string>) {}

  send() {
    this.modalRef.close(this.selectedId)
  }

  cancel() {
    this.modalRef.close()
  }
}
