/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MatchUserResponse } from './match-user-response';
import { MatchChallengeResponse } from './match-challenge-response';
import { PostResponse } from './post-response';


export interface MatchResponse { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    challengeType: string;
    avatarUrl: string;
    status?: MatchResponse.StatusEnum;
    canJoin?: boolean;
    approveJoin?: boolean;
    endTime?: number;
    joiners: Array<MatchUserResponse>;
    joinerQuantity: number;
    user: MatchUserResponse;
    challenge: MatchChallengeResponse;
    posts: Array<PostResponse>;
    isJoined: boolean;
    joinedRate: string;
    shared: number;
    liked: number;
    score: number;
    message: string;
    joined: number;
    hashtag: string;
}
export namespace MatchResponse {
    export type StatusEnum = 'NEW' | 'HAPPENING' | 'CANCELED' | 'FINISHED' | 'EXPIRED';
    export const StatusEnum = {
        NEW: 'NEW' as StatusEnum,
        HAPPENING: 'HAPPENING' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum
    };
}


