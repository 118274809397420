import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';
import { interval } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { vi } from 'date-fns/locale';

@Component({
  selector: 'app-post-gift-countdown',
  templateUrl: './post-gift-countdown.component.html',
  styleUrls: ['./post-gift-countdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostGiftCountdownComponent {
  @Input() endTime: number

  get nowInMillisecond() {
    return new Date().getTime()
  }

  getGiftInterval(timeEnd: number) {
    const MINUTE = 60 * 1000
    return interval(MINUTE).pipe(
      startWith(0),
      takeWhile((tick) => tick * MINUTE <= timeEnd),
      map(() => {
        const nowInMillisecond = new Date().getTime()
        const duration = intervalToDuration({ start: nowInMillisecond, end: timeEnd })
        return formatDuration(duration, {
          format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
          locale: vi
        })
      })
    )
  }
}
