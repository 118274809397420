import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AuthDialogResult, AuthDialogType, NzModalContentType } from '../../util/auth-dialog/auth-dialog.types';
import { AuthService, LoginSuccessResponse, PasswordService, RegisterResponse, UpdatePasswordResponse, VerifyUserDTO } from 'src/app/data-access/generated/iam';
import { AuthState, SaveLoginInfo } from '../../store';
import { BehaviorSubject, EMPTY, interval, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { formatNumber } from '@angular/common';
import { LoadingState, MyResponseModel } from 'src/app/data-access/missing.model';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { RxState } from '@rx-angular/state';
import { Store } from '@ngxs/store';
import { throwStatusCodeIfError } from 'src/app/util/custom-rxjs';

interface OtpVerificationState extends LoadingState {
  requestId: string
  resendLoading: boolean
  waitingLoading: boolean
}
@Component({
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class OtpVerificationComponent implements OnInit, AfterViewInit {
  readonly THREE_MINS = 3 * 60

  private resetValidTick = new BehaviorSubject<number>(0)
  private resendAction = new Subject<void>()
  private submitAction = new Subject<void>()

  @ViewChildren(NzInputDirective, { read: ElementRef }) inputs: QueryList<
    ElementRef<HTMLInputElement>
  >

  registerParams$ = this.store.select(AuthState.registerParams)

  loading$ = this.state.select('loading')
  waitingLoading$ = this.state.select('waitingLoading')
  requestId$ = this.state.select('requestId')

  interval$ = this.resetValidTick.asObservable().pipe(switchMap(() => interval(1000)))

  form = this.fb.group({
    otps: this.fb.array([])
  })

  countDown = (count: number, total: number) => {
    const timeLeft = total - count
    if (timeLeft < 0) return '00:00'
    return `${Math.floor(timeLeft / 60)}:${formatNumber(timeLeft % 60, 'en', '2.0-0')}`
  }

  constructor(
    private fb: FormBuilder,
    private state: RxState<OtpVerificationState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private nzMessageService: NzMessageService,
    private passwordService: PasswordService,
    private authService: AuthService,
    private modalRef: NzModalRef<NzModalContentType, AuthDialogResult>
  ) {}

  get otpFormArray() {
    return this.form.get('otps') as FormArray
  }

  ngOnInit(): void {
    this.submitEffect()
    this.resendEffect()
    for (let index = 0; index < 6; index++) {
      const control = new FormControl(null, Validators.required)
      this.otpFormArray.push(control)
    }

    const requestIdQueryParam$ = this.activatedRoute.queryParams.pipe(
      map(({ requestId }) => requestId)
    )
    this.state.connect('requestId', requestIdQueryParam$)
    this.state.hold(requestIdQueryParam$, requestId => {
      if (!requestId) {
        this.nzMessageService.error('Đã có lỗi xảy ra.')
        this.router.navigate([], {
          queryParams: { dialog: AuthDialogType.FORGOT_PASSWORD }
        })
      }
    })
  }

  ngAfterViewInit() {
    this.inputs.forEach((input, index) => {
      input.nativeElement.addEventListener('paste', pasteEvent => {
        this.onPaste(pasteEvent)
      })
      input.nativeElement.addEventListener('keydown', event => {
        this.onKeyDown(event, index)
      })
    })
  }

  resendOtp() {
    this.resendAction.next()
  }

  onSubmit() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
    if (this.form.valid) {
      this.submitAction.next()
    }
  }

  private submitEffect() {
    const fromRegister = this.activatedRoute.snapshot.queryParams.from === 'register'
    const submitEffect$ = this.submitAction.pipe(
      tap(() => this.state.set({ loading: true })),
      map(() => this.otpFormArray.value),
      withLatestFrom(this.requestId$),
      switchMap(([otps, requestId]) => {
        const params = { requestId, code: otps.join('') }
        if (fromRegister) return this.handleAfterRegister(params)
        return this.handleAfterForgotPassword(params)
      })
    )
    this.state.hold(submitEffect$)
  }

  private resendEffect() {
    const email$ = this.activatedRoute.queryParams.pipe(map(({ email }) => email))
    const resendEffect$ = this.resendAction.pipe(
      tap(() => this.state.set({ resendLoading: true })),
      withLatestFrom(email$, (_, email) => email),
      switchMap(email =>
        this.passwordService
          .passwordControllerForgotPassword({ forgotPasswordDTO: { email } })
          .pipe(
            throwStatusCodeIfError(),
            tap((response: MyResponseModel<RegisterResponse>) => {
              this.state.set({ resendLoading: false, requestId: response.data.requestId })
              this.resetValidTick.next(0)
            }),
            catchError(error => {
              this.state.set({ resendLoading: false })
              this.nzMessageService.error(error.message)
              return EMPTY
            })
          )
      )
    )
    this.state.hold(resendEffect$)
  }

  private onKeyDown(event: KeyboardEvent, index: number): void {
    const nextIndex = (index + 1) % 6
    const isNumber = '0123456789'.includes(event.key)
    if ((event.ctrlKey || event.metaKey || isNumber) === false) return event.preventDefault()

    if (isNumber) {
      this.otpFormArray.controls[index].setValue(event.key)
      event.preventDefault()
      this.inputs.get(nextIndex).nativeElement.focus()
    }
  }

  private onPaste(event: ClipboardEvent): void {
    const pasteText = event.clipboardData.getData('text')?.trim() ?? ''
    const matches = pasteText.match(/\d{6}/)
    if (matches?.length > 0) {
      for (let i = 0; i < 6; i++) {
        const control = this.otpFormArray.controls[i]
        control.setValue(matches[0][i])
      }
    } else event.preventDefault()
  }

  private handleAfterRegister(params: VerifyUserDTO) {
    return this.authService.authControllerVerifyRegistration({ verifyUserDTO: params }).pipe(
      throwStatusCodeIfError(),
      switchMap((response: MyResponseModel<LoginSuccessResponse>) => {
        this.state.set({ loading: false, waitingLoading: true })
        return this.store.dispatch(new SaveLoginInfo(response.data))
      }),
      tap(() => this.modalRef.close()),
      catchError(error => {
        this.state.set({ loading: false })
        this.nzMessageService.error(error.message)
        return EMPTY
      })
    )
  }

  private handleAfterForgotPassword(params: VerifyUserDTO) {
    return this.passwordService.passwordControllerVerifyOTP({ verifyPasswordOtpDTO: params }).pipe(
      throwStatusCodeIfError(),
      tap((response: MyResponseModel<UpdatePasswordResponse>) => {
        this.store.dispatch(new SaveLoginInfo({ token: response.data.token }))
        this.modalRef.close({ type: AuthDialogType.RESET_PASSWORD })
      }),
      catchError(error => {
        this.nzMessageService.error(error.message)
        return EMPTY
      }),
      finalize(() => this.state.set({ loading: false }))
    )
  }
}
