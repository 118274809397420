<ng-container *ngIf="count; else noOneJoinYet">
  <ul class="relative h-7 mb-0" [style.width.rem]="count + 1">
    <li
      *ngFor="let avatarUrl of avatars; index as i"
      class="
        absolute
        top-0
        w-7
        h-7
        rounded-full
        border-2 border-white border-opacity-50
        overflow-hidden
      "
      [style.left.rem]="i"
    >
      <img [src]="avatarUrl" alt="" class="object-cover" />
    </li>
  </ul>
  <strong>
    Đã có
    <span class="text-tryme-primary">{{ count | numberToText }} người tham gia</span>
    <span *ngIf="!isBrief">, bạn có muốn trả lời câu hỏi không?</span>
  </strong>
</ng-container>

<ng-template #noOneJoinYet>
  <strong>Hãy là người đầu tiên trả lời 🔥</strong>
</ng-template>
