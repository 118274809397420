import { authRoutes, IsNotLoggedInGuard } from './features/auth/util'
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { LayoutComponent } from './features/core/ui/layout/layout.component'
import { LayoutModule } from './features/core/ui/layout/layout.module'
import { rootRoutes } from './util/root.routes'
import { UnsavedChangesGuard } from './util/services'

const routes: Routes = [
  /**
   *  Music outlet in append-music-dialog.component.html
   * */
  {
    path: 'basic',
    outlet: 'music',
    loadChildren: () =>
      import('./features/challenge/ui/basic-music-layout/basic-music-layout.module').then(
        m => m.BasicMusicLayoutModule
      ),
    data: { animation: 'isLeft' }
  },
  {
    path: 'search',
    outlet: 'music',
    loadChildren: () =>
      import('./features/challenge/ui/music-search/music-search.module').then(
        m => m.MusicSearchModule
      ),
    data: { animation: 'isRight' }
  },
  {
    path: 'topics',
    outlet: 'music',
    loadChildren: () =>
      import('./features/challenge/ui/music-topics/music-topics.module').then(
        m => m.MusicTopicsModule
      ),
    data: { animation: 'isRight' }
  },
  { path: 'auth', children: authRoutes, canActivate: [IsNotLoggedInGuard] },
  {
    path: 'm/terms-of-service',
    loadChildren: () =>
      import('./ui/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule)
  },
  {
    path: '',
    component: LayoutComponent,
    children: rootRoutes,
    canDeactivate: [UnsavedChangesGuard]
  }
]

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
