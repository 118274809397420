import { Injectable } from '@angular/core'
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store'
import { STATE_NAME, INITIAL_STATE, StateModel, ReasonKey, ReasonValue } from './state.model'
import {
  LoadUnreadNotificationCount,
  MarkAsReadAll,
  MarkAsRead,
  LoadNotifications,
  LoadMoreNotifications,
  LoadReasons,
  LoadSettings
} from './actions'
import { tap, map } from 'rxjs/operators'
import { ActivityService, ActivityModelResponse } from 'src/app/data-access/generated/activity'
import { MyPagingModel } from 'src/app/data-access/missing.model'
import { Logout } from 'src/app/features/auth/store'
import { MyResponseModel } from '../../../../data-access/missing.model'
import {
  ReceiveCommentedPost,
  ReceiveFollowed,
  ReceiveSharedPost,
  ReceiveLikedPost
} from '../../../base/firebase-root/store/actions'
import {
  AppService,
  AppSettingModel,
  ReasonResponse,
  ReasonService
} from 'src/app/data-access/generated/iam'
import produce from 'immer'

@State<StateModel>({
  name: STATE_NAME,
  defaults: INITIAL_STATE
})
@Injectable()
export class CoreState {
  @Selector()
  static unreadNotiCount({ unreadNotiCount }: StateModel) {
    return unreadNotiCount
  }
  @Selector()
  static notifications({ notifications }: StateModel) {
    return notifications
  }
  @Selector()
  static errorMessage({ errorMessage }: StateModel) {
    return errorMessage
  }
  @Selector()
  static appSettings({ settings }: StateModel) {
    return settings
  }

  static reasonOf(type: ReasonValue) {
    return createSelector<(state: StateModel) => MyPagingModel<ReasonResponse>>(
      [CoreState],
      state => state[type]
    )
  }

  constructor(
    private activityService: ActivityService,
    private reasonService: ReasonService,
    private appService: AppService
  ) { }

  @Action(ReceiveLikedPost)
  @Action(ReceiveSharedPost)
  @Action(ReceiveFollowed)
  @Action(ReceiveCommentedPost)
  @Action(LoadUnreadNotificationCount, { cancelUncompleted: true })
  LoadUnreadNotificationCount({ patchState }: StateContext<StateModel>) {
    return this.activityService
      .activityControllerGetUnreadQuantity()
      .pipe(tap(response => patchState({ unreadNotiCount: response.data as unknown as number })))
  }

  @Action(MarkAsReadAll, { cancelUncompleted: true })
  MarkAsReadAll({ getState, patchState }: StateContext<StateModel>) {
    return this.activityService.activityControllerMakeAsReadAll().pipe(
      tap(() =>
        patchState({
          unreadNotiCount: 0,
          notifications: produce(getState().notifications, draft => {
            draft.data.forEach(noti => {
              noti.readAt = new Date().getTime()
            })
          })
        })
      )
    )
  }

  @Action(MarkAsRead, { cancelUncompleted: true })
  MarkAsRead({ patchState, getState }: StateContext<StateModel>, { params }: MarkAsRead) {
    return this.activityService.activityControllerMakeAsRead(params).pipe(
      tap(() => {
        const { unreadNotiCount = 0 } = getState()
        patchState({ unreadNotiCount: unreadNotiCount > 0 ? unreadNotiCount - 1 : 0 })
      })
    )
  }

  @Action(LoadNotifications, { cancelUncompleted: true })
  LoadNotifications({ patchState }: StateContext<StateModel>, { params }: LoadNotifications) {
    return this.activityService
      .activityControllerPaginate(params)
      .pipe(
        tap(response =>
          patchState({ notifications: response.data as MyPagingModel<ActivityModelResponse> })
        )
      )
  }

  @Action(LoadMoreNotifications, { cancelUncompleted: true })
  LoadMoreNotifications({ patchState, getState }: StateContext<StateModel>) {
    const notifications = getState().notifications
    return this.activityService
      .activityControllerPaginate({ page: notifications.page + 1, limit: notifications.limit })
      .pipe(
        map(response => response.data as MyPagingModel<ActivityModelResponse>),
        tap(response => {
          patchState({
            notifications: {
              ...notifications,
              data: notifications.data.concat(response.data)
            }
          })
        })
      )
  }

  @Action(LoadReasons)
  LoadReasons({ patchState, getState }: StateContext<StateModel>, { params }: LoadReasons) {
    const key = ReasonKey[params.type]
    if (getState()[key]) return
    return this.reasonService
      .reasonControllerPaginate(params)
      .pipe(
        tap((response: MyResponseModel<MyPagingModel<ReasonResponse>>) =>
          patchState({ [key]: response.data })
        )
      )
  }

  @Action(LoadSettings)
  LoadSettings({ patchState }: StateContext<StateModel>) {
    return this.appService
      .appControllerGetSettings()
      .pipe(
        tap((response: MyResponseModel<AppSettingModel>) => patchState({ settings: response.data }))
      )
  }

  @Action(Logout)
  Logout({ setState }: StateContext<StateModel>) {
    setState(INITIAL_STATE)
  }
}
