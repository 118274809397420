import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { STATE_NAME, INITIAL_STATE, StateModel } from './state.model'
import {
  SetConversations,
  InsertTopConversation,
  LoadFollowingUsers,
  LoadMessagesByConId,
  LoadConversationById,
  BlockConversation,
  UnblockConversation,
  DeleteConversationById,
  ToggleConversationNotificationById,
  MarkAsRead,
  SendMessage,
  LikeMessage,
  UnlikeMessage,
  LoadUnreadMessageCount,
  LoadConversationByUserId,
  ReceiveNewMessage,
  UpdateLikeMessage,
  UpdateReadMessage,
  UpdateBlockConversation
  // UpdateCount
} from './actions'
import { catchError, delayWhen, take, tap } from 'rxjs/operators'
import {
  ConversationResponse,
  ConversationService,
  MessageResponse,
  MessageService
} from 'src/app/data-access/generated/messenger'
import { MyPagingModel, MyResponseModel } from 'src/app/data-access/missing.model'
import { produce } from 'immer'
import { RelationService, FollowResponse } from 'src/app/data-access/generated/relation'
import { AuthState, Logout } from 'src/app/features/auth/store'
import { getUserInConversation } from '../../util/functions/get-user-in-conversation'
import { hasValue, precheckResponse } from 'src/app/util/custom-rxjs'
import { NEVER } from 'rxjs'
import { NzMessageService } from 'ng-zorro-antd/message'
import { ConfirmDialogComponent } from 'src/app/ui/confirm-dialog/confirm-dialog.component'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Title } from '@angular/platform-browser'
import { CONFIRM_DIALOG_MODAL_CONFIG } from 'src/app/ui/confirm-dialog/confirm-dialog.config'
@State<StateModel>({
  name: STATE_NAME,
  defaults: INITIAL_STATE
})
@Injectable()
export class MessengerState {
  @Selector()
  static conversations({ conversations }: StateModel) {
    return conversations
  }
  @Selector()
  static selectedConversation({ selectedConversation }: StateModel) {
    return selectedConversation
  }
  @Selector()
  static selectedConversationMessages({ selectedConversationMessages }: StateModel) {
    return selectedConversationMessages
  }
  @Selector()
  static myFollowingUsers({ myFollowingUsers }: StateModel) {
    return myFollowingUsers
  }
  @Selector()
  static unreadMessageCount({ unreadMessageCount }: StateModel) {
    return unreadMessageCount
  }
  @Selector()
  static errorMessage({ errorMessage }: StateModel) {
    return errorMessage
  }

  constructor(
    private conversationService: ConversationService,
    private messageService: MessageService,
    private relationService: RelationService,
    private store: Store,
    private nzMessageService: NzMessageService,
    private nzModalService: NzModalService,
    private title: Title
  ) {}

  @Action(SetConversations, { cancelUncompleted: true })
  SetConversations(
    { patchState, getState }: StateContext<StateModel>,
    { conversations }: SetConversations
  ) {
    const currentConversations = getState().conversations
    if (JSON.stringify(currentConversations) !== JSON.stringify(conversations))
      patchState({ conversations })
  }

  @Action(LoadConversationByUserId)
  CreateConversationById(
    { patchState, getState }: StateContext<StateModel>,
    { userId }: LoadConversationByUserId
  ) {
    return this.conversationService.conversationControllerGetConversationByUser({ userId }).pipe(
      delayWhen(() => this.store.select(MessengerState.conversations).pipe(hasValue(), take(1))),
      tap((response: MyResponseModel<ConversationResponse>) =>
        patchState({
          selectedConversation: response.data,
          conversations: produce(getState().conversations, draft => {
            if (!draft?.data.find(con => con._id === response.data._id))
              draft.data.unshift(response.data)
          })
        })
      )
    )
  }

  @Action(InsertTopConversation, { cancelUncompleted: true })
  InsertTopConversation(
    { patchState, getState }: StateContext<StateModel>,
    { conversationId: id }: InsertTopConversation
  ) {
    return this.conversationService.conversationControllerGet({ id }).pipe(
      tap(response => {
        const responseTrueType = response as MyResponseModel<ConversationResponse>
        const { conversations } = getState()
        if (conversations) {
          const conversationIndex = conversations.data.findIndex(c => c._id === id)
          if (conversationIndex > -1) {
            return patchState({
              conversations: produce(conversations, conversationsDraft => {
                conversationsDraft.data.splice(conversationIndex, 1)
                conversationsDraft.data.unshift(responseTrueType.data)
              })
            })
          }
          patchState({
            conversations: produce(conversations, conversationsDraft => {
              conversationsDraft.data.unshift(responseTrueType.data)
              conversationsDraft.total++
            })
          })
        }
      })
    )
  }

  @Action(LoadFollowingUsers, { cancelUncompleted: true })
  LoadFollowingUsers(
    { patchState, getState }: StateContext<StateModel>,
    { params, willLoadMore }: LoadFollowingUsers
  ) {
    const { myFollowingUsers } = getState()
    const processedParams =
      willLoadMore && myFollowingUsers ? { ...params, page: myFollowingUsers.page + 1 } : params
    return this.relationService.relationControllerGetFollowings(processedParams).pipe(
      tap((response: MyResponseModel<MyPagingModel<FollowResponse>>) => {
        const processedConversations = willLoadMore
          ? produce(myFollowingUsers, draft => {
              draft.page = response.data.page
              draft.data = myFollowingUsers.data.concat(response.data.data)
            })
          : response.data
        patchState({ myFollowingUsers: processedConversations })
      })
    )
  }

  @Action(LoadConversationById, { cancelUncompleted: true })
  LoadConversationById(
    { patchState, getState }: StateContext<StateModel>,
    { id }: LoadConversationById
  ) {
    const { conversations } = getState()
    const matchedConversation = conversations?.data.find(con => con._id === id)
    if (matchedConversation) patchState({ selectedConversation: matchedConversation })

    return this.conversationService.conversationControllerGet({ id }).pipe(
      tap(response =>
        patchState({
          selectedConversation: <ConversationResponse>response.data
        })
      )
    )
  }

  @Action(LoadMessagesByConId, { cancelUncompleted: true })
  LoadMessagesByConId(
    { patchState, getState }: StateContext<StateModel>,
    { params, willLoadMore }: LoadMessagesByConId
  ) {
    const { selectedConversationMessages: messagePaging } = getState()
    const processedParams =
      willLoadMore && messagePaging ? { ...params, page: messagePaging.page + 1 } : params

    return this.messageService.messageControllerPaginate(processedParams).pipe(
      tap((response: MyResponseModel<MyPagingModel<MessageResponse>>) => {
        const processedConversations = willLoadMore
          ? produce(messagePaging, draft => {
              draft.page = response.data.page
              draft.data = messagePaging.data.concat(response.data.data)
            })
          : response.data
        patchState({ selectedConversationMessages: processedConversations })
      })
    )
  }

  @Action(BlockConversation)
  BlockConversation({ dispatch }: StateContext<StateModel>, { conversationId }: BlockConversation) {
    return this.conversationService
      .conversationControllerBlockConversation({ id: conversationId })
      .pipe(
        precheckResponse(),
        tap(() => dispatch(new UpdateBlockConversation({ conversationId, type: 'block' }))),
        catchError(() => {
          this.nzMessageService.error('Chặn cuộc trò chuyện thất bại. Xin vui lòng thử lại.')
          return NEVER
        })
      )
  }

  @Action(UnblockConversation)
  UnblockConversation(
    { dispatch }: StateContext<StateModel>,
    { conversationId }: UnblockConversation
  ) {
    return this.conversationService
      .conversationControllerUnblockConversation({ id: conversationId })
      .pipe(
        precheckResponse(),
        tap(() => dispatch(new UpdateBlockConversation({ conversationId, type: 'unblock' }))),
        catchError(() => {
          this.nzMessageService?.error('Bỏ chặn cuộc trò chuyện thất bại. Xin vui lòng thử lại.')
          return NEVER
        })
      )
  }

  @Action(DeleteConversationById)
  DeleteConversationById(
    { patchState, getState }: StateContext<StateModel>,
    { conversationId: id }: DeleteConversationById
  ) {
    return this.nzModalService.create({
      nzContent: ConfirmDialogComponent,
      nzComponentParams: {
        title: 'Xoá cuộc trò chuyện',
        message: `Bạn có chắc chắn xoá cuộc trò chuyện này? Tất cả tin nhắn sẽ không thể khôi phục`
      },
      ...CONFIRM_DIALOG_MODAL_CONFIG,
      nzOkText: 'Xóa',
      nzOnOk: () => {
        this.conversationService
          .conversationControllerDelete({ id })
          .pipe(
            tap(response => {
              if (response.data as unknown as boolean) {
                const newState = produce(getState(), draft => {
                  if (draft.conversations) {
                    const indexOfCon = draft.conversations.data.findIndex(c => c._id === id)
                    draft.conversations.data.splice(indexOfCon, 1)
                  }
                  if (draft.selectedConversation?._id === id) draft.selectedConversation = null
                })
                patchState(newState)
              }
            })
          )
          .subscribe()
      }
    })
  }

  @Action(ToggleConversationNotificationById)
  ToggleConversationNotificationById(
    { patchState, getState }: StateContext<StateModel>,
    { conversationId: id }: ToggleConversationNotificationById
  ) {
    const me = this.store.selectSnapshot(AuthState.profile)
    const { conversations } = getState()
    const targetConversation = conversations.data.find(c => c._id === id)
    const targetCurrentNotification = getUserInConversation(targetConversation, me._id).notification
    return this.conversationService
      .conversationControllerUpdate({
        id,
        updateConversationDTO: { notification: !targetCurrentNotification }
      })
      .pipe(
        tap(response => {
          if (response.data as unknown as boolean) {
            const newState = produce(getState(), draft => {
              getUserInConversation(
                draft.conversations.data.find(c => c._id === id),
                me._id
              ).notification = !targetCurrentNotification
              if (draft.selectedConversation?._id === id)
                getUserInConversation(draft.selectedConversation, me._id).notification =
                  !targetCurrentNotification
            })
            patchState(newState)
          }
        })
      )
  }

  @Action(MarkAsRead)
  MarkAsRead({ dispatch }: StateContext<StateModel>, { conversationId }: MarkAsRead) {
    return this.conversationService.conversationControllerMarkAsRead({ id: conversationId }).pipe(
      precheckResponse(),
      tap(() => dispatch(new UpdateReadMessage(conversationId))),
      catchError(() => NEVER)
    )
  }

  @Action(SendMessage)
  SendMessage(_: StateContext<StateModel>, { params }: SendMessage) {
    return this.messageService.messageControllerSend(params).pipe(
      precheckResponse(),
      catchError(() => {
        this.nzMessageService?.error('Gửi tin nhấn thất bại. Xin vui lòng thử lại.')
        return NEVER
      })
    )
  }

  @Action(LikeMessage)
  LikeMessage({ dispatch }: StateContext<StateModel>, { messageId }: LikeMessage) {
    return this.messageService.messageControllerLike({ id: messageId }).pipe(
      precheckResponse(),
      tap(() => dispatch(new UpdateLikeMessage(messageId, 'like'))),
      catchError(() => NEVER)
    )
  }

  @Action(UnlikeMessage)
  UnlikeMessage({ dispatch }: StateContext<StateModel>, { messageId }: UnlikeMessage) {
    return this.messageService.messageControllerUnlike({ id: messageId }).pipe(
      precheckResponse(),
      tap(() => dispatch(new UpdateLikeMessage(messageId, 'unlike'))),
      catchError(() => NEVER)
    )
  }

  @Action(ReceiveNewMessage, { cancelUncompleted: true })
  ReceiveNewMessage(
    { dispatch, patchState, getState }: StateContext<StateModel>,
    { payload }: ReceiveNewMessage
  ) {
    const { conversations, selectedConversation, selectedConversationMessages } = getState()
    if (conversations && location.pathname.includes('/messages/'))
      dispatch(new InsertTopConversation(payload.conversationId))
    if (selectedConversation?._id === payload.conversationId && selectedConversationMessages) {
      if (location.pathname.includes('/messages/')) dispatch(new MarkAsRead(payload.conversationId))
      patchState({
        selectedConversationMessages: produce(selectedConversationMessages, draft => {
          const newMessage = {
            _id: payload.messageId,
            conversationId: payload.conversationId,
            content: payload.messageContent,
            userId: payload.userId,
            createdAt: new Date().getTime(),
            urls: payload.urls
          }
          draft.data.unshift(newMessage as MessageResponse)
        })
      })
    } else {
      dispatch(new LoadUnreadMessageCount())
    }
  }

  @Action(UpdateLikeMessage, { cancelUncompleted: true })
  UpdateLikeMessage(
    { patchState, getState }: StateContext<StateModel>,
    { messageId, type }: UpdateLikeMessage
  ) {
    const { selectedConversationMessages } = getState()
    const me = this.store.selectSnapshot(AuthState.profile)
    if (selectedConversationMessages?.data.some(m => m._id === messageId)) {
      const updatedMessages = produce(selectedConversationMessages, draft => {
        const targetMessage = draft.data.find(m => m._id === messageId)
        if (type === 'like') {
          targetMessage.wasLiked = true
          if (targetMessage.liked) targetMessage.liked.push(me._id)
          else targetMessage.liked = [me._id]
        } else {
          targetMessage.wasLiked = false
          targetMessage.liked = targetMessage.liked.filter(userId => userId !== me._id)
        }
      })
      patchState({ selectedConversationMessages: updatedMessages })
    }
  }

  @Action(UpdateReadMessage, { cancelUncompleted: true })
  UpdateReadMessage(
    { patchState, getState, dispatch }: StateContext<StateModel>,
    { conversationId }: UpdateReadMessage
  ) {
    const me = this.store.selectSnapshot(AuthState.profile)
    const newState = produce(getState(), draft => {
      draft.conversations?.data.find(c => c._id === conversationId)?.lastMessage.readAt.push(me._id)
      draft.selectedConversation?.lastMessage?.readAt.push(me._id)
    })
    patchState(newState)
    dispatch(new LoadUnreadMessageCount())
  }

  @Action(UpdateBlockConversation, { cancelUncompleted: true })
  UpdateBlockConversation(
    { patchState, getState }: StateContext<StateModel>,
    { params: { conversationId, userId, type } }: UpdateBlockConversation
  ) {
    const me = this.store.selectSnapshot(AuthState.profile)
    const newState = produce(getState(), draft => {
      let blockerIdValue = ''
      if (type === 'block') blockerIdValue = userId ?? me._id
      if (draft.conversations)
        draft.conversations.data.find(c => c._id === conversationId).blockerId = blockerIdValue
      if (draft.selectedConversation) draft.selectedConversation.blockerId = blockerIdValue
    })
    patchState(newState)
  }

  @Action(LoadUnreadMessageCount, { cancelUncompleted: true })
  LoadUnreadMessageCount({ patchState }: StateContext<StateModel>) {
    return this.conversationService.conversationControllerGetUnread().pipe(
      tap(({ data }) => {
        const title = this.title.getTitle()
        if (data) this.title.setTitle(`${data} 🔔 ${title.replace(/.+ 🔔\s+/, '')}`)
        else this.title.setTitle(title.replace(/.+ 🔔 /, ''))
        patchState({ unreadMessageCount: data as unknown as number })
      })
    )
  }

  // @Action(UpdateCount, { cancelUncompleted: true })
  // IncreaseCount({ patchState, getState }: StateContext<StateModel>, { isIncreased }: UpdateCount) {
  //   const count = (getState().unreadMessageCount ?? 0) + (isIncreased ? 1 : -1)
  //   const positiveCount = count < 0 ? 0 : count
  //   if (positiveCount) this.title.setTitle(`(${positiveCount} cuộc trò chuyện mới) | TryMe`)
  //   else this.title.setTitle(`TryMe`)
  //   patchState({ unreadMessageCount: positiveCount })
  // }

  @Action(Logout)
  Logout({ setState }: StateContext<StateModel>) {
    setState(INITIAL_STATE)
  }
}
