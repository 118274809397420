import { ClipboardModule } from '@angular/cdk/clipboard'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { PickerModule } from '@ctrl/ngx-emoji-mart'
import { TranslateModule } from '@ngx-translate/core'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzCommentModule } from 'ng-zorro-antd/comment'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzListModule } from 'ng-zorro-antd/list'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzSpaceModule } from 'ng-zorro-antd/space'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { LikedUsersDialogModule } from 'src/app/features/post/ui/liked-users-dialog'
import { PostActionsButtonModule } from 'src/app/features/post/ui/post-actions-button'
import { PostGiftCountdownModule } from 'src/app/features/post/ui/post-gift-countdown'
import { FollowButtonModule } from 'src/app/ui/follow-button'
import { ReportDialogModule } from 'src/app/ui/report-dialog'
import { CloseDialogDirectiveModule } from 'src/app/util/directives/close-dialog'
import {
  NumberToTextPipeModule,
  StatusDatePipeModule,
  TimeagoModule,
  TruncatePipeModule,
  TuiMapperPipeModule
} from 'src/app/util/pipes'
import { CommentsComponent } from './comments.component'

const nzModules = [
  NzDropDownModule,
  NzModalModule,
  NzInputModule,
  NzFormModule,
  NzSwitchModule,
  NzCommentModule,
  NzAvatarModule,
  NzListModule,
  NzButtonModule,
  NzIconModule,
  NzSkeletonModule,
  NzSpaceModule,
  NzToolTipModule
]
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    PickerModule,
    TimeagoModule,
    RouterModule,
    TuiMapperPipeModule,
    StatusDatePipeModule,
    TruncatePipeModule,
    ClipboardModule,
    ReportDialogModule,
    LikedUsersDialogModule,
    FollowButtonModule,
    NumberToTextPipeModule,
    PostActionsButtonModule,
    PostGiftCountdownModule,
    CloseDialogDirectiveModule,
    nzModules
  ],
  declarations: [CommentsComponent],
  exports: [CommentsComponent]
})
export class CommentsModule {}
