import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { UserModel } from 'src/app/data-access/generated/iam'

@Component({
  selector: 'app-suggest-item',
  templateUrl: './suggest-item.component.html',
  styleUrls: ['./suggest-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestItemComponent {
  @Input() relation: UserModel
}
