<nz-avatar
  [nzSize]="40"
  class="avatar-white flex-shrink-0"
  [nzSrc]="notification.actor.avatar || 'assets/images/logo.svg'"
></nz-avatar>
<div class="ml-2 flex-grow flex flex-col justify-center">
  <article class="line-clamp-2 leading-4 mb-1">
    <span class="font-semibold">{{ notification.actor.name }}</span>
    {{ notification.content | truncate: 60 }}
  </article>
  <small class="text-xs text-gray-400">{{ notification.createdAt | timeago | async }}</small>
</div>
<span class="text-tryme-tertiary text-xs mt-2 ml-1 w-4 flex-shrink-0">
  <ng-container *ngIf="!notification.readAt">&#x25cf;</ng-container>
</span>
