import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ForgotPasswordComponent } from './forgot-password.component'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { NzIconModule } from 'ng-zorro-antd/icon'

const nzModules = [NzFormModule, NzInputModule, NzButtonModule, NzIconModule]

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, nzModules],
  exports: [ForgotPasswordComponent]
})
export class ForgotPasswordModule {}
