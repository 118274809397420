/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserModel { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    readonly code: string;
    email: string;
    phone: string;
    username: string;
    name: string;
    gender?: string;
    address?: string;
    updatedBy?: string;
    readonly source: string;
    avatar: string;
    birthday: number;
    status: number;
    level: string;
    bio: string;
    score: number;
    follower: number;
    following: number;
    readonly joined: number;
    relation: UserModel.RelationEnum;
    qr: string;
    verified: boolean;
    gift: number;
}
export namespace UserModel {
    export type RelationEnum = 'following' | 'follower' | 'friend' | 'none' | 'blocked';
    export const RelationEnum = {
        Following: 'following' as RelationEnum,
        Follower: 'follower' as RelationEnum,
        Friend: 'friend' as RelationEnum,
        None: 'none' as RelationEnum,
        Blocked: 'blocked' as RelationEnum
    };
}


