import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { HeaderModule } from '../header/header.module'
import { LayoutComponent } from './layout.component'

const nzModules = [NzLayoutModule, NzSpinModule]

@NgModule({
  declarations: [LayoutComponent],
  imports: [CommonModule, RouterModule, HeaderModule, nzModules],
  exports: [LayoutComponent]
})
export class LayoutModule {}
