import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { io, Socket } from 'socket.io-client'
import { environment } from 'src/environments/environment'
import { AuthState } from '../../auth/store'
import {
  ReceiveNewMessage,
  UpdateBlockConversation,
  UpdateLikeMessage,
  UpdateReadMessage
} from './store'
import {
  BlockConversationPayload,
  LikeMessagePayload,
  MessengerSocketAction,
  NewMessagePayload,
  ReadConversationPayload,
  UnblockConversationPayload
} from './store/socket.model'

@Injectable({ providedIn: 'root' })
export class MessageSocketService {
  private socket!: Socket

  constructor(private store: Store) {}

  connection() {
    const token = this.store.selectSnapshot(AuthState.token)
    this.socket = io(environment.websocket, {
      transports: ['websocket'],
      timeout: 2000,
      withCredentials: true,
      query: { token }
    })

    this.socket.on(MessengerSocketAction.NewMessage, (message: NewMessagePayload) =>
      this.store.dispatch(new ReceiveNewMessage(message))
    )

    this.socket.on(MessengerSocketAction.LikeMessage, (message: LikeMessagePayload) => {
      this.store.dispatch(new UpdateLikeMessage(message.messageId, 'like'))
    })

    this.socket.on(MessengerSocketAction.UnLikeMessage, (message: LikeMessagePayload) => {
      this.store.dispatch(new UpdateLikeMessage(message.messageId, 'unlike'))
    })

    this.socket.on(MessengerSocketAction.ReadMessage, (message: ReadConversationPayload) => {
      this.store.dispatch(new UpdateReadMessage(message.conversationId))
    })

    this.socket.on(MessengerSocketAction.BlockConversation, (message: BlockConversationPayload) => {
      this.store.dispatch(
        new UpdateBlockConversation({
          conversationId: message.conversationId,
          userId: message.userId,
          type: 'block'
        })
      )
    })

    this.socket.on(
      MessengerSocketAction.UnblockConversation,
      (message: UnblockConversationPayload) => {
        this.store.dispatch(
          new UpdateBlockConversation({
            conversationId: message.conversationId,
            userId: message.userId,
            type: 'unblock'
          })
        )
      }
    )
  }

  disconnect() {
    this.socket?.disconnect()
  }
}
