import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FollowButtonComponent } from './follow-button.component'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { TranslateModule } from '@ngx-translate/core'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NgLetDirectiveModule } from 'src/app/util/directives/ng-let/ng-let.module'
import { RouterModule } from '@angular/router'

const nzModules = [NzButtonModule, NzSpinModule, NzToolTipModule]

@NgModule({
  declarations: [FollowButtonComponent],
  imports: [CommonModule, RouterModule, TranslateModule, NgLetDirectiveModule, nzModules],
  exports: [FollowButtonComponent]
})
export class FollowButtonModule {}
