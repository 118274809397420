import { PostUserResponse } from 'src/app/data-access/generated/challenge'
import { FollowActionResponse } from 'src/app/data-access/generated/relation'

const followLabelCheck: PostUserResponse.RelationEnum[] = ['following', 'friend']
export function isFollow(relation: PostUserResponse.RelationEnum) {
  return followLabelCheck.includes(relation)
}

export interface FollowButtonModel {
  friendId: string
  relation: FollowActionResponse.RelationEnum
}
