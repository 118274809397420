import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { RxState } from '@rx-angular/state'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NEVER, Subject } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import { ProfileService } from 'src/app/data-access/generated/iam'
import { LoadingState } from 'src/app/data-access/missing.model'
import { throwStatusCodeIfError } from 'src/app/util/custom-rxjs'
import { MatchValidator } from '../../util'
import {
  AuthDialogResult,
  AuthDialogType,
  NzModalContentType
} from '../../util/auth-dialog/auth-dialog.types'
import { passwordValidators } from '../../util/validators/password.validators'

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ResetPasswordComponent implements OnInit {
  form = this.fb.group({
    password: [null, passwordValidators],
    repassword: [null, [Validators.required, MatchValidator('password')]]
  })
  passwordVisible = false
  repasswordVisible = false

  loading$ = this.state.select('loading')

  private submitAction = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private state: RxState<LoadingState>,
    private nzMessageService: NzMessageService,
    private profileService: ProfileService,
    private modalRef: NzModalRef<NzModalContentType, AuthDialogResult>
  ) {}

  ngOnInit() {
    this.submitEffect()
  }

  onClickReset() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
    if (this.form.valid) {
      this.submitAction.next()
    }
  }

  private submitEffect() {
    const submitEffect$ = this.submitAction.pipe(
      switchMap(() => {
        this.state.set({ loading: true })
        const { password, repassword } = this.form.value
        return this.profileService
          .profileControllerUpdatePassword({
            updatePasswordDTO: { password, repassword }
          })
          .pipe(
            throwStatusCodeIfError(),
            tap(() => {
              this.state.set({ loading: false })
              this.modalRef.close({ type: AuthDialogType.SUCCESS_DIALOG })
            }),
            catchError(({ message }) => {
              this.state.set({ loading: false })
              this.nzMessageService.error(message)
              return NEVER
            })
          )
      })
    )
    this.state.hold(submitEffect$)
  }
}
