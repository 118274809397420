/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateProfileDTO { 
    name?: string;
    avatar?: string;
    gender?: UpdateProfileDTO.GenderEnum;
    birthday?: number;
    address?: string;
    username?: string;
    bio?: string;
}
export namespace UpdateProfileDTO {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}


