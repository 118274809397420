import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationItemComponent } from './notification-item.component'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { TruncatePipeModule } from '../../../../util/pipes/truncate/truncate-pipe.module'
import { TimeagoModule } from 'src/app/util/pipes'

const nzModules = [NzAvatarModule]

@NgModule({
  declarations: [NotificationItemComponent],
  imports: [CommonModule, TruncatePipeModule, TimeagoModule, nzModules],
  exports: [NotificationItemComponent]
})
export class NotificationItemModule {}
