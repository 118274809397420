import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationsComponent } from './notifications.component'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { TranslateModule } from '@ngx-translate/core'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NotificationItemModule } from '../notification-item/notification-item.module'
import { MoreActionsButtonModule } from 'src/app/ui/more-actions-button'

const nzModules = [
  NzGridModule,
  NzDropDownModule,
  NzSkeletonModule,
  NzAvatarModule,
  NzMenuModule,
  NzButtonModule,
  NzIconModule
]

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    InfiniteScrollModule,
    NotificationItemModule,
    MoreActionsButtonModule,
    nzModules
  ],
  exports: [NotificationsComponent]
})
export class NotificationsModule {}
