import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ChallengeService } from './api/challenge.service';
import { CommentService } from './api/comment.service';
import { MatchService } from './api/match.service';
import { PostService } from './api/post.service';
import { ProfilePostService } from './api/profile-post.service';
import { RewardService } from './api/reward.service';
import { SoundService } from './api/sound.service';
import { SoundTopicService } from './api/sound-topic.service';
import { TopicService } from './api/topic.service';
import { ViewService } from './api/view.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ChallengeApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ChallengeApiModule> {
        return {
            ngModule: ChallengeApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ChallengeApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ChallengeApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
