<h1 class="leading-none mb-2">
  <span class="text-tryme-primary mr-1">TRY</span> <span class="text-tryme-secondary">ME</span>
</h1>
<h1 class="leading-none mb-4">Chào mừng bạn!</h1>
<p class="text-gray-500 text-base leading-6 w-11/12 mx-auto mb-10">
  {{ 'login.descriptionLogin' | translate }}
</p>
<ng-container *ngLet="loading$ | async as loading">
  <button
    nz-button
    nzSize="large"
    [disabled]="loading"
    nzType="primary"
    (click)="onClickLoginEmail()"
  >
    <strong>{{ 'login.loginEmail' | translate }}</strong>
  </button>
  <button nz-button nzSize="large" [disabled]="loading" (click)="onClickFB()" class="btn-facebook">
    <i nz-icon nzType="icons/login:facebook"></i>
    <strong>{{ 'login.loginFacebook' | translate }}</strong>
  </button>
  <button
    nz-button
    nzSize="large"
    [disabled]="loading"
    (click)="onClickGoogle()"
    class="btn-google"
  >
    <i nz-icon nzType="icons/login:google"></i>
    <strong>{{ 'login.loginGoogle' | translate }}</strong>
  </button>
  <button nz-button nzSize="large" [disabled]="loading" class="btn-apple !mb-10">
    <i nz-icon nzType="icons/login:apple"></i>
    <strong>{{ 'login.loginApple' | translate }}</strong>
  </button>
</ng-container>
<span class="text-gray-500 mr-1">{{ 'login.txtNotAccount' | translate }}</span>
<a (click)="onClickRegister()" class="text-tryme-primary font-semibold">{{
  'login.txtRegister' | translate
}}</a>
<br />
<span class="text-gray-500 mr-1">{{ 'or' | translate }}</span>
<a (click)="onClickForgotPassword()" class="text-tryme-primary font-semibold">{{'forgot-password' | translate}}</a>
