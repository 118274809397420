import { ModalOptions } from 'ng-zorro-antd/modal'
import { NzNotificationDataOptions } from 'ng-zorro-antd/notification'

export const TRYME_MODAL_CONFIG: ModalOptions = {
  nzCloseIcon: 'icons/outline:close-circle',
  nzFooter: null
}

export const TRYME_NOTIFICATION_CONFIG: NzNotificationDataOptions = {
  nzCloseIcon: 'icons/outline:close-circle'
}
