import { ModuleWithProviders, NgModule } from '@angular/core'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { ForgotPasswordModule } from '../../feature/forgot-password/forgot-password.module'
import { LoginEmailModule } from '../../feature/login-email/login-email.module'
import { LoginModule } from '../../feature/login/login.module'
import { OtpVerificationModule } from '../../feature/otp-verification/otp-verification.module'
import { RegisterModule } from '../../feature/register/register.module'
import { ResetPasswordModule } from '../../feature/reset-password/reset-password.module'
import { SuccessDialogModule } from '../../feature/success-dialog/success-dialog.module'
import { AuthDialogService } from './auth-dialog.service'

const authModules = [
  LoginModule,
  LoginEmailModule,
  RegisterModule,
  ForgotPasswordModule,
  ResetPasswordModule,
  OtpVerificationModule,
  SuccessDialogModule
]

@NgModule({
  imports: [NzModalModule, authModules]
})
export class AuthDialogModule {
  static forRoot(): ModuleWithProviders<AuthDialogModule> {
    return {
      ngModule: AuthDialogModule,
      providers: [AuthDialogService]
    }
  }
}
