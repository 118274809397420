import produce from 'immer';
import { ActionPostResponse, PostService } from 'src/app/data-access/generated/challenge';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { MyResponseModel } from 'src/app/data-access/missing.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PostItemState } from '../post-item/post-item-state.model';
import { RxState } from '@rx-angular/state';
import { ShareDialogComponent } from 'src/app/ui/share-dialog';
import { ShareService } from 'src/app/data-access/generated/share';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root';

@Injectable()
export class QuizComponentStore extends RxState<PostItemState> {
  private shareAction = new Subject<void>()

  constructor(
    private postService: PostService,
    private shareService: ShareService,
    private nzModalService: NzModalService,
  ) {
    super();
    this.shareEffect();
  }

  private shareEffect() {
    const shareEffect$ = this.shareAction.pipe(
      switchMap(() =>
        this.shareService.shareControllerAdd({
          shareDTO: { id: this.get('post')._id, type: 'post' }
        })
      ),
      switchMap(response => {
        const code = response.data as unknown as string
        const modalRef = this.nzModalService.create({
          nzContent: ShareDialogComponent,
          nzComponentParams: {
            title: 'Mời bạn bè cùng tham gia',
            link: `${environment.share2}/${code}`
          },
          ...TRYME_MODAL_CONFIG
        })
        const component = modalRef.getContentComponent() as ShareDialogComponent
        return component.copy.pipe(
          switchMap(() => this.postService.postControllerShare({ id: this.get('post')._id })),
          this.patchPostFromAction(),
          takeUntil(modalRef.afterClose)
        )
      })
    )
    this.hold(shareEffect$)
  }

  private patchPostFromAction() {
    return tap((response: MyResponseModel<ActionPostResponse>) =>
      this.set(preState => ({
        post: produce(preState.post, post => ({ ...post, ...response.data }))
      }))
    )
  }

  share() {
    this.shareAction.next()
  }
}
