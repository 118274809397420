import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { FollowButtonModel } from 'src/app/ui/follow-button/util'
import { LikedUsersDialogComponentStore } from './liked-users-dialog.store'

@Component({
  templateUrl: './liked-users-dialog.component.html',
  styleUrls: ['./liked-users-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LikedUsersDialogComponentStore]
})
export class LikedUsersDialogComponent {
  @Input() title = 'Lượt thích'
  @Input() includeTotal = true
  @Input() set postId(value: string) {
    this.componentStore.set({ postId: value })
  }

  likes$ = this.componentStore.select('likes')
  loadingMore$ = this.componentStore.select('loadingMore')

  constructor(private componentStore: LikedUsersDialogComponentStore) {}

  onInfiniteScroll(page: number) {
    this.componentStore.loadMoreLikes(page)
  }

  onClickFollow(followButtonModel: FollowButtonModel) {
    this.componentStore.followUser(followButtonModel)
  }
}
