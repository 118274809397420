import { AuthState } from 'src/app/features/auth/store';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FollowActionResponse, RelationService } from 'src/app/data-access/generated/relation';
import { FollowButtonModel, isFollow } from './util';
import { LoadingState, MyResponseModel } from 'src/app/data-access/missing.model';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { RxState } from '@rx-angular/state';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';




interface FollowButtonState extends LoadingState {
  relation: FollowActionResponse.RelationEnum
}

@Component({
  selector: 'app-follow-button',
  templateUrl: './follow-button.component.html',
  styleUrls: ['./follow-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class FollowButtonComponent implements OnInit, OnChanges, FollowButtonModel {
  @Input() friendId = null
  @Input() relation: FollowActionResponse.RelationEnum = FollowActionResponse.RelationEnum.Empty;
  @Input() stopPropagation = true
  @Input() size: NzSizeLDSType = 'default'

  @Output() clickFollow = new EventEmitter<FollowButtonModel>()

  me$ = this.store.select(AuthState.profile)
  loading$ = this.state.select('loading')
  relation$ = this.state.select('relation')

  private followAction = new Subject<void>()

  constructor(
    private relationService: RelationService,
    private store: Store,
    private state: RxState<FollowButtonState>
  ) {}

  ngOnInit(): void {
    const followEffect$ = this.followAction.pipe(
      switchMap(() => {
        const relation = this.state.get('relation')
        this.state.set({ loading: true })
        if (isFollow(relation))
          return this.relationService.relationControllerUnFollow({
            followDTO: { friendId: this.friendId }
          })
        return this.relationService.relationControllerFollow({
          followDTO: { friendId: this.friendId }
        })
      }),
      tap((response: MyResponseModel<FollowActionResponse>) => {
        this.state.set({ loading: false, relation: response.data.relation })
        this.clickFollow.emit({ friendId: this.friendId, relation: response.data.relation })
      })
    )
    this.state.hold(followEffect$)
  }

  ngOnChanges(changes: SimpleChanges & { relation: SimpleChange }) {
    this.state.set({
      relation: (changes.relation?.currentValue ?? 'none') as FollowActionResponse.RelationEnum
    })
  }

  onClickFollow(event: Event): void {
    if (this.stopPropagation) event.stopPropagation()
    this.followAction.next()
  }
}
