import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './header.component'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { AppSearchModule } from '../app-search/app-search.module'
import { NotificationsModule } from '../notifications/notifications.module'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NgxsModule } from '@ngxs/store'
import { CoreState } from '../../data-access/store/state'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { AuthDialogModule } from 'src/app/features/auth/util/auth-dialog'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { MessengerDataAccessModule } from 'src/app/features/messages/data-access'
import { NgLetDirectiveModule } from 'src/app/util/directives/ng-let'

const nzModules = [
  NzLayoutModule,
  NzBadgeModule,
  NzAvatarModule,
  NzDropDownModule,
  NzIconModule,
  NzMenuModule,
  NzButtonModule,
  NzToolTipModule
]

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    AppSearchModule,
    NgLetDirectiveModule,
    NotificationsModule,
    AuthDialogModule,
    NgxsModule.forFeature([CoreState]),
    MessengerDataAccessModule,
    nzModules
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}
