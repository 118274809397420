/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserVerificationModel { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    userId: string;
    certificates: Array<string>;
    status: UserVerificationModel.StatusEnum;
    countryCode: string;
    verifierId: string;
    requestedAt: number;
    certificateType: UserVerificationModel.CertificateTypeEnum;
    verifiedAt: number;
}
export namespace UserVerificationModel {
    export type StatusEnum = 'SUCCESS' | 'REJECT' | 'PROCESSING' | 'REPROCESS';
    export const StatusEnum = {
        SUCCESS: 'SUCCESS' as StatusEnum,
        REJECT: 'REJECT' as StatusEnum,
        PROCESSING: 'PROCESSING' as StatusEnum,
        REPROCESS: 'REPROCESS' as StatusEnum
    };
    export type CertificateTypeEnum = 'PASSPORT' | 'IDENTITY_CARD' | 'CITIZEN_IDENTIFICATION';
    export const CertificateTypeEnum = {
        PASSPORT: 'PASSPORT' as CertificateTypeEnum,
        IDENTITYCARD: 'IDENTITY_CARD' as CertificateTypeEnum,
        CITIZENIDENTIFICATION: 'CITIZEN_IDENTIFICATION' as CertificateTypeEnum
    };
}


