export const environment = {
  production: true,
  userUrl: 'https://challenge.tryme.vn/user/api',
  fcm: 'https://activity.tryme.vn/user/api',
  uploadUrl: 'https://storage.tryme.vn',
  iam: 'https://iam.tryme.vn/user/api',
  marketing: 'https://marketing.tryme.vn/user/api',
  relation: 'https://relation.tryme.vn/user/api',
  storageCode: 'tryme2020',
  websocket: 'https://websocket.tryme.vn',
  messenger: 'https://messenger.tryme.vn/user/api',
  trending: 'https://trending.tryme.vn/user/api',
  google: {
    clientID: '68820029180-26197gcu3ulrkklat1lc4puu6dmel8k8.apps.googleusercontent.com'
  },
  facebook: {
    clientID: '679735032714118'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyD-AlE1hqojbezziPeYg0vc-35ybUIkTno',
    authDomain: 'challenge-1606443078170.firebaseapp.com',
    projectId: 'challenge-1606443078170',
    storageBucket: 'challenge-1606443078170.appspot.com',
    messagingSenderId: '68820029180',
    appId: '1:68820029180:web:2d350bcb4524266b0c1179',
    measurementId: 'G-2W7246QF8L'
  },
  activity2: 'https://activity.tryme.vn',
  challenge2: 'https://challenge.tryme.vn',
  iam2: 'https://iam.tryme.vn',
  relation2: 'https://relation.tryme.vn',
  messenger2: 'https://messenger.tryme.vn',
  trending2: 'https://trending.tryme.vn',
  marketing2: 'https://marketing.tryme.vn',
  share2: 'https://share.tryme.vn',
  writeKey: 'bAGaFWYsw0MzbihdAEszTEq68JuO87C9'
}
