<media-player
  *ngIf="sound; else noSoundTemplate"
  [muted]="paused || muted"
  [showPausedIcon]="false"
  controlsClass="h-12"
  (clickMute)="onClickMute($event)"
>
  <img [src]="src" [alt]="alt" appThrottleClick (throttleClick)="clickOpenMedia.emit($event)" class="cursor-pointer" />
  <p media-info class="mb-0 line-clamp-1">
    <i nz-icon nzType="icons/outline:playlist"></i>
    <span class="font-medium">{{ sound.name }}</span>
  </p>
</media-player>
<ng-template #noSoundTemplate>
  <img [src]="src" [alt]="alt" appThrottleClick (throttleClick)="clickOpenMedia.emit($event)" />
</ng-template>
