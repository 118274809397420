import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core'
import { Store } from '@ngxs/store'
import { AnswerResponse } from 'src/app/data-access/generated/challenge'
import { AuthState } from 'src/app/features/auth/store'

@Component({
  selector: 'app-quiz-statistic',
  templateUrl: './quiz-statistic.component.html',
  styleUrls: ['./quiz-statistic.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizStatisticComponent {
  @Input() answers: Array<AnswerResponse>
  @Input() total = 1
  @Input() onlyMyAnswer = false

  @HostBinding('class.sm')
  @Input()
  isSmall = false

  me$ = this.store.select(AuthState.profile)

  onlyMyAnswerFilter = (answer: AnswerResponse) => (this.onlyMyAnswer ? answer.selected : true)

  constructor(private store: Store) {}
}
