import { ResponseModel } from './../../data-access/generated/iam';
import { tap } from 'rxjs/operators';

export function precheckResponse() {
  return tap((response: ResponseModel) => {
    if (!response.data) throw new Error(response.message)
  })
}

export function throwStatusCodeIfError() {
  return tap((response: ResponseModel) => {
    if (!response.data) throw new Error(response.message)
  })
}
