/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ShareDTO { 
    type: ShareDTO.TypeEnum;
    id: string;
}
export namespace ShareDTO {
    export type TypeEnum = 'challenge' | 'post' | 'match' | 'user';
    export const TypeEnum = {
        Challenge: 'challenge' as TypeEnum,
        Post: 'post' as TypeEnum,
        Match: 'match' as TypeEnum,
        User: 'user' as TypeEnum
    };
}


