import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { VideoPlayerComponent } from './video-player.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'

const nzModules = [NzIconModule, NzToolTipModule]

@NgModule({
  declarations: [VideoPlayerComponent],
  imports: [CommonModule, nzModules],
  exports: [VideoPlayerComponent]
})
export class VideoPlayerModule {}
