import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from '../model/models';
import { TrendingControllerPaginateNewChallengeRequestParams, TrendingControllerPaginateNewPostRequestParams, TrendingControllerPaginateRequestParams, TrendingControllerSearchByChallengeRequestParams, TrendingControllerSearchByPostRequestParams, TrendingControllerSearchByUserRequestParams, TrendingControllerSearchRequestParams, TrendingControllerSuggestRequestParams, TrendingServiceInterface } from './trending.serviceInterface';
/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */






@Injectable({
  providedIn: 'root'
})
export class TrendingService implements TrendingServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerPaginate(requestParameters: TrendingControllerPaginateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerPaginate(requestParameters: TrendingControllerPaginateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerPaginate(requestParameters: TrendingControllerPaginateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerPaginate(requestParameters: TrendingControllerPaginateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling trendingControllerPaginate.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling trendingControllerPaginate.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerPaginateNewChallenge(requestParameters: TrendingControllerPaginateNewChallengeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerPaginateNewChallenge(requestParameters: TrendingControllerPaginateNewChallengeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerPaginateNewChallenge(requestParameters: TrendingControllerPaginateNewChallengeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerPaginateNewChallenge(requestParameters: TrendingControllerPaginateNewChallengeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling trendingControllerPaginateNewChallenge.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling trendingControllerPaginateNewChallenge.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/new-challenge`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerPaginateNewPost(requestParameters: TrendingControllerPaginateNewPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerPaginateNewPost(requestParameters: TrendingControllerPaginateNewPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerPaginateNewPost(requestParameters: TrendingControllerPaginateNewPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerPaginateNewPost(requestParameters: TrendingControllerPaginateNewPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling trendingControllerPaginateNewPost.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling trendingControllerPaginateNewPost.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/new-post`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerSearch(requestParameters: TrendingControllerSearchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerSearch(requestParameters: TrendingControllerSearchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerSearch(requestParameters: TrendingControllerSearchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerSearch(requestParameters: TrendingControllerSearchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const fullTextSearch = requestParameters.fullTextSearch;
        if (fullTextSearch === null || fullTextSearch === undefined) {
            throw new Error('Required parameter fullTextSearch was null or undefined when calling trendingControllerSearch.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/search`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerSearchByChallenge(requestParameters: TrendingControllerSearchByChallengeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerSearchByChallenge(requestParameters: TrendingControllerSearchByChallengeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerSearchByChallenge(requestParameters: TrendingControllerSearchByChallengeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerSearchByChallenge(requestParameters: TrendingControllerSearchByChallengeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling trendingControllerSearchByChallenge.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling trendingControllerSearchByChallenge.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;
        const hasPost = requestParameters.hasPost;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }
        if (hasPost !== undefined && hasPost !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hasPost, 'hasPost');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/search/challenge`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerSearchByPost(requestParameters: TrendingControllerSearchByPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerSearchByPost(requestParameters: TrendingControllerSearchByPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerSearchByPost(requestParameters: TrendingControllerSearchByPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerSearchByPost(requestParameters: TrendingControllerSearchByPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling trendingControllerSearchByPost.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling trendingControllerSearchByPost.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/search/post`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerSearchByUser(requestParameters: TrendingControllerSearchByUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerSearchByUser(requestParameters: TrendingControllerSearchByUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerSearchByUser(requestParameters: TrendingControllerSearchByUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerSearchByUser(requestParameters: TrendingControllerSearchByUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling trendingControllerSearchByUser.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling trendingControllerSearchByUser.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/search/user`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trendingControllerSuggest(requestParameters: TrendingControllerSuggestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public trendingControllerSuggest(requestParameters: TrendingControllerSuggestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public trendingControllerSuggest(requestParameters: TrendingControllerSuggestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public trendingControllerSuggest(requestParameters: TrendingControllerSuggestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const fullTextSearch = requestParameters.fullTextSearch;
        if (fullTextSearch === null || fullTextSearch === undefined) {
            throw new Error('Required parameter fullTextSearch was null or undefined when calling trendingControllerSuggest.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const type = requestParameters.type;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/trending/suggestion`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
