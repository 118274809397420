<ng-container *ngIf="relation; else loadingTemplate">
  <nz-avatar
    routerLink="/h/{{ relation.username }}"
    [nzSrc]="relation.avatar || 'assets/images/logo.svg'"
    class="avatar flex-shrink-0 cursor-pointer"
    closeDialog="all"
  >
  </nz-avatar>
  <section class="w-3/4 px-2 flex-grow flex flex-col justify-center">
    <a
      routerLink="/h/{{ relation.username }}"
      class="w-full font-semibold leading-5 text-gray-800 line-clamp-1 break-words"
      closeDialog="all"
      >@{{ relation.username }}
      <i
        nz-icon
        nzType="icons/fill:verified"
        *ngIf="relation.verified"
        class="!text-base align-icon"
      ></i
    ></a>
    <small class="w-full text-gray-600 leading-4 line-clamp-1 break-words mb-0.5">{{ relation.name }}</small>
    <strong class="statistics">
      <span>{{ relation.follower | numberToText }} Followers</span>
      <span class="text-[3px] leading-5 mx-1">&#x25cf;</span>
      <span>{{ relation.score | numberToText }} Scores</span>
    </strong>
  </section>
</ng-container>

<ng-template #loadingTemplate>
  <nz-skeleton [nzAvatar]="true" [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 3 }">
  </nz-skeleton>
</ng-template>
