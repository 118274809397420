<ng-container *ngFor="let questionItem of questions">
  <article
    [ngStyle]="{
      background: color | tuiMapper: toBackgroundStyle,
      color: color?.text
    }"
    class="text-gray-700"
  >
    <strong
      class="text-lg text-white bg-gray-900 bg-opacity-40 px-4 py-2 mb-4 rounded-full space-x-2"
    >
      <i nz-icon nzType="icons/fill:help" class="!text-white"></i>
      <span class="vertical-align-span">{{'challengingQuestion' | translate}}: {{questionItem.type | translate}}</span>
    </strong>
    <div class="pre">{{ questionItem.question }}</div>

    <div *ngIf="post?.avatarUrl" class="mb-8 rounded-xl overflow-hidden image-wrapper">
      <img
        [src]="post?.avatarUrl"
        class="max-w-full max-h-full w-full h-full object-contain rounded-xl"
      />
    </div>

    <ng-container *ngIf="post?.isJoined">
      <div class="px-4 py-2 mb-2 space-x-2 bg-white rounded-xl" *ngIf="questionItem.correct">
        <i nz-icon nzType="icons/fill:success-circle" class="align-icon !text-tryme-green mr-1"></i>
        <strong class="text-tryme-green">{{'yourAnswerCorrect' | translate}}</strong>
        <span>|</span>
        <a
          class="text-tryme-primary underline"
          (click)="onViewStatistic()"
        >
          {{'viewStatistic' | translate}}
        </a>
      </div>

      <div class="px-4 py-2 mb-2 space-x-2 bg-white rounded-xl" *ngIf="!questionItem.correct">
        <i nz-icon nzType="icons/fill:close-circle" class="align-icon !text-tryme-primary mr-1"></i>
        <strong class="text-tryme-primary">{{'yourAnswerIncorrect' | translate}}</strong>
        <span>|</span>
        <a class="text-tryme-green underline" (click)="onViewStatistic()">
          {{'viewStatistic' | translate}}
        </a>
      </div>

      <app-quiz-statistic
        [answers]="questionItem.answers"
        [total]="post?.joined"
      ></app-quiz-statistic>
    </ng-container>

    <ng-container *ngIf="!post?.isJoined">
      <ul class="answers" *ngIf="questionItem.type === questionTypeEnum.Choice">
        <li
          *ngFor="let answerItem of questionItem.answers"
          class="text-gray-700 border-gray-700"
          [ngStyle]="{ color: color?.text, borderColor: color?.text }"
          [class.border-2]="selectedAnswerId === answerItem._id"
          [class.small]="answerItem.answer?.length > 34"
          (click)="selectedAnswerId = answerItem._id"
        >
          <i
            [hidden]="selectedAnswerId !== answerItem._id"
            nz-icon
            nzType="icons/fill:success-circle"
            class="mr-1"
          ></i>
          <strong>{{ answerItem.answer }}</strong>
        </li>
      </ul>

      <input
        nz-input
        [formControl]="answerControl"
        nzSize="large"
        placeholder="{{'enterAnswer' | translate}}"
        maxlength="50"
        class="max-w-md !bg-white !bg-opacity-40 max-w-md !placeholder-gray-100"
        [ngClass]="{
          '!mb-16': !answerControl.value,
          '!mb-10': answerControl.value
        }"
        [ngStyle]="{color: color?.text || 'white'}"
        *ngIf="questionItem.type !== questionTypeEnum.Choice"
      />
    </ng-container>

    <app-quiz-joined-count
      [hidden]="selectedAnswerId || answerControl.value || post?.isJoined"
      [avatars]="post?.avatars"
      [count]="post?.joined"
      class="mb-8"
    ></app-quiz-joined-count>

    <div *ngIf="post.challenge?.giftConfig?.type === 'luckyNumber' && !post.isJoined" class="w-full">
      <h4 class="text-2xl mb-1" [style.color]="color?.text">
        {{'chooseLuckyNumberToJoinReward' | translate}}
      </h4>
      <p class="text-base">{{ 'fromTo' | translate: {from: post.challenge.giftConfig.startNumber, to:
        post.challenge.giftConfig.endNumber} }}</p>
      <div class="flex justify-center space-x-2 mb-12">
        <input nz-input nzSize="large" [formControl]="randomNumberControl" class="
                                !bg-black !bg-opacity-20
                                !placeholder-gray-300
                                !text-white
                                text-3xl
                                !w-56
                                font-semibold
              " [placeholder]="'Nhập số may mắn'" />
        <button nz-button nzType="primary" nzSize="large" (click)="onRandom()" class="border !border-white">
          <i nz-icon nzType="icons/outline:dice" class="dice"></i>
        </button>
      </div>
    </div>

    <button
      *ngIf="!isJoined && (selectedAnswerId || answerControl.value)"
      nz-button
      nzType="button"
      nzSize="large"
      class="w-1/4 mb-2"
      (click)="onClickSend(questionItem)"
      [nzLoading]="loading$ | async"
    >
      {{'confirm' | translate}}
    </button>

    <button [hidden]="!post?.isJoined" nz-button nzType="button" nzSize="large" class="w-65" (click)="onShare.emit()">
      <i
        nz-icon
        nzType="icons/fill:group"
        class="!text-tryme-primary"
        style="vertical-align: 0"
      ></i>
      Mời bạn bè cùng tham gia
    </button>
  </article>
</ng-container>
