import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { OtpVerificationComponent } from './otp-verification.component'
import { TuiMapperPipeModule } from 'src/app/util/pipes'
import { NgLetDirectiveModule } from 'src/app/util/directives/ng-let/ng-let.module'

const nzModules = [NzFormModule, NzInputModule, NzButtonModule]

@NgModule({
  declarations: [OtpVerificationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TuiMapperPipeModule,
    NgLetDirectiveModule,
    nzModules
  ],
  exports: [OtpVerificationComponent]
})
export class OtpVerificationModule {}
