import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { RxState } from '@rx-angular/state'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { EMPTY, Subject } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { PasswordService, RegisterResponse } from 'src/app/data-access/generated/iam'
import { LoadingState, MyResponseModel } from 'src/app/data-access/missing.model'
import { hasValue, precheckResponse } from 'src/app/util/custom-rxjs'
import {
  AuthDialogResult,
  AuthDialogType,
  NzModalContentType
} from '../../util/auth-dialog/auth-dialog.types'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ForgotPasswordComponent implements OnInit {
  loading$ = this.state.select('loading')
  form = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  })

  private submitAction = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private state: RxState<LoadingState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private nzMessageService: NzMessageService,
    private passwordService: PasswordService,
    private modalRef: NzModalRef<NzModalContentType, AuthDialogResult>
  ) {}

  ngOnInit(): void {
    const emailQueryParam$ = this.activatedRoute.queryParams.pipe(
      map(({ email }) => email),
      hasValue()
    )
    this.state.hold(emailQueryParam$, email => this.form.setValue({ email }))
    this.submitEffect()
  }

  onSubmit() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
    if (this.form.valid) {
      this.submitAction.next()
    }
  }

  private submitEffect() {
    const submitEffect$ = this.submitAction.pipe(
      tap(() => this.state.set({ loading: true })),
      switchMap(() => {
        const email = this.form.value.email
        return this.passwordService
          .passwordControllerForgotPassword({ forgotPasswordDTO: { email } })
          .pipe(
            precheckResponse(),
            tap((response: MyResponseModel<RegisterResponse>) => {
              this.router.navigate([], {
                queryParams: { requestId: response.data.requestId, email }
              })
              this.modalRef.close({ type: AuthDialogType.OTP_VERIFICATION })
            }),
            catchError(({ message }) => {
              this.state.set({ loading: false })
              this.nzMessageService.error(message)
              return EMPTY
            })
          )
      })
    )
    this.state.hold(submitEffect$)
  }
}
