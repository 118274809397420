import { Routes } from '@angular/router';

export const authRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../feature/login/login.module').then(m => m.LoginModule),
    data: { value: 'login' }
  },
  {
    path: 'register',
    loadChildren: () => import('../feature/register/register.module').then(m => m.RegisterModule),
    data: { value: 'register' }
  }
]
