/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TrendingSuggestionResponse { 
    type: TrendingSuggestionResponse.TypeEnum;
    title: string;
    _id: string;
    image: string;
}
export namespace TrendingSuggestionResponse {
    export type TypeEnum = 'User' | 'Challenge' | 'Post' | 'Hashtag' | 'Topic';
    export const TypeEnum = {
        User: 'User' as TypeEnum,
        Challenge: 'Challenge' as TypeEnum,
        Post: 'Post' as TypeEnum,
        Hashtag: 'Hashtag' as TypeEnum,
        Topic: 'Topic' as TypeEnum
    };
}


