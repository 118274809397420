import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { filter } from 'rxjs/operators'

interface SetupAutoTitleParams {
  prefix?: string
  postfix?: string
}

@Injectable({ providedIn: 'root' })
export class AutoTitleService {
  constructor(
    private title: Title,
    private router: Router,
    private translate: TranslateService) { }

  setupAutoTitleListener(params: SetupAutoTitleParams = {}) {
    this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe((event) => {
      const { data } = getDeepestChildSnapshot((event as ResolveEnd).state.root)
      const { prefix = '', postfix = '' } = params
      if (data?.title) this.title.setTitle(prefix + this.translate.instant(`TITLE.${data.title}`) + postfix)
    })
  }
}

export const getDeepestChildSnapshot: (snapshot: ActivatedRouteSnapshot) => ActivatedRouteSnapshot =
  (snapshot) => {
    let deepestChild = snapshot.firstChild
    while (deepestChild?.firstChild) {
      deepestChild = deepestChild?.firstChild
    }
    return deepestChild || snapshot
  }
