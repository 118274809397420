import { Component, ChangeDetectionStrategy } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'

@Component({
  templateUrl: './choose-create-type-dialog.component.html',
  styleUrls: ['./choose-create-type-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseCreateTypeDialogComponent {
  constructor(public nzModalRef: NzModalRef) {}
}
