import { AuthModule } from './../../../features/auth/auth.module'
import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { NgxsResetPluginModule } from 'ngxs-reset-plugin'
// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { environment } from 'src/environments/environment'
import { AuthState } from '../../auth/store/state'
import { LanguageState } from '../language/store'

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsResetPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: [AuthState, LanguageState] }),
    AuthModule,
    environment.production
      ? []
      : [
        // NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production })
      ]
  ]
})
export class StateManagementModule { }
