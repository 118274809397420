import { NzButtonModule } from 'ng-zorro-antd/button'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzFormModule } from 'ng-zorro-antd/form'
import { TranslateModule } from '@ngx-translate/core'
import { RegisterComponent } from './register.component'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { RequireSignModule } from 'src/app/ui/require-sign/require-sign.module'

const nzModules = [
  NzInputModule,
  NzFormModule,
  NzButtonModule,
  NzDatePickerModule,
  NzSelectModule,
  NzSpinModule,
  NzIconModule,
  NzToolTipModule
]

@NgModule({
  declarations: [RegisterComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, nzModules, RequireSignModule],
  exports: [RegisterComponent]
})
export class RegisterModule {}
