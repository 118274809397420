import { PostResponse } from 'src/app/data-access/generated/challenge'

export interface PostItemState {
  post: PostResponse
  editing: boolean
}

export enum PostActionType {
  CopyLink,
  Download,
  ReportPost,
  BlockUser,
  EditPost,
  RemovePost,
  HidePost
}
