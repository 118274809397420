/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FollowActionResponse { 
    relation: FollowActionResponse.RelationEnum;
}
export namespace FollowActionResponse {
    export type RelationEnum = 'following' | 'follower' | 'friend' | '' | 'blocked';
    export const RelationEnum = {
        Following: 'following' as RelationEnum,
        Follower: 'follower' as RelationEnum,
        Friend: 'friend' as RelationEnum,
        Empty: '' as RelationEnum,
        Blocked: 'blocked' as RelationEnum
    };
}


