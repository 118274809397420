import { ActionItem } from 'src/app/ui/more-actions-button';
import { ActivityModelResponse } from 'src/app/data-access/generated/activity';
import { AuthState, Logout } from 'src/app/features/auth/store';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CoreState } from '../../data-access/store/state';
import { delay, distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';
import { Empty } from 'src/app/data-access/missing.model';
import { LoadMoreNotifications } from '../../data-access/store/actions';
import { LoadNotifications, LoadUnreadNotificationCount, MarkAsRead, MarkAsReadAll } from '../../data-access/store/actions';
import { LoadUnreadMessageCount, MessengerState } from 'src/app/features/messages/data-access';
import { NavigationEnd, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { UserActionType } from 'src/app/features/user/feature/user/user-state.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class HeaderComponent implements OnInit {
  me$ = this.store.select(AuthState.profile)
  unreadNotiCount$ = this.store.select(CoreState.unreadNotiCount)
  notifications$ = this.store.select(CoreState.notifications)
  unreadMessageCount$ = this.store.select(MessengerState.unreadMessageCount)
  currentUrl$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(e => (e as NavigationEnd).url),
    distinctUntilChanged(),
    startWith(location.pathname)
  )

  notificationsDropdownVisible = false

  // TODO: Duplicate code with header
  readonly myActions: Array<ActionItem> = [
    {
      id: UserActionType.ViewProfile,
      title: this.translate.instant('user.profile'),
      iconType: 'icons/outline:profile-circle',
      url: `/h/me`
    },
    {
      id: UserActionType.Setting,
      title: this.translate.instant('user.settings'),
      iconType: 'icons/outline:settings',
      url: `/settings`
    },
    {
      id: UserActionType.Logout,
      title: this.translate.instant('signOut'),
      iconType: 'icons/outline:stop',
      class: '!text-tryme-primary'
    }
  ]

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly state: RxState<Empty>,
    private readonly translate: TranslateService,
  ) { }

  ngOnInit() {
    this.state.hold(
      this.store.select(AuthState.isLoggedIn).pipe(
        filter(isLoggedIn => isLoggedIn),
        delay(1000)
      ),
      () => {
        this.store.dispatch([
          new LoadUnreadMessageCount(),
          new LoadUnreadNotificationCount(),
          new LoadNotifications()
        ])
      }
    )
  }

  readAll() {
    this.store.dispatch(new MarkAsReadAll())
  }

  readOne(item: ActivityModelResponse) {
    this.notificationsDropdownVisible = false
    if (!item.readAt) this.store.dispatch(new MarkAsRead({ id: item._id }))
    switch (item.type) {
      case 'LikedComment':
      case 'CommentedPost':
        this.router.navigate([`/post/${item.postId}`], {
          queryParams: { commentId: item.commentId }
        })
        break

      case 'RepliedComment':
        this.router.navigate([`/post/${item.postId}`], {
          queryParams: { commentId: item.commentId, originalCommentId: item.originalCommentId }
        })
        break

      case 'LikedPost':
      case 'NewChallengeReminder':
      case 'SharedPost':
      case 'ViewPost':
        this.router.navigate([`/post/${item.postId}`])
        break

      case 'Followed':
        this.router.navigate([`/h/${item.actor.username}`])
        break

      case 'ViewChallenge':
      case 'RemindChallengeEvent':
      case 'RewardChallengeEvent':
      case 'ExpiredChallengeEvent':
      case 'FeedbackGiftChallenge':
      case 'ChallengeApproved':
      case 'ChallengeRejected':
        this.router.navigate([`/h/challenge/${item.challengeId}`])
        break

      default:
        break
    }
  }

  onClickNotification() {
    this.store
      .dispatch(new LoadNotifications())
      .pipe(take(1))
      .subscribe(() => this.store.dispatch(new LoadUnreadNotificationCount()))
  }

  loadMoreNotification() {
    this.store.dispatch(new LoadMoreNotifications())
  }

  onClickLogin() {
    this.router.navigate([], { queryParams: { dialog: 'login' } })
  }

  onClickAction(action: ActionItem) {
    switch (action.id) {
      case UserActionType.Logout: {
        this.store.dispatch(new Logout())
        break
      }
    }
  }
}
