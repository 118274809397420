/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PrizeModel { 
    name: string;
    value: string;
    quantity: number;
    amount: number;
    currency?: string;
    url?: string;
    type: PrizeModel.TypeEnum;
}
export namespace PrizeModel {
    export type TypeEnum = 'money' | 'voucher' | 'product';
    export const TypeEnum = {
        Money: 'money' as TypeEnum,
        Voucher: 'voucher' as TypeEnum,
        Product: 'product' as TypeEnum
    };
}


