import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AuthService, Configuration as IamConfiguration, LoginSuccessResponse, ResponseModel } from 'src/app/data-access/generated/iam';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Configuration as ActivityConfiguration } from 'src/app/data-access/generated/activity';
import { Configuration as TrendingConfiguration } from 'src/app/data-access/generated/trending';
import { Configuration as ChallengeConfiguration } from 'src/app/data-access/generated/challenge';
import { Configuration as MessengerConfiguration } from 'src/app/data-access/generated/messenger';
import { Configuration as RelationConfiguration } from 'src/app/data-access/generated/relation';
import { Configuration as MarketingConfiguration } from 'src/app/data-access/generated/marketing';
import { Configuration as ShareConfiguration } from 'src/app/data-access/generated/share';
import { CONFIRM_DIALOG_MODAL_CONFIG } from 'src/app/ui/confirm-dialog/confirm-dialog.config';
import { ConfirmDialogComponent } from '../../../ui/confirm-dialog/confirm-dialog.component';
import { errorCatchThrow, precheckResponse as preCheckResponse } from 'src/app/util/custom-rxjs';
import { INITIAL_STATE, STATE_NAME, StateModel } from './state.model';
import { Injectable, NgZone } from '@angular/core';
import { LoadToken, Login, LoginApple, LoginFacebook, LoginGoogle, Logout, SaveLoginInfo, SaveRegisterParams, UpdateProfile } from './actions';
import { MyResponseModel } from '../../../data-access/missing.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SegmentTrackingService } from 'src/app/util/services/segment.service';
import { SocialAuthService } from 'angularx-social-login';

@State<StateModel>({
  name: STATE_NAME,
  defaults: INITIAL_STATE
})
@Injectable()
export class AuthState {
  @Selector()
  static token({ loginResponse }: StateModel) {
    return loginResponse.token
  }
  @Selector()
  static profile({ loginResponse }: StateModel) {
    return loginResponse.profile
  }
  @Selector()
  static isLoggedIn({ loginResponse }: StateModel) {
    return !!loginResponse.profile
  }
  @Selector()
  static errorMessage({ errorMessage }: StateModel) {
    return errorMessage
  }
  @Selector()
  static registerParams({ registerParams }: StateModel) {
    return registerParams
  }

  constructor(
    private authService: AuthService,
    private iamApiConfig: IamConfiguration,
    private activityApiConfig: ActivityConfiguration,
    private trendingApiConfig: TrendingConfiguration,
    private challengeApiConfig: ChallengeConfiguration,
    private messengerApiConfig: MessengerConfiguration,
    private relationApiConfig: RelationConfiguration,
    private marketingApiConfig: MarketingConfiguration,
    private shareConfiguration: ShareConfiguration,
    private socialAuth: SocialAuthService,
    private nzModalService: NzModalService,
    private router: Router,
    private ngZone: NgZone,
    private segmentTrackingService: SegmentTrackingService
  ) {}

  @Action(Login, { cancelUncompleted: true })
  Login({ patchState, dispatch }: StateContext<StateModel>, { params }: Login) {
    return this.authService.authControllerLogin(params).pipe(
      preCheckResponse(),
      tap((response: ResponseModel) => {
        patchState({ loginResponse: response.data as LoginSuccessResponse })
        return dispatch(new LoadToken())
      }),
      errorCatchThrow(patchState)
    )
  }

  @Action(LoginFacebook, { cancelUncompleted: true })
  LoginFacebook({ patchState, dispatch }: StateContext<StateModel>, { params }: LoginFacebook) {
    return this.authService.authControllerGetTokenAfterFacebookSignIn(params).pipe(
      tap((response: MyResponseModel<LoginSuccessResponse>) => {
        patchState({ loginResponse: response.data })
        return dispatch(new LoadToken())
      }),
      catchError(error => {
        console.warn(`[${STATE_NAME}] LoginFacebook with error: `, error)
        const errorMessage = 'Your email or password is seem to be wrong. Please try again.'
        patchState({ errorMessage })
        return throwError(errorMessage)
      })
    )
  }

  @Action(LoginGoogle, { cancelUncompleted: true })
  LoginGoogle({ patchState, dispatch }: StateContext<StateModel>, { params }: LoginGoogle) {
    return this.authService.authControllerGetTokenAfterGoogleSignIn(params).pipe(
      tap((response: MyResponseModel<LoginSuccessResponse>) => {
        patchState({ loginResponse: response.data })
        return dispatch(new LoadToken())
      }),
      catchError(error => {
        console.warn(`[${STATE_NAME}] LoginGoogle with error: `, error)
        const errorMessage = 'Your email or password is seem to be wrong. Please try again.'
        patchState({ errorMessage })
        return throwError(errorMessage)
      })
    )
  }

  @Action(LoginApple, { cancelUncompleted: true })
  LoginApple({ patchState, dispatch }: StateContext<StateModel>, { params }: LoginApple) {
    return this.authService.authControllerGetTokenAfterAppleSignIn(params).pipe(
      tap((response: MyResponseModel<LoginSuccessResponse>) => {
        patchState({ loginResponse: response.data })
        return dispatch(new LoadToken())
      }),
      catchError(error => {
        console.warn(`[${STATE_NAME}] LoginApple with error: `, error)
        const errorMessage = 'Your email or password is seem to be wrong. Please try again.'
        patchState({ errorMessage })
        return throwError(errorMessage)
      })
    )
  }

  @Action(LoadToken)
  loadToken({ getState }: StateContext<StateModel>) {
    const token = getState().loginResponse?.token
    if (!token) return
    this.iamApiConfig.accessToken = token
    this.activityApiConfig.accessToken = token
    this.trendingApiConfig.accessToken = token
    this.challengeApiConfig.accessToken = token
    this.messengerApiConfig.accessToken = token
    this.relationApiConfig.accessToken = token
    this.marketingApiConfig.accessToken = token
    this.shareConfiguration.accessToken = token
    this.segmentTrackingService.identity(getState().loginResponse.profile._id)
    this.segmentTrackingService.userId = getState().loginResponse.profile._id
  }

  @Action(Logout, { cancelUncompleted: true })
  Logout({ setState }: StateContext<StateModel>, { willConfirm }: Logout) {
    const successCallback = () =>
      this.ngZone.run(() => {
        setState(INITIAL_STATE)
        localStorage.clear()
        this.router.navigate([], {
          queryParams: { dialog: undefined },
          queryParamsHandling: 'merge'
        })
        this.authService.authControllerLogout().pipe(
          catchError(error => {
            console.warn(`[${STATE_NAME}] Log out with error: `, error)
            return of(error)
          }),
          finalize(() => {
            this.socialAuth.signOut(true).catch(console.warn)
          })
        )
      })
    if (willConfirm)
      return this.nzModalService.create({
        nzContent: ConfirmDialogComponent,
        nzComponentParams: {
          title: 'Đăng xuất',
          message: `Bạn có chắc chắn muốn đăng xuất?`
        },
        ...CONFIRM_DIALOG_MODAL_CONFIG,
        nzOkText: 'Đăng xuất',
        nzOnOk: successCallback
      })
    return successCallback()
  }

  @Action(SaveRegisterParams)
  SaveRegisterParams({ patchState }: StateContext<StateModel>, { params }: SaveRegisterParams) {
    patchState({ registerParams: params })
  }

  @Action(UpdateProfile)
  UpdateProfile({ patchState, getState }: StateContext<StateModel>, { profile }: UpdateProfile) {
    patchState({ loginResponse: { ...getState().loginResponse, profile: profile } })
  }

  @Action(SaveLoginInfo)
  SaveLoginInfo({ patchState, dispatch }: StateContext<StateModel>, { loginInfo }: SaveLoginInfo) {
    patchState({ loginResponse: loginInfo })
    return dispatch(new LoadToken())
  }
}
