import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReportDialogComponent } from './report-dialog.component'
import { TranslateModule } from '@ngx-translate/core'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzListModule } from 'ng-zorro-antd/list'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { FormsModule } from '@angular/forms'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { ReportDialogService } from './report-dialog.service'

const nzModules = [NzIconModule, NzListModule, NzRadioModule, NzButtonModule]

@NgModule({
  declarations: [ReportDialogComponent],
  imports: [CommonModule, TranslateModule, FormsModule, nzModules],
  exports: [ReportDialogComponent],
  providers: [ReportDialogService]
})
export class ReportDialogModule {}
