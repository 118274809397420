<button
  nz-button
  nzType="text"
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzDropdownMenu]="dropdown"
  [(nzVisible)]="visible"
>
  <i nz-icon nzType="icons/outline:more-vertical"></i>
</button>

<nz-dropdown-menu #dropdown="nzDropdownMenu">
  <ul nz-menu class="dropdown-menu">
    <li
      nz-menu-item
      *ngFor="let action of actions"
      (click)="onClickAction(action)"
      [ngClass]="action.class"
    >
      <i nz-icon [nzType]="action.iconType"></i>
      <span>{{ action.title }}</span>
    </li>
  </ul>
</nz-dropdown-menu>
