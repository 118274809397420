import { AuthState } from 'src/app/features/auth/store';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PostQuizStatisticStore } from './post-quiz-statistic.store';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-post-quiz-statistic',
  templateUrl: './post-quiz-statistic.component.html',
  styleUrls: ['./post-quiz-statistic.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostQuizStatisticComponent {
  @Input() postId: string;
  @Input() joined: number;

  me$ = this.store.select(AuthState.profile)

  statisticsPaging$ = this.postQuizStatisticStore.select('statistics');
  loadMore = false;

  constructor(
    private readonly store: Store,
    private readonly postQuizStatisticStore: PostQuizStatisticStore,
  ) { }

  ngOnInit() {
    this.postQuizStatisticStore.loadStatistics({ id: this.postId, page: 1, limit: 20 }, this.loadMore);
  }
}
