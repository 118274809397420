export type LanguageCode = 'en' | 'vi'

export interface LanguageStateModel {
  language: LanguageCode
  supportedLanguages: Array<LanguageCode>
}
export const LANGUAGE_STATE_NAME = 'TryMe_Language'

export const INITIAL_STATE: LanguageStateModel = {
  language: 'vi',
  supportedLanguages: ['en', 'vi']
}
