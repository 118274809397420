export enum SegmentEvent {
  ViewMoreComment = 'View more comment',
  LoadMoreTrending = 'View more trending',
  ViewMatch = 'View match',
  Searching = 'Searching',
  ViewChallenge = 'View challenge',
  ViewPost = 'View post',
  ViewUser = 'View user',
  ViewCommunity = 'View community',
  LoadMoreFollowing = 'Load more following',
  LoadMoreCommunity = 'Load more community',
  RequestLogin = 'Request Login',
  NewMessage = 'New Message',
  FollowUser = 'Follow user',
  RandomNumberQuiz = 'Random number quiz',
  ViewSuggestUser = 'View suggestion user',
  ViewFollowing = 'View following',
  ViewFollower = 'View follower',
  SharePost = 'Share post',
  ViewSuggestionUser = 'View suggestion user',
  PostAction = 'Post Action'
}

export enum PageViewEvent {
  Challenge = 'Challenge',
  Post = 'Post',
  User = 'User',
  Match = 'Match',
  Explore = 'Explore',
  Conversation = 'Conversation',
  Home = 'Home',
  Quiz = 'Quiz',
  SuggestUser = 'Suggest User'
}

export enum SegmentUserEvent {
  Click = 'Click',
  Comment = 'Comment',
  Liked = 'Liked',
  Share = 'Share',
  JoinQuiz = 'Join Quiz',
  Suggestion = 'Suggestion',
  ChallengeAction = 'Challenge Action'
}
