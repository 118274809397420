import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export interface CanShowUnsavedDialog {
  willShowUnsavedDialog: boolean
}

@Injectable()
export class UnsavedChangesGuard<T extends CanShowUnsavedDialog> implements CanDeactivate<T> {
  constructor(private modalService: NzModalService) {}

  canDeactivate(component: T): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.willShowUnsavedDialog) return true
    return this.modalService
      .confirm({
        nzContent: `You're on editting. Would you like to discard all changes?`,
        nzOkText: 'Discard Changes',
        nzOkDanger: true,
        nzCancelText: 'Keep me here'
      })
      .afterClose.pipe(map(response => response === 1))
  }
}
