import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberToTextPipeModule } from 'src/app/util/pipes';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PostQuizStatisticModule } from '../post-quiz-statistic/post-quiz-statistic.module';
import { QuizStatisticDialogComponent } from './quiz-statistic-dialog.component';

const nzModules = [NzAvatarModule, NzButtonModule]

@NgModule({
  declarations: [QuizStatisticDialogComponent],
  imports: [CommonModule, NumberToTextPipeModule, nzModules, PostQuizStatisticModule],
  exports: [QuizStatisticDialogComponent]
})
export class QuizStatisticDialogModule {}
