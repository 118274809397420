import { Injectable, Type } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Store } from '@ngxs/store'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Observable } from 'rxjs'
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root'
import { distinctUntilIdChanged, hasValue } from 'src/app/util/custom-rxjs'
import { AuthDialogResult } from '.'
import { ForgotPasswordComponent } from '../../feature/forgot-password/forgot-password.component'
import { LoginEmailComponent } from '../../feature/login-email/login-email.component'
import { LoginComponent } from '../../feature/login/login.component'
import { OtpVerificationComponent } from '../../feature/otp-verification/otp-verification.component'
import { RegisterComponent } from '../../feature/register/register.component'
import { ResetPasswordComponent } from '../../feature/reset-password/reset-password.component'
import { SuccessDialogComponent } from '../../feature/success-dialog/success-dialog.component'
import { AuthState } from '../../store'
import { AuthDialogType, NzModalContentType } from './auth-dialog.types'

const mapper: Record<AuthDialogType, Type<any>> = {
  login: LoginComponent,
  'login-email': LoginEmailComponent,
  register: RegisterComponent,
  'forgot-password': ForgotPasswordComponent,
  'reset-password': ResetPasswordComponent,
  'otp-verification': OtpVerificationComponent,
  'success-dialog': SuccessDialogComponent
}
const authDialogTypes = Object.keys(mapper) as AuthDialogType[]

@Injectable()
export class AuthDialogService {
  constructor(
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private store: Store,
    private title: Title
  ) { }

  openDialog(options: AuthDialogResult): Observable<AuthDialogResult> {
    this.title.setTitle(this.translate.instant(`TITLE.${options.type}`) + ' | TryMe')
    return this.modalService
      .create<NzModalContentType, AuthDialogResult>({
        nzContent: mapper[options.type],
        nzWidth: '28rem',
        nzCentered: true,
        nzMaskClosable:
          options.type === 'login' ||
          options.type === 'login-email' ||
          options.type === 'success-dialog',
        ...TRYME_MODAL_CONFIG
      })
      .afterClose.pipe(
        tap((result = {}) => {
          const { type, additionalParams } = result
          if (!type) {
            this.title.setTitle('TryMe')
            this.router.navigate([], { queryParams: { dialog: undefined } })
          } else {
            this.title.setTitle(this.translate.instant(`TITLE.${type}`) + ' | TryMe')
            this.router.navigate([], {
              queryParams: { dialog: type, ...additionalParams },
              queryParamsHandling: 'merge'
            })
          }
        })
      )
  }

  closeAll(): void {
    this.modalService.closeAll()
  }

  registerOpenDialogByQueryParams() {
    const me$ = this.store.select(AuthState.profile).pipe(distinctUntilIdChanged())
    this.activatedRoute.queryParams
      .pipe(
        withLatestFrom(me$),
        filter(
          ([{ dialog: dialogType }, user]) => !user || (!!user && dialogType === 'reset-password')
        ),
        map(([{ dialog: dialogType }]) => dialogType as AuthDialogType),
        hasValue(),
        filter(dialogType => authDialogTypes.includes(dialogType)),
        switchMap(type => this.openDialog({ type }))
      )
      .subscribe()
  }
}
