import { Actions, ofActionErrored, ofActionSuccessful, Store } from '@ngxs/store';
import { AuthDialogResult, AuthDialogType, NzModalContentType } from '../../util/auth-dialog';
import { AuthState, LoginFacebook, LoginGoogle, Logout } from '../../store';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { defer, EMPTY, merge, NEVER, Subject } from 'rxjs';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { LoadingState } from 'src/app/data-access/missing.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { TitleCasePipe } from '@angular/common';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState, TitleCasePipe]
})
export class LoginComponent implements OnInit, OnDestroy {
  loading$ = this.state.select('loading')
  private loadingMessageId: string

  signInAction = new Subject<'google' | 'facebook'>()

  constructor(
    private socialAuthService: SocialAuthService,
    private message: NzMessageService,
    private modalRef: NzModalRef<NzModalContentType, AuthDialogResult>,
    private store: Store,
    private actions: Actions,
    private state: RxState<LoadingState>,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
  ) {}

  ngOnInit() {
    this.registerLogin()
    this.registerGlobalLoading()
    this.signInEffect()
    this.state.hold(this.socialAuthService.authState, user => {
      if (!user) return this.store.dispatch(new Logout())
      if (user.provider === 'FACEBOOK')
        this.store.dispatch(
          new LoginFacebook({ facebookLoginDTO: { access_token: user.authToken } })
        )
      if (user.provider === 'GOOGLE')
        this.store.dispatch(new LoginGoogle({ googleLoginDTO: { id_token: user.idToken } }))
    })
  }

  ngOnDestroy() {
    if (this.loadingMessageId) this.message.remove(this.loadingMessageId)
  }

  onClickLoginEmail() {
    this.modalRef.close({ type: AuthDialogType.LOGIN_EMAIL })
  }

  onClickRegister() {
    this.modalRef.close({ type: AuthDialogType.REGISTER })
  }

  onClickGoogle() {
    this.signInAction.next('google')
  }

  onClickFB() {
    this.signInAction.next('facebook')
  }

  private registerLogin() {
    const loginFBError$ = this.actions.pipe(ofActionErrored(LoginFacebook))
    const loginGoogleError$ = this.actions.pipe(ofActionErrored(LoginGoogle))
    const whenSendFailed$ = merge(loginFBError$, loginGoogleError$).pipe(
      withLatestFrom(this.store.select(AuthState.errorMessage))
    )
    this.state.hold(whenSendFailed$, ([, errorMessage]) => {
      this.state.set({ loading: false })
      this.message.error(errorMessage)
    })

    const loginFBSuccess$ = this.actions.pipe(ofActionSuccessful(LoginFacebook))
    const loginGoogleSuccess$ = this.actions.pipe(ofActionSuccessful(LoginGoogle))
    const whenSendSuccess$ = merge(loginFBSuccess$, loginGoogleSuccess$)
    this.state.hold(whenSendSuccess$, () => {
      this.state.set({ loading: false })
      this.modalRef.close()
    })
  }

  private signInEffect() {
    const googleLoginEffect$ = this.signInAction.pipe(
      tap(() => this.state.set({ loading: true })),
      switchMap(type =>
        defer(() => {
          if (type === 'google')
            return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
          if (type === 'facebook')
            return this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
          return EMPTY
        }).pipe(
          catchError(() => {
            this.message.error(
              `Oops! Bạn vừa hủy đăng nhập ${this.titleCasePipe.transform(
                type
              )}. Tiếp tục để kết nối cộng đồng TryMe nhé!`
            )
            this.state.set({ loading: false })
            return NEVER
          })
        )
      )
    )
    this.state.hold(googleLoginEffect$)
  }

  private registerGlobalLoading() {
    this.state.hold(this.loading$, loading => {
      if (loading)
        this.loadingMessageId = this.message.loading('TryMe đang đợi bạn hoàn tất...', {
          nzDuration: 99999999999
        }).messageId
      else if (this.loadingMessageId) this.message.remove(this.loadingMessageId)
    })
  }

  onClickForgotPassword() {
    this.modalRef.close()
    this.router.navigate([], {
      queryParams: { email: '', dialog: AuthDialogType.FORGOT_PASSWORD },
      queryParamsHandling: 'merge'
    })
  }
}
