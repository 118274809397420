import { AuthControllerRegisterRequestParams, AuthService, RegisterResponse } from 'src/app/data-access/generated/iam';
import { AuthDialogResult, AuthDialogType, NzModalContentType } from '../../util/auth-dialog/auth-dialog.types';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { FormBuilder, Validators } from '@angular/forms';
import { getMilis } from 'src/app/util/helpers';
import { LoadingState, MyResponseModel } from 'src/app/data-access/missing.model';
import { NEVER, Subject } from 'rxjs';
import { NoSpaceValidator } from '../../util';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { passwordValidators } from '../../util/validators/password.validators';
import { precheckResponse } from 'src/app/util/custom-rxjs';
import { RxState } from '@rx-angular/state';
const today = new Date()

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class RegisterComponent implements OnInit {
  form = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    fullname: [
      null,
      [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(200),
        Validators.pattern(/[a-zA-Z]/)
      ]
    ],
    username: [
      null,
      [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern(/[a-zA-Z0-9]/),
        NoSpaceValidator()
      ]
    ],
    password: [null, passwordValidators],
    birthday: [new Date(2000, 0, 1)],
    gender: [null]
  })
  passwordVisible = false

  loading$ = this.state.select('loading')

  private registerAction = new Subject<void>()

  disabledDate = (current: Date): boolean =>
    // Can not select days after today
    differenceInCalendarDays(current, today) > 0

  constructor(
    private fb: FormBuilder,
    private nzMessageService: NzMessageService,
    private modalRef: NzModalRef<NzModalContentType, AuthDialogResult>,
    private state: RxState<LoadingState>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.registerEffect()
  }

  async submitForm() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
    if (this.form.valid) {
      this.registerAction.next()
    }
  }

  onClickLogin() {
    this.modalRef.close({ type: AuthDialogType.LOGIN })
  }

  private registerEffect() {
    const registerEffect$ = this.registerAction.pipe(
      switchMap(() => {
        this.state.set({ loading: false })
        const { email, password, fullname, username, birthday, gender } = this.form.value
        const params: AuthControllerRegisterRequestParams = {
          registerDTO: {
            email: (email as string).trim(),
            password,
            repassword: password,
            name: fullname?.trim(),
            username: (username as string).trim(),
            birthday: getMilis(birthday as Date),
            gender
          }
        }
        return this.authService.authControllerRegister(params).pipe(
          precheckResponse(),
          tap((response: MyResponseModel<RegisterResponse>) =>
            this.modalRef.close({
              type: AuthDialogType.OTP_VERIFICATION,
              additionalParams: {
                from: 'register',
                email: params.registerDTO.email,
                requestId: response.data.requestId
              }
            })
          ),
          catchError((error: Error) => {
            this.nzMessageService.error(error.message, { nzPauseOnHover: true })
            return NEVER
          })
        )
      }),
      finalize(() => this.state.set({ loading: false }))
    )
    this.state.hold(registerEffect$)
  }
}
