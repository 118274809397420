import { ChallengeDetailResponse } from 'src/app/data-access/generated/challenge';
import { FriendProfileResponse } from 'src/app/data-access/generated/iam';
import { LoadingState, MyPagingModel } from 'src/app/data-access/missing.model';

export interface UserState extends LoadingState {
  user: FriendProfileResponse
  postType: UserPostType
  postsLoading: boolean
  posts: {
    challenge?: MyPagingModel<ChallengeDetailResponse>
    joined?: MyPagingModel<ChallengeDetailResponse>
    liked?: MyPagingModel<ChallengeDetailResponse>
  }
}
export type UserPostType = 'challenge' | 'joined' | 'liked'

export const userPostTypes: UserPostType[] = ['challenge', 'joined', 'liked']
export enum UserActionType {
  Share,
  Block,
  Unblock,
  Report,
  ViewProfile,
  EditProfile,
  ChangePassword,
  Setting,
  Logout
}

export enum AvatarActionType {
  View,
  Edit
}
