import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core'
import { RxState } from '@rx-angular/state'
import { interval } from 'rxjs'
import { filter } from 'rxjs/operators'
import { VideoPlayerState } from './video-player-state.model'

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['video-player.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class VideoPlayerComponent implements AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>

  @Input()
  set muted(value: boolean) {
    this.state.set({ muted: value })
  }
  @Input() poster = ''
  @Input()
  set paused(value: boolean) {
    this.state.set({ paused: value })
  }
  @Input()
  set duration(value: number) {
    if (value) {
      this.state.set({ showProgress: true })
      const increaseWhenPlaying$ = interval(value / 100).pipe(
        filter(() => !this.state.get('paused'))
      )
      this.state.hold(increaseWhenPlaying$, () => {
        const percent = this.state.get('percent')
        this.state.set({ percent: percent + 1 })
      })
    }
  }

  @Input()
  get videoUrl() {
    return this.state.get('videoUrl')
  }
  set videoUrl(value: string) {
    this.state.set({ videoUrl: value, percent: 0 })
  }

  @Output() clickPause = new EventEmitter<boolean>()
  @Output() clickMute = new EventEmitter<boolean>()

  paused$ = this.state.select('paused')
  muted$ = this.state.select('muted')
  percent$ = this.state.select('percent')
  showProgress$ = this.state.select('showProgress')

  constructor(private state: RxState<VideoPlayerState>) {
    this.state.set({ muted: true, paused: true })
  }

  ngAfterViewInit() {
    this.state.hold(this.state.select('paused'), (paused) => {
      if (paused) this.videoPlayer.nativeElement.pause()
      else this.videoPlayer.nativeElement.play()
    })
    this.state.hold(this.percent$.pipe(filter((percent) => percent >= 100)), () => {
      this.state.set({ percent: 0 })
      this.videoPlayer.nativeElement.pause()
      this.videoPlayer.nativeElement.currentTime = 0
      this.videoPlayer.nativeElement.play()
    })
  }

  onClickVideo(event: Event) {
    event.stopPropagation()
    const paused = this.state.get('paused')
    this.state.set({ paused: !paused })
    this.clickPause.emit(!paused)
  }

  onClickVideoMute(event: Event) {
    event.stopPropagation()
    const muted = this.state.get('muted')
    this.state.set({ muted: !muted })
    this.clickMute.emit(!muted)
  }
}
