import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService } from 'ng-zorro-antd/modal'
import { Subject } from 'rxjs'
import { switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { ReportService, ReportUserService } from 'src/app/data-access/generated/marketing'
import { AuthState } from 'src/app/features/auth/store'
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root'
import { CoreState, LoadReasons } from 'src/app/features/core/data-access/store'
import { distinctUntilIdChanged, hasValue } from 'src/app/util/custom-rxjs'
import { ReportDialogComponent } from './report-dialog.component'

/**
 * Inject the service to component to use
 */
@Injectable()
export class ReportDialogService {
  private reportPostAction = new Subject<string>()
  private reportChallengeAction = new Subject<string>()
  private reportUserAction = new Subject<string>()
  private reportCommentAction = new Subject<{ commentId: string; postId: string }>()

  private me$ = this.store.select(AuthState.profile).pipe(distinctUntilIdChanged(), hasValue())
  private challengeReasons$ = this.store.select(CoreState.reasonOf('challengeReasons'))

  constructor(
    private reportService: ReportService,
    private reportUserService: ReportUserService,
    private store: Store,
    private nzModalService: NzModalService,
    private nzMessageService: NzMessageService
  ) {
    this.loadReasonsEffect()
    this.reportPostEffect()
    this.reportUserEffect()
    this.reportCommentEffect()
    this.reportChallengeEffect()
  }

  reportPost(postId: string) {
    this.reportPostAction.next(postId)
  }
  reportUser(userId: string) {
    this.reportUserAction.next(userId)
  }
  reportComment(params: { commentId: string; postId: string }) {
    this.reportCommentAction.next(params)
  }
  reportChallenge(challengeId: string) {
    this.reportChallengeAction.next(challengeId)
  }

  private loadReasonsEffect() {
    // TODO: Refactor to load on-demand Reasons
    this.me$.subscribe(() => {
      this.store.dispatch([
        new LoadReasons({ limit: 20, page: 1, type: 'CHALLENGE_REPORT' }),
        new LoadReasons({ limit: 20, page: 1, type: 'USERREPORT' }),
        new LoadReasons({ limit: 20, page: 1, type: 'COMMENT_REPORT' })
      ])
    })
  }

  private reportPostEffect() {
    const reportPostEffect$ = this.reportPostAction.pipe(
      withLatestFrom(this.challengeReasons$),
      switchMap(([postId, challengeReasons]) =>
        this.nzModalService
          .create({
            nzContent: ReportDialogComponent,
            nzComponentParams: {
              title: 'Lý do báo cáo bài viết',
              reasons: challengeReasons
            },
            ...TRYME_MODAL_CONFIG
          })
          .afterClose.pipe(
            hasValue(),
            switchMap((reasonId) =>
              this.reportService.reportControllerReportPost({ reportPostDTO: { reasonId, postId } })
            )
          )
      ),
      this.notifySuccess()
    )
    reportPostEffect$.subscribe()
  }

  private reportUserEffect() {
    const userReasons$ = this.store.select(CoreState.reasonOf('userReasons'))
    const reportUserEffect$ = this.reportUserAction.pipe(
      withLatestFrom(userReasons$),
      switchMap(([userId, userReasons]) =>
        this.nzModalService
          .create({
            nzContent: ReportDialogComponent,
            nzComponentParams: {
              title: 'Lý do báo cáo người dùng',
              reasons: userReasons
            },
            ...TRYME_MODAL_CONFIG
          })
          .afterClose.pipe(
            hasValue(),
            switchMap((reasonId) =>
              this.reportUserService.reportUserControllerCreateReportUser({
                reportUserDTO: { reasonId, userId }
              })
            )
          )
      ),
      this.notifySuccess()
    )
    reportUserEffect$.subscribe()
  }

  private reportCommentEffect() {
    const userReasons$ = this.store.select(CoreState.reasonOf('commentReasons'))
    const reportCommentEffect$ = this.reportCommentAction.pipe(
      withLatestFrom(userReasons$),
      switchMap(([{ commentId, postId }, userReasons]) =>
        this.nzModalService
          .create({
            nzContent: ReportDialogComponent,
            nzComponentParams: {
              title: 'Lý do báo cáo người dùng',
              reasons: userReasons
            },
            ...TRYME_MODAL_CONFIG
          })
          .afterClose.pipe(
            hasValue(),
            switchMap((reasonId) =>
              this.reportService.reportControllerReport({
                reportCommentDTO: { reasonId, commentId, postId }
              })
            )
          )
      ),
      this.notifySuccess()
    )
    reportCommentEffect$.subscribe()
  }

  private reportChallengeEffect() {
    const reportChallengeEffect$ = this.reportChallengeAction.pipe(
      withLatestFrom(this.challengeReasons$),
      switchMap(([challengeId, challengeReasons]) =>
        this.nzModalService
          .create({
            nzContent: ReportDialogComponent,
            nzComponentParams: {
              title: 'Lý do báo cáo thử thách',
              reasons: challengeReasons
            },
            ...TRYME_MODAL_CONFIG
          })
          .afterClose.pipe(
            hasValue(),
            switchMap((reasonId) =>
              this.reportService.reportControllerReportChallenge({
                reportChallengeDTO: { reasonId, challengeId }
              })
            )
          )
      ),
      this.notifySuccess()
    )
    reportChallengeEffect$.subscribe()
  }

  private notifySuccess() {
    return tap(() =>
      this.nzMessageService.success(
        'Gửi báo cáo thành công! TryMe xin cảm ơn và sẽ xem xét trong thời gian sớm nhất.'
      )
    )
  }
}
