/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserResponse } from './user-response';
import { MediaModel } from './media-model';
import { AccountResponse } from './account-response';


export interface FeedbackResponse { 
    readonly _id: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly isDel: boolean;
    readonly email: string;
    readonly phoneNumber: string;
    readonly userId: string;
    readonly accountId: string;
    readonly question: string;
    readonly content: string;
    medias: Array<MediaModel>;
    readonly statusReply: FeedbackResponse.StatusReplyEnum;
    readonly status: FeedbackResponse.StatusEnum;
    user: UserResponse;
    account: AccountResponse;
    time: string;
    messages: Array<string>;
}
export namespace FeedbackResponse {
    export type StatusReplyEnum = 'FS_UNREPLIED' | 'FS_REPLIED' | 'FS_PENDING';
    export const StatusReplyEnum = {
        UNREPLIED: 'FS_UNREPLIED' as StatusReplyEnum,
        REPLIED: 'FS_REPLIED' as StatusReplyEnum,
        PENDING: 'FS_PENDING' as StatusReplyEnum
    };
    export type StatusEnum = 'FS_OPEN' | 'FS_CLOSE';
    export const StatusEnum = {
        OPEN: 'FS_OPEN' as StatusEnum,
        CLOSE: 'FS_CLOSE' as StatusEnum
    };
}


