import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SuggestItemComponent } from './suggest-item.component'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NumberToTextPipeModule } from 'src/app/util/pipes'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { CloseDialogDirectiveModule } from 'src/app/util/directives/close-dialog'

const nzModules = [NzAvatarModule, NzSkeletonModule, NzIconModule]

@NgModule({
  declarations: [SuggestItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NumberToTextPipeModule,
    CloseDialogDirectiveModule,
    nzModules
  ],
  exports: [SuggestItemComponent]
})
export class SuggestItemModule {}
