/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaModel } from './media-model';
import { PostHistoryModel } from './post-history-model';
import { ColorModel } from './color-model';
import { QuestionResponseModel } from './question-response-model';
import { PostUserResponse } from './post-user-response';
import { GiftModel } from './gift-model';
import { TopicModel } from './topic-model';
import { ChallengeResponse } from './challenge-response';
import { PostSoundModel } from './post-sound-model';


export interface PostResponse { 
    readonly _id: string;
    readonly createdAt: number;
    readonly isDel: boolean;
    userId: string;
    matchIds: Array<string>;
    avatarUrl: string;
    description: string;
    status: number;
    privacy: number;
    hashtags: Array<string>;
    challengeId: string;
    downloadable: boolean;
    commentable: boolean;
    medias: Array<MediaModel>;
    liked: number;
    shared: number;
    commented: number;
    viewed: number;
    score: number;
    joined: number;
    topicId: string;
    official: boolean;
    type: PostResponse.TypeEnum;
    histories: Array<PostHistoryModel>;
    giftRank?: number;
    giftScore?: number;
    soundId?: string;
    sound?: PostSoundModel;
    trend?: number;
    color: ColorModel;
    user: PostUserResponse;
    readonly wasLiked: boolean;
    topic: TopicModel;
    challenge: ChallengeResponse;
    time: string;
    gift: GiftModel;
    matchRank: number;
    questions: Array<QuestionResponseModel>;
    isJoined: boolean;
    avatars: Array<string>;
}
export namespace PostResponse {
    export type TypeEnum = 'video' | 'image' | 'quiz';
    export const TypeEnum = {
        Video: 'video' as TypeEnum,
        Image: 'image' as TypeEnum,
        Quiz: 'quiz' as TypeEnum
    };
}


