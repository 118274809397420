import { ConversationResponse, MessageResponse } from 'src/app/data-access/generated/messenger'
import { FollowResponse } from 'src/app/data-access/generated/relation'
import { MyPagingModel } from 'src/app/data-access/missing.model'

export const STATE_NAME = 'TryMe_Messenger'
export const INITIAL_STATE: StateModel = {
  unreadMessageCount: 0
}
export interface StateModel {
  conversations?: MyPagingModel<ConversationResponse>
  selectedConversation?: ConversationResponse
  selectedConversationMessages?: MyPagingModel<MessageResponse>
  myFollowingUsers?: MyPagingModel<FollowResponse>
  unreadMessageCount: number
  errorMessage?: string
}
