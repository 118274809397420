export function generateUUID(): string {
  const navigator_info = window.navigator
  const screen_info = window.screen
  let uuid = navigator_info.mimeTypes.length.toString()
  uuid += navigator_info.userAgent.replace(/\D+/g, '')
  uuid += navigator_info.plugins.length
  uuid += screen_info.height || ''
  uuid += screen_info.width || ''
  uuid += screen_info.pixelDepth || ''
  return uuid
}
