import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { RxState } from '@rx-angular/state'
import { interval } from 'rxjs'
import { filter } from 'rxjs/operators'
import { MediaPlayerState } from './media-player-state.model'

@Component({
  selector: 'media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['media-player.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class MediaPlayerComponent {
  @Input() muted = true
  @Input() paused = true
  @Input() showPausedIcon = true
  @Input() controlsClass = 'h-20'
  @Input()
  set duration(value: number) {
    if (value) {
      this.state.set({ showProgress: true })
      const increaseWhenPlaying$ = interval(value / 100).pipe(filter(() => !this.paused))
      this.state.hold(increaseWhenPlaying$, () => {
        const percent = this.state.get('percent')
        this.state.set({ percent: percent + 1 })
      })
    }
  }

  @Output() clickPause = new EventEmitter<boolean>()
  @Output() clickMute = new EventEmitter<boolean>()

  percent$ = this.state.select('percent')
  showProgress$ = this.state.select('showProgress')

  constructor(private state: RxState<MediaPlayerState>) {}

  onClickPause(event: Event) {
    event.stopPropagation()
    this.paused = !this.paused
    this.clickPause.emit(this.paused)
  }

  onClickMute(event: Event) {
    event.stopPropagation()
    this.muted = !this.muted
    this.clickMute.emit(this.muted)
  }
}
