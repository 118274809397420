import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NzModalService } from 'ng-zorro-antd/modal'
import { map, switchMap } from 'rxjs/operators'
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root'
import { hasValue } from 'src/app/util/custom-rxjs'
import { ChooseCreateTypeDialogComponent } from './choose-create-type-dialog.component'

@Injectable()
export class ChooseCreateTypeDialogService {
  constructor(
    private nzModalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.openCreateChallengeDialogFromRouteEffect()
  }

  open() {
    this.router.navigate([], { queryParams: { 'challenge-create': true } })
  }

  private openCreateChallengeDialogFromRouteEffect() {
    const postId$ = this.activatedRoute.queryParams.pipe(
      map(queryParams => queryParams['challenge-create'])
    )

    postId$
      .pipe(
        hasValue(),
        switchMap(
          () =>
            this.nzModalService.create<ChooseCreateTypeDialogComponent, void>({
              nzContent: ChooseCreateTypeDialogComponent,
              nzWidth: 736,
              nzCloseOnNavigation: true,
              ...TRYME_MODAL_CONFIG
            }).afterClose
        )
      )
      .subscribe(() => {
        this.router.navigate([], { queryParams: { post: undefined } })
      })
  }
}
