import { Injectable } from '@angular/core'
import { RxState } from '@rx-angular/state'
import produce from 'immer'
import { BehaviorSubject, combineLatest, Subject } from 'rxjs'
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { LikeResponse, PostService } from 'src/app/data-access/generated/challenge'
import { MyPagingModel, MyResponseModel } from 'src/app/data-access/missing.model'
import { FollowButtonModel } from 'src/app/ui/follow-button/util'
import { hasValue } from 'src/app/util/custom-rxjs'
import { LikedUsersDialogState } from './model'

@Injectable()
export class LikedUsersDialogComponentStore extends RxState<LikedUsersDialogState> {
  private loadLikeAction = new BehaviorSubject(0)
  private followAction = new Subject<FollowButtonModel>()

  constructor(private postService: PostService) {
    super()
    this.connectLikes()
    this.followEffect()
  }

  loadMoreLikes(page: number) {
    this.loadLikeAction.next(page)
  }

  followUser(followButtonModel: FollowButtonModel) {
    this.followAction.next(followButtonModel)
  }

  private connectLikes() {
    const likes$ = combineLatest([
      this.loadLikeAction,
      this.select('postId').pipe(hasValue())
    ]).pipe(
      switchMap(([page, postId]) =>
        this.postService.postControllerGeDetailsLikeChallenge({ postId, limit: 20, page: page + 1 })
      ),
      map((response: MyResponseModel<MyPagingModel<LikeResponse>>) => {
        const next = response.data
        const current = this.get('likes')
        return current ? { ...next, data: [...current.data, ...next.data] } : next
      }),
      tap(() => this.set({ loadingMore: false }))
    )
    this.connect('likes', likes$)
  }

  private followEffect() {
    const followEffect$ = this.followAction.pipe(
      withLatestFrom(this.select('likes')),
      map(([followButtonModel, likes]) =>
        produce(likes, (draft) => {
          draft.data.find((like) => like.userId === followButtonModel.friendId).relation =
            followButtonModel.relation
        })
      )
    )
    this.connect('likes', followEffect$)
  }
}
