import produce from 'immer';
import { catchError, distinctUntilKeyChanged, map, switchMap, tap } from 'rxjs/operators';
import { CommentResponse, CommentService, CreateCommentDTO, PostControllerCommentRequestParams, PostControllerPaginateCommentRequestParams, PostResponse, PostService } from 'src/app/data-access/generated/challenge';
import { FormControl } from '@angular/forms';
import { hasValue } from 'src/app/util/custom-rxjs';
import { Injectable } from '@angular/core';
import { MyPagingModel, MyResponseModel } from 'src/app/data-access/missing.model';
import { NEVER, Subject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PageViewEvent, SegmentEvent } from 'src/app/util/services/constants/segment-event.constant';
import { PostDetailState } from './post-detail-state.model';
import { PostItemComponentStore } from '../post-item/post-item.store';
import { PostItemState } from '../post-item/post-item-state.model';
import { RxState } from '@rx-angular/state';
import { SegmentTrackingService } from 'src/app/util/services/segment.service';

@Injectable()
export class PostDetailComponentStore extends RxState<PostDetailState & PostItemState> {
  private commentAction = new Subject<CreateCommentDTO>()
  private loadPostAction = new Subject<string>()
  private loadCommentsAction = new Subject<{
    params: PostControllerPaginateCommentRequestParams
    willLoadMore: boolean
  }>()
  private toggleLikeCommentAction = new Subject<{ commentId: string; current: boolean }>()

  commentControl = new FormControl()

  constructor(
    private postService: PostService,
    private commentService: CommentService,
    private postItemComponentStore: PostItemComponentStore,
    private nzMessageService: NzMessageService,
    private segmentTrackingService: SegmentTrackingService
  ) {
    super()
    this.commentEffect()
    this.loadPostEffect()
    this.loadCommentsEffect()
    this.toggleLikeCommentEffect()
    this.commentControlDisableEffect()
    this.connect('post', this.postItemComponentStore.select('post'))
  }

  loadPostById(id: string) {
    this.loadPostAction.next(id)
  }

  loadComments(params: PostControllerPaginateCommentRequestParams, willLoadMore: boolean) {
    this.loadCommentsAction.next({ params, willLoadMore })
  }

  comment(params: PostControllerCommentRequestParams['createCommentDTO']) {
    this.commentAction.next(params)
  }

  toggleLike({ commentId, current }: { commentId: string; current: boolean }) {
    this.toggleLikeCommentAction.next({ commentId, current })
  }

  private commentEffect() {
    const commentEffect$ = this.commentAction.pipe(
      switchMap(params => {
        const post = this.get('post')
        const { originalId } = params
        this.set({ sending: true })
        this.commentControl.disable()

        return this.postService
          .postControllerComment({ id: post._id, createCommentDTO: params })
          .pipe(
            map((res: MyResponseModel<CommentResponse>) => {
              this.commentControl.enable()
              this.set({
                sending: false,
                replyInfo: { commentId: undefined, userId: undefined, username: undefined }
              })
              this.commentControl.reset()

              return produce(this.get(), ({ comments: draftComments, post: draftPost }) => {
                if (originalId) {
                  for (const com of draftComments.data) {
                    const injectedParent =
                      com._id === originalId ? com : com.children?.find(c => c._id === originalId)
                    if (injectedParent) {
                      injectedParent.replied = injectedParent.replied + 1
                      injectedParent.children = [res.data, ...(injectedParent.children ?? [])]
                      break
                    }
                  }
                } else {
                  draftComments.data = [res.data, ...(draftComments.data ?? [])]
                }
                draftPost.commented = res.data.postReaction.commented
                draftPost.liked = res.data.postReaction.liked
                draftPost.shared = res.data.postReaction.shared
                draftPost.score = res.data.postReaction.score
              })
            }),
            catchError(({ message }: Error) => {
              this.nzMessageService.error(message)
              this.set({ sending: false })
              this.commentControl.enable()
              return NEVER
            })
          )
      })
    )
    this.connect(commentEffect$)
  }

  //TODO: current???
  private loadPostEffect() {
    const loadPostEffect$ = this.loadPostAction.pipe(
      tap(() => this.set({ loading: true })),
      switchMap(id => this.postService.postControllerGet({ id })),
      map((response: MyResponseModel<PostResponse>) => {
        this.set({ loading: false })
        this.postItemComponentStore.post = response.data
        this.segmentTrackingService.track(SegmentEvent.ViewPost, {
          postId: response.data._id,
          destination: PageViewEvent.Post,
          challengeId: response.data.challengeId,
          topicId: response.data.topicId,
          owner: response.data.userId
        })
      })
    )
    this.hold(loadPostEffect$)
  }

  private loadCommentsEffect() {
    const loadCommentsEffect$ = this.loadCommentsAction.pipe(
      switchMap(({ params, willLoadMore }) => {
        this.set({ commentsLoading: true })
        const state = this.get()
        const processedParams =
          willLoadMore && state.comments ? { ...params, page: state.comments.page + 1 } : params

        return this.postService.postControllerPaginateComment(processedParams).pipe(
          map((response: MyResponseModel<MyPagingModel<CommentResponse>>) => {
            this.set({ commentsLoading: false })
            const processedComments = willLoadMore
              ? produce(state.comments, draft => {
                  draft.page = response.data.page
                  draft.data = state.comments.data.concat(response.data.data)
                })
              : response.data
            if (willLoadMore) {
              this.segmentTrackingService.track(SegmentEvent.ViewMoreComment, {
                postId: processedParams['postId']
              })
            }
            return processedComments
          })
        )
      })
    )
    this.connect('comments', loadCommentsEffect$)
  }

  private toggleLikeCommentEffect() {
    const toggleLikeCommentEffect$ = this.toggleLikeCommentAction.pipe(
      switchMap(({ commentId, current }) => {
        const api$ = current
          ? this.commentService.commentControllerUnlike({ id: commentId })
          : this.commentService.commentControllerLike({ id: commentId })
        return api$.pipe(
          map(() => {
            const comments = this.get('comments')
            return produce(comments, draft => {
              for (const com of draft.data) {
                const targetComment =
                  com._id === commentId ? com : com.children?.find(c => c._id === commentId)
                if (targetComment) {
                  targetComment.liked = targetComment.liked + (current ? -1 : 1)
                  targetComment.wasLiked = !current
                  break
                }
              }
            })
          })
        )
      })
    )

    this.connect('comments', toggleLikeCommentEffect$)
  }

  private commentControlDisableEffect() {
    const sending$ = this.select('sending')
    this.hold(sending$, sending => {
      if (sending) this.commentControl.disable()
      else this.commentControl.enable()
    })

    this.hold(
      this.select('post').pipe(hasValue(), distinctUntilKeyChanged('commentable')),
      ({ commentable }) => {
        if (commentable) this.commentControl.enable()
        else this.commentControl.disable()
      }
    )
  }
}
