<div class="gift-wrapper" [class.end]="nowInMillisecond >= endTime">
  <i nz-icon nzType="icons/fill:gift" class="!text-base align-icon"></i>
  {{'reward' | translate}}
  <span class="dot mr-1">&#x25cf;</span>
  <ng-container *ngIf="nowInMillisecond < endTime; else endGiftTemplate">
    Còn {{ endTime | tuiMapper: getGiftInterval | async }}
  </ng-container>
  <ng-template #endGiftTemplate>{{'finished' | translate}}</ng-template>
  <i nz-icon nzType="icons/outline:chevron-forward" class="align-icon"></i>
</div>
