import { NgModule } from '@angular/core'
import { IamApiModule, Configuration as IamConfiguration } from 'src/app/data-access/generated/iam'
import {
  TrendingApiModule,
  Configuration as TrendingConfiguration
} from 'src/app/data-access/generated/trending'
import {
  ActivityApiModule,
  Configuration as ActivityConfiguration
} from 'src/app/data-access/generated/activity'
import {
  ChallengeApiModule,
  Configuration as ChallengeConfiguration
} from 'src/app/data-access/generated/challenge'
import {
  MessengerApiModule,
  Configuration as MessengerConfiguration
} from 'src/app/data-access/generated/messenger'
import {
  RelationApiModule,
  Configuration as RelationConfiguration
} from 'src/app/data-access/generated/relation'
import {
  MarketingApiModule,
  Configuration as MarketingConfiguration
} from 'src/app/data-access/generated/marketing'
import {
  ShareApiModule,
  Configuration as ShareConfiguration
} from 'src/app/data-access/generated/share'
import { environment } from 'src/environments/environment'

@NgModule({
  imports: [
    IamApiModule.forRoot(() => new IamConfiguration({ basePath: environment.iam2 })),
    ActivityApiModule.forRoot(() => new ActivityConfiguration({ basePath: environment.activity2 })),
    ChallengeApiModule.forRoot(
      () => new ChallengeConfiguration({ basePath: environment.challenge2 })
    ),
    TrendingApiModule.forRoot(() => new TrendingConfiguration({ basePath: environment.trending2 })),
    MessengerApiModule.forRoot(
      () => new MessengerConfiguration({ basePath: environment.messenger2 })
    ),
    RelationApiModule.forRoot(() => new RelationConfiguration({ basePath: environment.relation2 })),
    MarketingApiModule.forRoot(
      () => new MarketingConfiguration({ basePath: environment.marketing2 })
    ),
    ShareApiModule.forRoot(() => new ShareConfiguration({ basePath: environment.share2 }))
  ]
})
export class OpenApiRootModule {}
