import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { CreateCommentDTO } from '../model/models';
import { CustomHttpParameterCodec } from '../encoder';
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PostControllerCommentRequestParams,
  PostControllerDeletePostRequestParams,
  PostControllerGeDetailsLikeChallengeRequestParams,
  PostControllerGetRequestParams,
  PostControllerGetStatisticRequestParams,
  PostControllerLikeRequestParams,
  PostControllerPaginateCommentRequestParams,
  PostControllerPaginateFollowingPostsRequestParams,
  PostControllerPaginateRequestParams,
  PostControllerRecommendRequestParams,
  PostControllerShareRequestParams,
  PostControllerUnlikeRequestParams,
  PostControllerUpdatePostRequestParams,
  PostServiceInterface
  } from './post.serviceInterface';
import { ResponseModel } from '../model/models';
import { UpdatePostDTO } from '../model/models';
/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */






@Injectable({
  providedIn: 'root'
})
export class PostService implements PostServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerComment(requestParameters: PostControllerCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerComment(requestParameters: PostControllerCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerComment(requestParameters: PostControllerCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerComment(requestParameters: PostControllerCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerComment.');
        }
        const createCommentDTO = requestParameters.createCommentDTO;
        if (createCommentDTO === null || createCommentDTO === undefined) {
            throw new Error('Required parameter createCommentDTO was null or undefined when calling postControllerComment.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}/comment`,
            createCommentDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerDeletePost(requestParameters: PostControllerDeletePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerDeletePost(requestParameters: PostControllerDeletePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerDeletePost(requestParameters: PostControllerDeletePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerDeletePost(requestParameters: PostControllerDeletePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerDeletePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerGeDetailsLikeChallenge(requestParameters: PostControllerGeDetailsLikeChallengeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerGeDetailsLikeChallenge(requestParameters: PostControllerGeDetailsLikeChallengeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerGeDetailsLikeChallenge(requestParameters: PostControllerGeDetailsLikeChallengeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerGeDetailsLikeChallenge(requestParameters: PostControllerGeDetailsLikeChallengeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling postControllerGeDetailsLikeChallenge.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling postControllerGeDetailsLikeChallenge.');
        }
        const postId = requestParameters.postId;
        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling postControllerGeDetailsLikeChallenge.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(postId))}/like`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerGet(requestParameters: PostControllerGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerGet(requestParameters: PostControllerGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerGet(requestParameters: PostControllerGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerGet(requestParameters: PostControllerGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerGetStatistic(requestParameters: PostControllerGetStatisticRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerGetStatistic(requestParameters: PostControllerGetStatisticRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerGetStatistic(requestParameters: PostControllerGetStatisticRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerGetStatistic(requestParameters: PostControllerGetStatisticRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerGetStatistic.');
        }
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling postControllerGetStatistic.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling postControllerGetStatistic.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}/statistic`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerLike(requestParameters: PostControllerLikeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerLike(requestParameters: PostControllerLikeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerLike(requestParameters: PostControllerLikeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerLike(requestParameters: PostControllerLikeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerLike.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}/like`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerPaginate(requestParameters: PostControllerPaginateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerPaginate(requestParameters: PostControllerPaginateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerPaginate(requestParameters: PostControllerPaginateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerPaginate(requestParameters: PostControllerPaginateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling postControllerPaginate.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling postControllerPaginate.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;
        const matchIds = requestParameters.matchIds;
        const challengeId = requestParameters.challengeId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }
        if (matchIds !== undefined && matchIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>matchIds, 'matchIds');
        }
        if (challengeId !== undefined && challengeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>challengeId, 'challengeId');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerPaginateComment(requestParameters: PostControllerPaginateCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerPaginateComment(requestParameters: PostControllerPaginateCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerPaginateComment(requestParameters: PostControllerPaginateCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerPaginateComment(requestParameters: PostControllerPaginateCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const postId = requestParameters.postId;
        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling postControllerPaginateComment.');
        }
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling postControllerPaginateComment.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling postControllerPaginateComment.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;
        const originalId = requestParameters.originalId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }
        if (originalId !== undefined && originalId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>originalId, 'originalId');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(postId))}/comment`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerPaginateFollowingPosts(requestParameters: PostControllerPaginateFollowingPostsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerPaginateFollowingPosts(requestParameters: PostControllerPaginateFollowingPostsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerPaginateFollowingPosts(requestParameters: PostControllerPaginateFollowingPostsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerPaginateFollowingPosts(requestParameters: PostControllerPaginateFollowingPostsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling postControllerPaginateFollowingPosts.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling postControllerPaginateFollowingPosts.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/following`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerRecommend(requestParameters: PostControllerRecommendRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerRecommend(requestParameters: PostControllerRecommendRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerRecommend(requestParameters: PostControllerRecommendRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerRecommend(requestParameters: PostControllerRecommendRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling postControllerRecommend.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling postControllerRecommend.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/recommendation`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerShare(requestParameters: PostControllerShareRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerShare(requestParameters: PostControllerShareRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerShare(requestParameters: PostControllerShareRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerShare(requestParameters: PostControllerShareRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerShare.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}/share`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerUnlike(requestParameters: PostControllerUnlikeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerUnlike(requestParameters: PostControllerUnlikeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerUnlike(requestParameters: PostControllerUnlikeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerUnlike(requestParameters: PostControllerUnlikeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerUnlike.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}/unlike`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postControllerUpdatePost(requestParameters: PostControllerUpdatePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public postControllerUpdatePost(requestParameters: PostControllerUpdatePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public postControllerUpdatePost(requestParameters: PostControllerUpdatePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public postControllerUpdatePost(requestParameters: PostControllerUpdatePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling postControllerUpdatePost.');
        }
        const updatePostDTO = requestParameters.updatePostDTO;
        if (updatePostDTO === null || updatePostDTO === undefined) {
            throw new Error('Required parameter updatePostDTO was null or undefined when calling postControllerUpdatePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ResponseModel & object>(`${this.configuration.basePath}/user/api/post/${encodeURIComponent(String(id))}`,
            updatePostDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
