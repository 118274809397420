import { Injectable } from '@angular/core';
import { SoundResponse } from 'src/app/data-access/generated/challenge';

@Injectable({ providedIn: 'root' })
export class AudioPlayerService {
  sound: SoundResponse
  private audio: HTMLAudioElement = new Audio()

  get isPaused(): boolean {
    return this.audio.paused
  }

  play(withSound = true, loop = true): Promise<void> {
    if (!this.sound) return Promise.reject(new Error('No sound found'))
    this.audio.loop = loop;
    if (this.audio.src === this.sound.url) {
      if (!withSound) this.audio.muted = true
      return this.audio.play()
    }
    return new Promise((resolve, reject) => {
      this.audio.addEventListener('canplay', () => {
        resolve(this.audio.play().catch(error => reject(error)))
      })
      this.audio.src = this.sound.url
      this.audio.title = this.sound.name
      if (!withSound) this.audio.muted = true
      this.audio.load()
    })
  }

  pause() {
    this.audio.pause()
  }

  mute() {
    this.audio.muted = true
  }

  unmute() {
    this.audio.muted = false
  }
}
