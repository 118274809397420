import { ActivityModelResponse } from 'src/app/data-access/generated/activity'
import {
  ReasonControllerPaginateRequestParams,
  ReasonResponse,
  AppSettingModel
} from 'src/app/data-access/generated/iam'
import { MyPagingModel } from 'src/app/data-access/missing.model'
export const STATE_NAME = 'TryMe_Header'
export const INITIAL_STATE: StateModel = {
  unreadNotiCount: 0,
}

export type ReasonValue =
  | 'challengeReasons'
  | 'commentReasons'
  | 'feedbackReasons'
  | 'userReasons'
  | 'verificationReasons'

export const ReasonKey: Record<ReasonControllerPaginateRequestParams['type'], ReasonValue> = {
  CHALLENGE_REPORT: 'challengeReasons',
  COMMENT_REPORT: 'commentReasons',
  FEEDBACK: 'feedbackReasons',
  USERREPORT: 'userReasons',
  VERIFICATION_REASON: 'verificationReasons'
}

export interface StateModel extends Partial<Record<ReasonValue, MyPagingModel<ReasonResponse>>> {
  notifications?: MyPagingModel<ActivityModelResponse>
  unreadNotiCount: number
  errorMessage?: string
  settings?: AppSettingModel
}
