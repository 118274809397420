<h1 class="leading-none mb-4">Điểm bài viết</h1>
<ul>
  <li>
    <strong>Điểm phần thưởng</strong>
    <strong>
      <i nz-icon nzType="icons/fill:gift" class="align-icon"></i>
      {{ giftScore | numberToText }}
    </strong>
  </li>
  <li>
    <strong>Thứ hạng nhận giải</strong>
    <strong>
      <i nz-icon nzType="icons/outline:growth" class="align-icon"></i>
      {{ giftRank | numberToText }}
    </strong>
  </li>
  <li>
    <strong>Điểm thử thách</strong>
    <strong>
      <i nz-icon nzType="icons/outline:badge" class="align-icon"></i>
      {{ score | numberToText }}
    </strong>
  </li>
</ul>
