import {
  AuthControllerRegisterRequestParams,
  LoginSuccessResponse
} from 'src/app/data-access/generated/iam'

export const STATE_NAME = 'TryMe_Auth'
export const INITIAL_STATE: StateModel = {}
export interface StateModel {
  errorMessage?: string
  loginResponse?: Partial<LoginSuccessResponse>
  registerParams?: AuthControllerRegisterRequestParams['registerDTO']
}
