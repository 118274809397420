import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RequireSignComponent } from './require-sign.component'

@NgModule({
  declarations: [RequireSignComponent],
  imports: [CommonModule],
  exports: [RequireSignComponent]
})
export class RequireSignModule { }
