<h2 class="text-3xl leading-none mb-4">{{ title }}</h2>

<nz-list [nzHeader]="null" [nzFooter]="null" *ngIf="reasons?.data.length" class="!mb-4">
  <nz-radio-group [(ngModel)]="selectedId" class="w-full">
    <ng-container *ngFor="let item of reasons.data">
      <nz-list-item class="cursor-pointer py-3" (click)="selectedId = item._id">
        <label nz-radio [nzValue]="item._id" class="!text-base pointer-events-none">{{
          item.content
        }}</label>
      </nz-list-item>
    </ng-container>
  </nz-radio-group>
</nz-list>

<footer class="text-right">
  <button nz-button nzSize="large" nzType="primary" (click)="send()" class="mr-2">
    Gửi báo cáo
  </button>
  <button nz-button nzType="text" nzSize="large" (click)="cancel()">Hủy</button>
</footer>
