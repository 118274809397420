import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FileUploadResponse, MyResponseModel } from 'src/app/data-access/missing.model'

export type UploadResponse = MyResponseModel<FileUploadResponse[]>

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadFile({ path, files }: { path: string; files: File[] }) {
    const formData = new FormData()
    formData.append('path', path)
    files.forEach(file => formData.append('files', file, file.name))

    return this.http.post<UploadResponse>(`${environment.uploadUrl}/file/upload`, formData, {
      headers: { code: environment.storageCode }
    })
  }
}
