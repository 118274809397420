import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { CreateMatchDIO } from '../model/models';
import { CustomHttpParameterCodec } from '../encoder';
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatchCategoryResponse } from '../model/models';
import { MatchControllerCreateRequestParams, MatchControllerGetRequestParams, MatchControllerPaginateMatchesByCategoryRequestParams, MatchControllerPaginateMatchGroupsByCategoryRequestParams, MatchControllerPaginateNewMatchRequestParams, MatchControllerPaginateRequestParams, MatchControllerPaginateTopMatchRequestParams, MatchControllerRejectRequestParams, MatchServiceInterface } from './match.serviceInterface';
import { MatchResponse } from '../model/models';
import { Observable } from 'rxjs';
import { ResponseModel } from '../model/models';
/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */






@Injectable({
  providedIn: 'root'
})
export class MatchService implements MatchServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerCreate(requestParameters: MatchControllerCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MatchResponse>;
    public matchControllerCreate(requestParameters: MatchControllerCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MatchResponse>>;
    public matchControllerCreate(requestParameters: MatchControllerCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MatchResponse>>;
    public matchControllerCreate(requestParameters: MatchControllerCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createMatchDIO = requestParameters.createMatchDIO;
        if (createMatchDIO === null || createMatchDIO === undefined) {
            throw new Error('Required parameter createMatchDIO was null or undefined when calling matchControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MatchResponse>(`${this.configuration.basePath}/user/api/match`,
            createMatchDIO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerGet(requestParameters: MatchControllerGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public matchControllerGet(requestParameters: MatchControllerGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public matchControllerGet(requestParameters: MatchControllerGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public matchControllerGet(requestParameters: MatchControllerGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling matchControllerGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/match/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerListFeaturedMatches(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MatchCategoryResponse>>;
    public matchControllerListFeaturedMatches(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MatchCategoryResponse>>>;
    public matchControllerListFeaturedMatches(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MatchCategoryResponse>>>;
    public matchControllerListFeaturedMatches(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<MatchCategoryResponse>>(`${this.configuration.basePath}/user/api/match/categories`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerPaginate(requestParameters: MatchControllerPaginateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public matchControllerPaginate(requestParameters: MatchControllerPaginateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public matchControllerPaginate(requestParameters: MatchControllerPaginateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public matchControllerPaginate(requestParameters: MatchControllerPaginateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling matchControllerPaginate.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling matchControllerPaginate.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;
        const userId = requestParameters.userId;
        const challengeId = requestParameters.challengeId;
        const status = requestParameters.status;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (challengeId !== undefined && challengeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>challengeId, 'challengeId');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/match`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerPaginateMatchGroupsByCategory(requestParameters: MatchControllerPaginateMatchGroupsByCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public matchControllerPaginateMatchGroupsByCategory(requestParameters: MatchControllerPaginateMatchGroupsByCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public matchControllerPaginateMatchGroupsByCategory(requestParameters: MatchControllerPaginateMatchGroupsByCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public matchControllerPaginateMatchGroupsByCategory(requestParameters: MatchControllerPaginateMatchGroupsByCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const type = requestParameters.type;
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling matchControllerPaginateMatchGroupsByCategory.');
        }
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling matchControllerPaginateMatchGroupsByCategory.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling matchControllerPaginateMatchGroupsByCategory.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/match/${encodeURIComponent(String(type))}/matches`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerPaginateMatchesByCategory(requestParameters: MatchControllerPaginateMatchesByCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public matchControllerPaginateMatchesByCategory(requestParameters: MatchControllerPaginateMatchesByCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public matchControllerPaginateMatchesByCategory(requestParameters: MatchControllerPaginateMatchesByCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public matchControllerPaginateMatchesByCategory(requestParameters: MatchControllerPaginateMatchesByCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling matchControllerPaginateMatchesByCategory.');
        }
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling matchControllerPaginateMatchesByCategory.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling matchControllerPaginateMatchesByCategory.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/match-group/${encodeURIComponent(String(challengeId))}/matches`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerPaginateNewMatch(requestParameters: MatchControllerPaginateNewMatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public matchControllerPaginateNewMatch(requestParameters: MatchControllerPaginateNewMatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public matchControllerPaginateNewMatch(requestParameters: MatchControllerPaginateNewMatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public matchControllerPaginateNewMatch(requestParameters: MatchControllerPaginateNewMatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling matchControllerPaginateNewMatch.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling matchControllerPaginateNewMatch.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/new-match`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerPaginateTopMatch(requestParameters: MatchControllerPaginateTopMatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel & object>;
    public matchControllerPaginateTopMatch(requestParameters: MatchControllerPaginateTopMatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel & object>>;
    public matchControllerPaginateTopMatch(requestParameters: MatchControllerPaginateTopMatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel & object>>;
    public matchControllerPaginateTopMatch(requestParameters: MatchControllerPaginateTopMatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling matchControllerPaginateTopMatch.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling matchControllerPaginateTopMatch.');
        }
        const createdAtFrom = requestParameters.createdAtFrom;
        const createdAtTo = requestParameters.createdAtTo;
        const sortBy = requestParameters.sortBy;
        const sortType = requestParameters.sortType;
        const ids = requestParameters.ids;
        const cursor = requestParameters.cursor;
        const includeCursor = requestParameters.includeCursor;
        const fullTextSearch = requestParameters.fullTextSearch;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtFrom !== undefined && createdAtFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtFrom, 'createdAtFrom');
        }
        if (createdAtTo !== undefined && createdAtTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAtTo, 'createdAtTo');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortType !== undefined && sortType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortType, 'sortType');
        }
        if (ids !== undefined && ids !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ids, 'ids');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (cursor !== undefined && cursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cursor, 'cursor');
        }
        if (includeCursor !== undefined && includeCursor !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeCursor, 'includeCursor');
        }
        if (fullTextSearch !== undefined && fullTextSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fullTextSearch, 'fullTextSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResponseModel & object>(`${this.configuration.basePath}/user/api/top-match`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchControllerReject(requestParameters: MatchControllerRejectRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseModel>;
    public matchControllerReject(requestParameters: MatchControllerRejectRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseModel>>;
    public matchControllerReject(requestParameters: MatchControllerRejectRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseModel>>;
    public matchControllerReject(requestParameters: MatchControllerRejectRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling matchControllerReject.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<ResponseModel>(`${this.configuration.basePath}/user/api/match/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
