import { LanguageState } from './../../language/store/language.state'
import { tap } from 'rxjs/operators'
import { FCMService } from '../../../../data-access/generated/activity'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import {
  StateModel,
  INITIAL_STATE,
  STATE_NAME,
  LikedPostPayload,
  SharedPostPayload,
  FollowedPayload,
  CommentedPostPayload,
  FirebaseActions
} from './state.model'
import {
  AddToken,
  ReceiveFirebaseMessage,
  ReceiveLikedPost,
  ReceiveSharedPost,
  ReceiveFollowed,
  ReceiveCommentedPost
} from './actions'
import { generateUUID } from '../util/uuid.generator'
import { Injectable } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'

@State<StateModel>({
  name: STATE_NAME,
  defaults: INITIAL_STATE
})
@Injectable()
export class FirebaseState {
  @Selector()
  static browserToken({ browserToken }: StateModel) {
    return browserToken
  }

  constructor(
    private fcmService: FCMService,
    private store: Store,
    private nzMessageService: NzMessageService
  ) {}

  @Action(AddToken)
  AddToken({ patchState }: StateContext<StateModel>, { browserToken }: AddToken) {
    const lang = this.store.selectSnapshot(LanguageState.getLanguage)
    return this.fcmService
      .fCMControllerAddToken({
        acceptLanguage: lang,
        addTokenDTO: { token: browserToken, uuid: generateUUID() }
      })
      .pipe(tap(() => patchState({ browserToken })))
  }

  @Action(ReceiveFirebaseMessage)
  ReceiveFirebaseMessage(
    { dispatch }: StateContext<StateModel>,
    { response: payload }: ReceiveFirebaseMessage
  ) {
    this.nzMessageService.info(payload.notification.title)

    switch (payload.data.action) {
      case FirebaseActions.LikedPost:
        return dispatch(
          new ReceiveLikedPost({
            payload: payload.data.payload as LikedPostPayload,
            notification: payload.notification
          })
        )
      case FirebaseActions.CommentedPost:
        return dispatch(
          new ReceiveCommentedPost({
            payload: payload.data.payload as CommentedPostPayload,
            notification: payload.notification
          })
        )
      case FirebaseActions.SharedPost:
        return dispatch(
          new ReceiveSharedPost({
            payload: payload.data.payload as SharedPostPayload,
            notification: payload.notification
          })
        )
      case FirebaseActions.Followed:
        return dispatch(
          new ReceiveFollowed({
            payload: payload.data.payload as FollowedPayload,
            notification: payload.notification
          })
        )

      default:
        break
    }
  }
}
