import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { TruncatePipeModule, TuiMapperPipeModule } from 'src/app/util/pipes'
import { CommentsModule } from '../comments/comments.module'
import { ImageMusicPlayerModule } from '../image-music-player/image-music-player.module'
import { QuizModule } from '../quiz/quiz.module'
import { VideoPlayerModule } from '../video-player'
import { PostDetailDialogService } from './post-detail-dialog.service'
import { PostDetailComponent } from './post-detail.component'

const nzModules = [NzButtonModule, NzSpinModule, NzIconModule, NzSkeletonModule, NzModalModule]

@NgModule({
  declarations: [PostDetailComponent],
  imports: [
    CommonModule,
    TranslateModule,
    VideoPlayerModule,
    QuizModule,
    CommentsModule,
    TruncatePipeModule,
    TuiMapperPipeModule,
    nzModules,
    ImageMusicPlayerModule
  ],
  exports: [PostDetailComponent]
})
export class PostDetailModule {
  static forRoot(): ModuleWithProviders<PostDetailModule> {
    return {
      ngModule: PostDetailModule,
      providers: [PostDetailDialogService]
    }
  }
}
