import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs'

export function errorCatchThrow(patchState: ({ errorMessage: string }) => void) {
  return catchError((errorMessage: Error) => {
    console.warn(errorMessage)

    patchState({ errorMessage: errorMessage.message })
    return throwError(errorMessage)
  })
}
