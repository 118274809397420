import produce from 'immer';
import { ActionPostResponse, PostControllerGeDetailsLikeChallengeRequestParams, PostControllerUpdatePostRequestParams, PostResponse, PostService, PostUserResponse } from 'src/app/data-access/generated/challenge';
import { AuthDialogType } from 'src/app/features/auth/util/auth-dialog/auth-dialog.types';
import { Clipboard } from '@angular/cdk/clipboard';
import { CONFIRM_DIALOG_MODAL_CONFIG } from 'src/app/ui/confirm-dialog/confirm-dialog.config';
import { ConfirmDialogComponent } from 'src/app/ui/confirm-dialog/confirm-dialog.component';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { FollowButtonModel } from 'src/app/ui/follow-button/util';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LikedUsersDialogComponent } from '../liked-users-dialog';
import { MyResponseModel } from 'src/app/data-access/missing.model';
import { NEVER, Observable, Subject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PageViewEvent, SegmentEvent } from 'src/app/util/services/constants/segment-event.constant';
import { PostItemState } from './post-item-state.model';
import { RelationService } from 'src/app/data-access/generated/relation';
import { ReportDialogService } from 'src/app/ui/report-dialog/report-dialog.service';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { ScoreDialogComponent } from 'src/app/ui/score-dialog/score-dialog.component';
import { SegmentTrackingService } from 'src/app/util/services/segment.service';
import { ShareDialogComponent } from 'src/app/ui/share-dialog';
import { ShareService } from 'src/app/data-access/generated/share';
import { switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root';
import { WritableDraft } from 'immer/dist/internal';

type UpdatePostParams = PostControllerUpdatePostRequestParams['updatePostDTO']
// Things doesn't mutate the component store should stay in parent component for prevent multiplying stuff
// Example: reportUser, reportPost should emit out to the parent component,
//          handle inside will multiplying number of unused instance of ReportDialogService

@Injectable()
export class PostItemComponentStore extends RxState<PostItemState> {
  clickFollow = new EventEmitter<PostUserResponse.RelationEnum>()
  clickBlockUser = new EventEmitter<void>()
  clickRemovePost = new EventEmitter<void>()
  clickExpandInDialog = new EventEmitter<PostResponse>()

  editingForm = this.fb.group({
    description: ['', Validators.required],
    commentable: [true],
    downloadable: [true]
  })

  private likeAction = new Subject<void>()
  private followAction = new Subject<FollowButtonModel>()
  private downloadAction = new Subject<void>()
  private blockUserAction = new Subject<void>()
  private updatePostAction = new Subject<void>()
  private removePostAction = new Subject<void>()
  private shareAction = new Subject<void>()
  private viewScoreAction = new Subject<void>()

  private likedUsersCreator = (
    requestParameters: PostControllerGeDetailsLikeChallengeRequestParams
  ) => this.postService.postControllerGeDetailsLikeChallenge(requestParameters)

  constructor(
    private postService: PostService,
    private relationService: RelationService,
    private reportDialogService: ReportDialogService,
    private clipboardService: Clipboard,
    private nzMessageService: NzMessageService,
    private nzModalService: NzModalService,
    private shareService: ShareService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private segmentTrackingService: SegmentTrackingService
  ) {
    super()
    this.likeEffect()
    this.followEffect()
    this.downloadEffect()
    this.blockUserEffect()
    this.updatePostEffect()
    this.removePostEffect()
    this.shareEffect()
    this.viewScoreEffect()
  }

  set post(post: PostResponse) {
    this.set({ post })
    this.editingForm.patchValue({
      description: post.description,
      commentable: post.commentable,
      downloadable: post.downloadable
    })
  }

  toggleLike() {
    const post = this.get('post')
    this.segmentTrackingService.track(SegmentEvent.PostAction, {
      postId: post._id,
      owner: post.userId,
      challengeId: post.challengeId,
      topicId: post.topicId,
      type: 'LikePost'
    })
    this.likeAction.next()
  }

  followUser(followButtonModel: FollowButtonModel) {
    this.segmentTrackingService.track(SegmentEvent.FollowUser, {
      page: PageViewEvent.Post,
      userId: followButtonModel.friendId,
      relationBefore: followButtonModel.relation
    })
    this.followAction.next(followButtonModel)
  }

  copyUrl() {
    const post = this.get('post')
    if (this.clipboardService.copy(`${location.host}/post/${post._id}`))
      this.segmentTrackingService.track(SegmentEvent.PostAction, {
        postId: post._id,
        owner: post.userId,
        challengeId: post.challengeId,
        topicId: post.topicId,
        type: 'CopyPostURL'
      })
    this.nzMessageService.success('Sao chép liên kết thành công!')
  }

  openLoginDialog() {
    const post = this.get('post')
    this.segmentTrackingService.track(SegmentEvent.RequestLogin, {
      postId: post._id,
      owner: post.userId,
      challengeId: post.challengeId,
      topicId: post.topicId,
      request: PageViewEvent.Post
    })
    this.router.navigate([], { queryParams: { dialog: AuthDialogType.LOGIN } })
  }

  downloadMedia() {
    this.segmentTrackingService.track(SegmentEvent.PostAction, {
      postId: this.post._id,
      owner: this.post.userId,
      challengeId: this.post.challengeId,
      topicId: this.post.topicId,
      type: 'DownLoadPostMedia'
    })
    this.downloadAction.next()
  }

  blockUser() {
    this.blockUserAction.next()
  }

  reportPost() {
    const postId = this.get('post')._id
    this.segmentTrackingService.track(SegmentEvent.PostAction, {
      postId: this.post._id,
      owner: this.post.userId,
      challengeId: this.post.challengeId,
      topicId: this.post.topicId,
      type: 'ReportPost'
    })
    this.reportDialogService.reportPost(postId)
  }

  setEditingMode(enable: boolean) {
    this.set({ editing: enable })
    if (enable) return
    const post = this.get('post')
    this.editingForm.patchValue({
      description: post.description,
      commentable: post.commentable,
      downloadable: post.downloadable
    })
  }

  updatePost() {
    const post = this.get('post')
    this.segmentTrackingService.track(SegmentEvent.PostAction, {
      postId: post._id,
      owner: post.userId,
      challengeId: post.challengeId,
      topicId: post.topicId,
      type: 'UpdatePost'
    })
    this.updatePostAction.next()
  }

  removePost() {
    this.removePostAction.next()
  }

  openPostDetailDialog() {
    const post = this.get('post')
    this.clickExpandInDialog.emit(post)
  }

  share() {
    const post = this.get('post')
    this.segmentTrackingService.track(SegmentEvent.PostAction, {
      postId: post._id,
      owner: post.userId,
      challengeId: post.challengeId,
      topicId: post.topicId,
      type: 'SharePost'
    })
    this.shareAction.next()
  }

  openLikesDialog() {
    this.nzModalService.create({
      nzContent: LikedUsersDialogComponent,
      nzComponentParams: { postId: this.get('post')._id },
      ...TRYME_MODAL_CONFIG
    })
    // this.nzModalService.create<ListDialogComponent<LikeResponse>, void>({
    //   nzContent: ListDialogComponent,
    //   nzComponentParams: {
    //     loadMoreDataCreator: this.likedUsersCreator,
    //     title: 'Tin nhắn mới',
    //     additionalParams: { userId: me._id },
    //     itemTemplate: this.userItemTemplate,
    //     loadingTemplate: this.userLoadingTemplate,
    //     searchable: true,
    //     placeholder: 'Tìm kiếm người dùng'
    //   },
    //   ...TRYME_MODAL_CONFIG,
    //   nzCentered: true
    // })
  }

  openScoreDialog() {
    const post = this.get('post')
    this.segmentTrackingService.track(SegmentEvent.PostAction, {
      destination: PageViewEvent.Post,
      owner: post.userId,
      challengeId: post.challengeId,
      topic: post.topicId,
      postId: post._id,
      type: 'ViewPostScore'
    })
    this.viewScoreAction.next()
  }

  private likeEffect() {
    const likeEffect$ = this.likeAction.pipe(
      withLatestFrom(this.select('post'), (_, post) => post),
      tap(post =>
        this.set({
          post: produce(post, toggleWasLiked)
        })
      ),
      switchMap(({ _id: postId, wasLiked }) => {
        if (wasLiked) return this.postService.postControllerUnlike({ id: postId })
        return this.postService.postControllerLike({ id: postId })
      }),
      this.patchPostFromAction()
    )
    this.hold(likeEffect$)
  }

  private followEffect() {
    const followEffect$ = this.followAction.pipe(
      tap(followButtonModel => {
        this.set({
          post: produce(this.get('post'), draft => {
            draft.user.relation = followButtonModel.relation
          })
        })
        this.clickFollow.emit(followButtonModel.relation)
      })
    )
    this.hold(followEffect$)
  }

  private downloadEffect() {
    const downloadEffect$ = this.downloadAction.pipe(
      switchMap(() => {
        const { downloadable, medias, _id, user } = this.get('post')

        if (downloadable) {
          const mediaUrl = medias[0].url
          const fileType = mediaUrl.slice(mediaUrl.lastIndexOf('.'))
          const fileName = (user.username ?? '') + _id
          const { messageId } = this.nzMessageService.loading('Đang chuẩn bị tải xuống...', {
            nzDuration: 9999999999
          })
          return this.http.get(mediaUrl, { responseType: 'blob' }).pipe(
            tap(blob => {
              const aTag = this.document.createElement('a')
              aTag.setAttribute('href', window.URL.createObjectURL(blob))
              aTag.setAttribute('download', fileName + fileType)
              aTag.setAttribute('target', '_blank')
              aTag.click()
              this.nzMessageService.remove(messageId)
            })
          )
        }
        this.nzMessageService.warning('Tải xuống thất bại. Bài viết không cho phép tải xuống!')
        return NEVER
      })
    )
    this.hold(downloadEffect$)
  }

  private blockUserEffect() {
    const blockUserEffect$ = this.blockUserAction.pipe(
      tap(() => {
        const { _id, username } = this.get('post').user
        this.nzModalService.create({
          nzContent: ConfirmDialogComponent,
          nzComponentParams: {
            title: 'Chặn người dùng',
            message: `Bạn có chắc chắn muốn chặn <code>@${username}</code>? <br/>Bạn sẽ không thể truy cập thông tin, nhận thông báo về bài đăng, tin nhắn hoặc thử thách từ người này.`
          },
          ...CONFIRM_DIALOG_MODAL_CONFIG,
          nzOkText: 'Chặn',
          nzOnOk: () => {
            this.relationService.relationControllerBlockUser({ userId: _id }).subscribe(() => {
              this.nzMessageService.success('Chặn người dùng thành công!')
              this.clickBlockUser.emit()
            })
          }
        })
      })
    )
    this.hold(blockUserEffect$)
  }

  private updatePostEffect() {
    const updatePostEffect$ = this.updatePostAction.pipe(
      withLatestFrom(this.editingForm.valueChanges as Observable<UpdatePostParams>),
      switchMap(([, params]) =>
        this.postService
          .postControllerUpdatePost({
            id: this.get('post')._id,
            updatePostDTO: { ...params, description: params.description }
          })
          .pipe(
            tap(() => {
              this.nzMessageService.success('Cập nhật bài viết thành công!')
              this.set(({ post: prePost }) => ({ editing: false, post: { ...prePost, ...params } }))
            })
          )
      )
    )
    this.hold(updatePostEffect$)
  }

  private removePostEffect() {
    const removePostEffect$ = this.removePostAction.pipe(
      tap(() =>
        this.nzModalService.create({
          nzContent: ConfirmDialogComponent,
          nzComponentParams: {
            title: 'Xóa bài viết',
            message: `Bạn có chắc chắn muốn xóa bài viết này? <br/>Bạn sẽ không thể khôi phục lại bài viết này sau khi xóa.`
          },
          ...CONFIRM_DIALOG_MODAL_CONFIG,
          nzOkText: 'Xóa',
          nzOnOk: () => {
            this.postService
              .postControllerDeletePost({ id: this.get('post')._id })
              .subscribe(() => {
                this.nzMessageService.success('Xoá bài viết thành công!')
                this.clickRemovePost.emit()
              })
          }
        })
      )
    )
    this.hold(removePostEffect$)
  }

  private shareEffect() {
    const shareEffect$ = this.shareAction.pipe(
      switchMap(() =>
        this.shareService.shareControllerAdd({
          shareDTO: { id: this.get('post')._id, type: 'post' }
        })
      ),
      switchMap(response => {
        const code = response.data as unknown as string
        const modalRef = this.nzModalService.create({
          nzContent: ShareDialogComponent,
          nzComponentParams: {
            title: 'Chia sẻ bài viết',
            link: `${environment.share2}/${code}`
          },
          ...TRYME_MODAL_CONFIG
        })
        const component = modalRef.getContentComponent() as ShareDialogComponent
        return component.copy.pipe(
          switchMap(() => this.postService.postControllerShare({ id: this.get('post')._id })),
          this.patchPostFromAction(),
          takeUntil(modalRef.afterClose)
        )
      })
    )
    this.hold(shareEffect$)
  }

  private viewScoreEffect() {
    const viewScoreEffect$ = this.viewScoreAction.pipe(
      tap(() => {
        const { giftScore, giftRank, score } = this.get('post')
        this.nzModalService.create({
          nzContent: ScoreDialogComponent,
          nzComponentParams: { giftScore, giftRank, score },
          ...TRYME_MODAL_CONFIG
        })
      })
    )
    this.hold(viewScoreEffect$)
  }

  private patchPostFromAction() {
    return tap((response: MyResponseModel<ActionPostResponse>) =>
      this.set(preState => ({
        post: produce(preState.post, post => ({ ...post, ...response.data }))
      }))
    )
  }
}

function toggleWasLiked(draft: WritableDraft<PostResponse>) {
  if (draft.wasLiked) draft.liked -= 1
  else draft.liked += 1
  draft.wasLiked = !draft.wasLiked
}
