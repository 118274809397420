import { Component, ChangeDetectionStrategy, Input, Output } from '@angular/core'
import { RxState } from '@rx-angular/state'
import { delay, filter } from 'rxjs/operators'
import { Clipboard } from '@angular/cdk/clipboard'

@Component({
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ShareDialogComponent {
  successful$ = this.state.select('successful')
  @Input() title: string
  @Input() link: string
  @Output() copy = this.successful$.pipe(filter(value => value))

  constructor(private state: RxState<{ successful: boolean }>, private clipboard: Clipboard) {
    this.state.hold(
      this.successful$.pipe(
        filter(successful => successful),
        delay(500)
      ),
      () => this.state.set({ successful: false })
    )
  }

  onClickInput() {
    this.clipboard.copy(this.link)
    this.state.set({ successful: true })
  }
}
