export const STATE_NAME = 'TryMe_Firebase'
export const INITIAL_STATE: StateModel = {}
export interface StateModel {
  browserToken?: string
}

export const enum FirebaseActions {
  LikedPost = 'LikedPost',
  CommentedPost = 'CommentedPost',
  SharedPost = 'SharedPost',
  Followed = 'Followed',
  NEW_MESSAGE = 'NEW_MESSAGE'
}

export interface FirebaseResponse<T> {
  data: {
    action: keyof typeof FirebaseActions
    payload: T // string // {"conversationId":"613f6d8831daa125f413aff8","messageId":"613fab1e9e076a61830ad70f","userId":"613f6c1cdf23c85166e60691","messageContent":"ưdh"}
  }
  fcmMessageId?: string
  from: string
  notification?: {
    body?: string
    image?: string
    title?: string
    click_action?: string
  }
}


export interface FollowedResponse {
  payload: FollowedPayload // string // {"userId":"613f6c1cdf23c85166e60691","activityId":"613faaaf992cf86320a7c861"}
  notification?: {
    click_action?: string
    title?: string
  }
}
export interface FollowedPayload {
  userId: string
  activityId: string
}

export interface SharedPostResponse {
  payload: SharedPostPayload // string // {"postId":"613f008e0ed2cd0d7c42935e","activityId":"613faa49992cf86320a7c85d"}
  notification?: {
    title?: string
    body?: string
  }
}
export interface SharedPostPayload {
  postId: string
  activityId: string
}
export interface CommentedPostResponse {
  payload: CommentedPostPayload // string // {"postId":"613f008e0ed2cd0d7c42935e","activityId":"613faa3f992cf86320a7c85a","commentId":"613faa3f484147069ef0c272","commentContent":"abc"}
  notification?: {
    title?: string
    body?: string
    click_action?: string
    image?: string
  }
}
export interface CommentedPostPayload {
  postId: string
  activityId: string
  commentId: string
  commentContent: string
}
export interface LikedPostResponse {
  payload: LikedPostPayload // string // {"postId":"613f008e0ed2cd0d7c42935e","activityId":"613faa32992cf86320a7c857"}
  notification?: {
    title?: string
    body?: string
    click_action?: string
    image?: string
  }
}

export interface LikedPostPayload {
  postId: string
  activityId: string
}
