/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerResponse } from './answer-response';


export interface QuestionResponseModel { 
    _id: string;
    type: QuestionResponseModel.TypeEnum;
    answers?: Array<AnswerResponse>;
    question: string;
    correct: boolean;
}
export namespace QuestionResponseModel {
    export type TypeEnum = 'choice' | 'freeText' | 'noAnswer';
    export const TypeEnum = {
        Choice: 'choice' as TypeEnum,
        FreeText: 'freeText' as TypeEnum,
        NoAnswer: 'noAnswer' as TypeEnum
    };
}


