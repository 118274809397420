<header>
  <h2 class="text-xl leading-none mb-0">{{ 'notification.common' | translate }}</h2>
  <more-actions-button
    [actions]="actions"
    (clickAction)="onClickAction($event)"
  ></more-actions-button>
</header>
<ng-container *ngIf="notifications; else loadingTemplate">
  <ng-container *ngIf="notifications.length; else emptyNotifications">
    <div
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="100"
      [scrollWindow]="false"
      (scrolled)="loadMore.emit()"
      class="max-w-xs max-h-96 overflow-auto mb-3"
    >
      <app-notification-item
        *ngFor="let notification of notifications"
        [notification]="notification"
        (click)="readOne.emit(notification)"
        class="mt-1 p-3"
      ></app-notification-item>
    </div>
  </ng-container>
</ng-container>
<ng-template #emptyNotifications>
  <div class="w-100 flex flex-col justify-center items-center py-6">
    <img src="assets/images/empty-notification.png" class="w-52 mb-2" />
    <span>{{ 'notification.empty' | translate }}</span>
  </div>
</ng-template>
<ng-template #loadingTemplate>
  <div nz-row class="item">
    <nz-skeleton
      [nzAvatar]="true"
      [nzActive]="true"
      [nzTitle]="false"
      [nzParagraph]="{ rows: 2 }"
      *ngFor="let i of [1, 2, 3, 4, 5]"
    ></nz-skeleton>
  </div>
</ng-template>
