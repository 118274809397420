import produce from 'immer';
import { CommentStatisticResponse, PostControllerGetStatisticRequestParams, PostService } from 'src/app/data-access/generated/challenge';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { MyPagingModel, MyResponseModel } from 'src/app/data-access/missing.model';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';

export class PostQuizStatisticState {
  statistics: MyPagingModel<CommentStatisticResponse> = { data: [], page: 1, limit: 20, total: 0, pages: 0 };
  loading = false;
}

@Injectable()
export class PostQuizStatisticStore extends RxState<PostQuizStatisticState> {
  constructor(private readonly postService: PostService) {
    super()
    this.loadStatisticsEffect()
  }

  private loadStatisticsAction = new Subject<{
    params: PostControllerGetStatisticRequestParams,
    willLoadMore: boolean
  }>()

  private loadStatisticsEffect() {
    const loadStatisticsEffect$ = this.loadStatisticsAction.pipe(
      switchMap(({ params, willLoadMore }) => {
        this.set({ loading: true })
        const state = this.get()
        const processedParams =
          willLoadMore && state.statistics ? { ...params, page: state.statistics.page + 1 } : params

        return this.postService.postControllerGetStatistic(processedParams).pipe(
          map((response: MyResponseModel<MyPagingModel<CommentStatisticResponse>>) => {
            this.set({ loading: false })
            const processedComments = willLoadMore
              ? produce(state.statistics, draft => {
                draft.page = response.data.page
                draft.data = state.statistics.data.concat(response.data.data)
              })
              : response.data
            return processedComments
          })
        )
      })
    )
    this.connect('statistics', loadStatisticsEffect$)
  }

  loadStatistics(params: PostControllerGetStatisticRequestParams, willLoadMore: boolean) {
    this.loadStatisticsAction.next({ params, willLoadMore });
  }
}
