import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { State, Action, Selector, StateContext, NgxsOnInit } from '@ngxs/store'
import { INITIAL_STATE, LanguageStateModel, LANGUAGE_STATE_NAME } from './language-state.model'
import { LoadLanguage } from './language.actions'

@State<LanguageStateModel>({
  name: LANGUAGE_STATE_NAME,
  defaults: INITIAL_STATE
})
@Injectable()
export class LanguageState implements NgxsOnInit {
  @Selector()
  static getLanguage({ language }: LanguageStateModel) {
    return language
  }

  constructor(private translate: TranslateService) {}

  ngxsOnInit({ dispatch }: StateContext<LanguageStateModel>) {
    dispatch(new LoadLanguage('vi'))
  }

  @Action(LoadLanguage)
  loadLanguage(
    { patchState, getState }: StateContext<LanguageStateModel>,
    { language }: LoadLanguage
  ) {
    if (getState().language === language) return
    this.translate.use(language)
    patchState({ language })
  }
}
