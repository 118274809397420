<header class="success-message" *ngIf="waitingLoading$ | async">
  Xác thực tài khoản thành công. Đang đăng nhập...
</header>
<h1 class="leading-none mb-4">Xác nhận OTP</h1>

<ng-container *ngLet="interval$ | async as tick">
  <section class="text-gray-500 text-base leading-6 mb-8">
    <p class="mb-0">Mã OTP đã được gửi đến email của bạn.</p>
    <p class="mb-0">
      Mã sẽ hết hạn sau:
      <span class="text-tryme-primary">
        {{ tick | tuiMapper: countDown:THREE_MINS }}
      </span>
    </p>
  </section>
  <form nz-form [formGroup]="form" (ngSubmit)="onSubmit()">
    <nz-form-item formArrayName="otps">
      <nz-form-control *ngFor="let control of otpFormArray.controls; index as i">
        <input nz-input [formControl]="control" maxlength="1" min="0" max="9" nzSize="large"  />
      </nz-form-control>
    </nz-form-item>
    <p class="mb-20 text-gray-500">
      Bạn không nhận được OTP?
      <button
        nz-button
        type="button"
        nzType="link"
        [disabled]="tick < THREE_MINS"
        (click)="resendOtp()"
        class="resend"
      >
        Gửi lại mã ({{ tick | tuiMapper: countDown:THREE_MINS }})
      </button>
    </p>
    <button
      nz-button
      nzSize="large"
      nzType="primary"
      nzBlock
      [disabled]="loading$ | async"
      class="rounded-lg !mb-6"
    >
      <strong class="text-base">Xác thực OTP</strong>
    </button>
  </form>
</ng-container>
