<h1 class="leading-none mb-4">{{ title }}</h1>
<p class="text-gray-500 text-base leading-6 mb-8">
  Chia sẻ tới bạn bè thông qua đường dẫn phía dưới nhé!
</p>

<nz-input-group [nzSuffix]="suffixEmail" nzSize="large" (click)="onClickInput()">
  <input type="text" nz-input [value]="link" />
</nz-input-group>

<ng-template #suffixEmail>
  <i
    nz-icon
    class="!text-tryme-green"
    nzType="icons/outline:check-all"
    *ngIf="successful$ | async; else copyIconTemplate"
  ></i>
  <ng-template #copyIconTemplate>
    <i nz-icon nzType="icons/outline:copy" class="cursor-pointer"></i>
  </ng-template>
</ng-template>
