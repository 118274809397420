import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { NzMessageService } from 'ng-zorro-antd/message'
import { Store } from '@ngxs/store'
import { AuthState, Logout } from 'src/app/features/auth/store'

@Injectable({ providedIn: 'root' })
export class UnauthorizeInterceptor implements HttpInterceptor {
  messageId: string

  constructor(private nzMessageService: NzMessageService, private store: Store) {}

  intercept(
    request: HttpRequest<Record<string, string>>,
    next: HttpHandler
  ): Observable<HttpEvent<Record<string, string>>> {
    return next.handle(request).pipe(
      catchError((error: HttpResponse<Record<string, string>>) => {
        const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn)
        if (error.status === 401 && isLoggedIn) {
          this.store.dispatch(new Logout(false))
          if (!this.messageId) {
            const messageRef = this.nzMessageService.error(
              'Phiên đăng nhập hết hạn. Bạn dành ít phút đăng nhập lại nhé!'
            )
            this.messageId = messageRef.messageId
            messageRef.onClose.subscribe(() => {
              this.messageId = null
            })
          }
        }
        return throwError(error)
      })
    )
  }
}
