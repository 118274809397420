import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ShareDialogComponent } from './share-dialog.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { ClipboardModule } from '@angular/cdk/clipboard'

const nzModules = [NzIconModule, NzInputModule]

@NgModule({
  declarations: [ShareDialogComponent],
  imports: [CommonModule, ClipboardModule, nzModules],
  exports: [ShareDialogComponent]
})
export class ShareDialogModule {}
