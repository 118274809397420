import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig
} from 'angularx-social-login'
import { environment } from '../../../../environments/environment'

export const SOCIAL_AUTH_PROVIDER = {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.google.clientID)
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.facebook.clientID)
      }
    ]
  } as SocialAuthServiceConfig
}
