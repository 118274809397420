import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AppService } from './api/app.service';
import { AuthService } from './api/auth.service';
import { BankService } from './api/bank.service';
import { BannerService } from './api/banner.service';
import { CountryCodeService } from './api/country-code.service';
import { FriendService } from './api/friend.service';
import { PasswordService } from './api/password.service';
import { ProfileService } from './api/profile.service';
import { ReasonService } from './api/reason.service';
import { UserVerifyService } from './api/user-verify.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class IamApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<IamApiModule> {
        return {
            ngModule: IamApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: IamApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('IamApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
