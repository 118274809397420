/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateAnswerDTO } from './create-answer-dto';


export interface CreateQuestionDTO { 
    type: CreateQuestionDTO.TypeEnum;
    answers?: Array<CreateAnswerDTO>;
    question: string;
}
export namespace CreateQuestionDTO {
    export type TypeEnum = 'choice' | 'freeText' | 'noAnswer';
    export const TypeEnum = {
        Choice: 'choice' as TypeEnum,
        FreeText: 'freeText' as TypeEnum,
        NoAnswer: 'noAnswer' as TypeEnum
    };
}


