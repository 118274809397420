import { registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import localeViExtra from '@angular/common/locales/extra/vi'
import localeVi from '@angular/common/locales/vi'
import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SocialLoginModule } from 'angularx-social-login'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthDialogModule } from './features/auth/util/auth-dialog'
import { SOCIAL_AUTH_PROVIDER } from './features/auth/util/social-auth.provider'
import { FirebaseRootModule } from './features/base/firebase-root/firebase-root.module'
import { LanguageModule } from './features/base/language'
import { NzRootModule } from './features/base/nz-root/nz-root.module'
import { OpenApiRootModule } from './features/base/openapi-root/openapi-root.module'
import { StateManagementModule } from './features/base/state-management/state-management.module'
import { ChooseCreateTypeDialogModule } from './features/challenge/ui/choose-create-type-dialog/choose-create-type-dialog.module'
import { PostDetailModule } from './features/post/ui/post-detail'
import { ReportDialogModule } from './ui/report-dialog'
import { GlobalErrorHandler } from './util/global-error-handler'
import { UnauthorizeInterceptor } from './util/interceptors'
import { LanguageInterceptor } from './util/interceptors/language.interceptor'

registerLocaleData(localeVi, 'vi-VN', localeViExtra)

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    LanguageModule,
    NzRootModule,
    OpenApiRootModule,
    FirebaseRootModule,
    StateManagementModule,
    ReportDialogModule,
    AuthDialogModule.forRoot(),
    PostDetailModule.forRoot(), // Register open post from route
    ChooseCreateTypeDialogModule.forRoot() // Register choose create challenge type
  ],
  bootstrap: [AppComponent],
  providers: [
    SOCIAL_AUTH_PROVIDER,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }
  ]
})
export class AppModule {}
