import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-quiz-statistic-dialog',
  templateUrl: './quiz-statistic-dialog.component.html',
  styleUrls: ['./quiz-statistic-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizStatisticDialogComponent {
  @Input() postId: string;
  @Input() joined = 1

  constructor(public nzModalRef: NzModalRef) {}
}
