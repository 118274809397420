import { ActionItem } from 'src/app/ui/more-actions-button';
import { ActivityModelResponse } from 'src/app/data-access/generated/activity';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notify',
  templateUrl: 'notifications.component.html',
  styleUrls: ['./notifications.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent {
  @Output() readAll = new EventEmitter<void>()
  @Output() readOne = new EventEmitter<ActivityModelResponse>()
  @Output() loadMore = new EventEmitter<void>()
  @Input() isLogin = false
  @Input() notifications: ActivityModelResponse[]

  actions: Array<ActionItem> = [
    {
      id: 'readAll',
      title: this.translateService.instant('notifications.maskAsReadAll'),
      iconType: 'icons/outline:check-all'
    }
  ]

  constructor(private translateService: TranslateService) {}

  onClickAction(action: ActionItem) {
    if (action.id === 'readAll') this.readAll.emit()
  }
}
