/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BannerResponse { 
    thumbnail: string;
    action: BannerResponse.ActionEnum;
    information?: string;
}
export namespace BannerResponse {
    export type ActionEnum = 'challenge' | 'post' | 'user' | 'match' | 'link' | 'nothing';
    export const ActionEnum = {
        Challenge: 'challenge' as ActionEnum,
        Post: 'post' as ActionEnum,
        User: 'user' as ActionEnum,
        Match: 'match' as ActionEnum,
        Link: 'link' as ActionEnum,
        Nothing: 'nothing' as ActionEnum
    };
}


