import { conversationRoutes } from '../features/messages/util/conversation.routes';
import { IsLoggedInGuard } from '../features/auth/util/guards/is-logged-in.guard';
import { IsNormalUserGuard } from '../features/auth/util/guards/is-normal-user.guard';
import { Routes } from '@angular/router';

export const rootRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'h' },
  {
    path: 'error-404',
    loadChildren: () => import('../ui/error404/error404.module').then(m => m.Error404Module)
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../features/user/feature/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [IsNormalUserGuard],
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('../ui/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule)
  },
  {
    path: 'post',
    loadChildren: () => import('../features/post/feature/post/post.module').then(m => m.PostModule),
    canActivate: [IsNormalUserGuard]
  },
  {
    path: 'explore',
    loadChildren: () =>
      import('../features/explore/feature/explore.module').then(m => m.ExplorePageModule),
    data: { title: 'suggestion' },
    canActivate: [IsNormalUserGuard]
  },
  {
    path: 'suggestion',
    loadChildren: () =>
      import('../features/suggestion/suggestion.module').then(m => m.SuggestionModule),
    canActivate: [IsLoggedInGuard],
  },
  {
    path: 'messages',
    children: conversationRoutes,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('../features/account/feature/account.module').then(m => m.AccountModule),
  },
  {
    path: 'h',
    data: { title: 'home' },
    loadChildren: () => import('../features/home/feature/home.module').then(m => m.HomeModule),
    canActivate: [IsNormalUserGuard],
    canActivateChild: [IsNormalUserGuard]
  },
  {
    path: 'challenge/create-question',
    loadChildren: () =>
      import(
        '../features/challenge/feature/challenge-create-question/challenge-create-question.module'
      ).then(m => m.ChallengeCreateQuestionModule),
    data: { title: 'challenge-create-question' },
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'challenge/create',
    loadChildren: () =>
      import('../features/challenge/feature/challenge-create/challenge-create.module').then(
        m => m.ChallengeCreateModule
      ),
    data: { title: 'challenge-create' },
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'challenge/join/:challengeId',
    loadChildren: () =>
      import('../features/challenge/feature/challenge-create/challenge-create.module').then(
        m => m.ChallengeCreateModule
      ),
    data: { title: 'challenge-join' },
    canActivate: [IsLoggedInGuard]
  },
  { path: '**', redirectTo: 'error-404' }
]
