/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaModel } from './media-model';


export interface JoinChallengeDTO { 
    originalId: string;
    challengeId: string;
    description: string;
    privacy: JoinChallengeDTO.PrivacyEnum;
    downloadable: boolean;
    commentable: boolean;
    matchId?: string;
    topicId?: string;
    soundId?: string;
    medias?: Array<MediaModel>;
}
export namespace JoinChallengeDTO {
    export type PrivacyEnum = 'PP_EVERYONE' | 'PP_FOLLOWER' | 'PP_ONLY_ME';
    export const PrivacyEnum = {
        EVERYONE: 'PP_EVERYONE' as PrivacyEnum,
        FOLLOWER: 'PP_FOLLOWER' as PrivacyEnum,
        ONLYME: 'PP_ONLY_ME' as PrivacyEnum
    };
}


