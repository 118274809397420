/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileChallengePost } from './profile-challenge-post';
import { GiftModel } from './gift-model';


export interface ProfileChallengeResponse { 
    readonly _id: string;
    userId: string;
    avatarUrl: string;
    description: string;
    hashtags: Array<string>;
    liked: number;
    shared: number;
    commented: number;
    score: number;
    joined: number;
    type: ProfileChallengeResponse.TypeEnum;
    gift: GiftModel;
    posts: Array<ProfileChallengePost>;
    officialPostId: string;
}
export namespace ProfileChallengeResponse {
    export type TypeEnum = 'video' | 'image' | 'quiz';
    export const TypeEnum = {
        Video: 'video' as TypeEnum,
        Image: 'image' as TypeEnum,
        Quiz: 'quiz' as TypeEnum
    };
}


