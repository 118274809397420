<ng-container *ngIf="likes$ | async as likes; else loadingTemplate">
  <h2 class="mb-8">
    {{ title }} <span *ngIf="includeTotal">({{ likes.data?.length }})</span>
  </h2>
  <ul
    class="flex flex-col overflow-auto"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    [scrollWindow]="false"
    (scrolled)="onInfiniteScroll(likes.page + 1)"
    *ngIf="likes.data?.length"
  >
    <li *ngFor="let like of likes.data" class="flex justify-between items-start">
      <app-suggest-item [relation]="like.user" class="mb-4"></app-suggest-item>

      <app-follow-button
        [friendId]="like.user?._id"
        [relation]="like.relation"
        (clickFollow)="onClickFollow($event)"
      ></app-follow-button>
    </li>
    <p class="text-center" *ngIf="loadingMore$ | async">
      <nz-spin nzSimple class="ml-4 mb-4"></nz-spin> <br />
    </p>
  </ul>
</ng-container>
<ng-template #loadingTemplate>
  <app-suggest-item *ngFor="let i of [1, 2, 3, 4]"></app-suggest-item>
</ng-template>
