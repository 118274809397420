import { Component, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
  selector: 'app-quiz-joined-count',
  templateUrl: './quiz-joined-count.component.html',
  styleUrls: ['./quiz-joined-count.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizJoinedCountComponent {
  @Input() count = 0
  @Input() avatars: string[] = []
  @Input() isBrief = false
}
