import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { ActivityModelResponse } from '../../../../data-access/generated/activity'

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {
  @Input() notification: ActivityModelResponse
}
