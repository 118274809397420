import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ImageMusicPlayerComponent } from './image-music-player.component'
import { ThrottleClickDirectiveModule } from 'src/app/util/directives/throttle-click'
import { MediaPlayerModule } from '../media-player'
import { NzIconModule } from 'ng-zorro-antd/icon'

const nzModules = [NzIconModule]

@NgModule({
  declarations: [ImageMusicPlayerComponent],
  imports: [CommonModule, ThrottleClickDirectiveModule, MediaPlayerModule, nzModules],
  exports: [ImageMusicPlayerComponent]
})
export class ImageMusicPlayerModule {}
