import { HttpClient } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule, TranslateModuleConfig } from '@ngx-translate/core'
import { NgxsModule } from '@ngxs/store'
import { LanguageState } from './store/language.state'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json`)
}

const translationOptions: TranslateModuleConfig = {
  defaultLanguage: 'vi',
  useDefaultLang: true,
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}

@NgModule({
  imports: [TranslateModule.forRoot(translationOptions), NgxsModule.forFeature([LanguageState])]
})
export class LanguageModule {}
