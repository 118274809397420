import { formatDate } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { Store } from '@ngxs/store'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks
} from 'date-fns'
import { interval, Observable, of } from 'rxjs'
import { map, startWith, withLatestFrom } from 'rxjs/operators'
import { LanguageCode, LanguageState } from 'src/app/features/base/language/store'

interface SingularPlural {
  singular: string
  plural?: string
}
interface TimeagoTranslation {
  week: SingularPlural
  day: SingularPlural
  yesterday: string
  hour: SingularPlural
  minute: SingularPlural
  now: string
}

const languageMapper: Record<LanguageCode, TimeagoTranslation> = {
  en: {
    week: { singular: 'week', plural: 'weeks' },
    day: { singular: 'day', plural: 'days' },
    yesterday: 'yesterday',
    hour: { singular: 'hour', plural: 'hours' },
    minute: { singular: 'minute', plural: 'minutes' },
    now: 'just now'
  },
  vi: {
    week: { singular: 'tuần', plural: 'tuần' },
    day: { singular: 'ngày', plural: 'ngày' },
    yesterday: 'Hôm qua',
    hour: { singular: 'giờ', plural: 'giờ' },
    minute: { singular: 'phút', plural: 'phút' },
    now: 'Bây giờ'
  }
}

@Pipe({ name: 'timeago' })
export class TimeagoPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: string | number | Date): Observable<string> {
    const date = new Date(typeof value === 'string' ? Number(value) : value)
    const now = new Date()
    const diffInMonth = differenceInMonths(now, date)
    if (diffInMonth > 0) return of(formatDate(date, 'dd/MM/yyyy', 'vi'))
    return interval(60 * 1000).pipe(
      startWith(0),
      withLatestFrom(this.store.select(LanguageState.getLanguage)),
      map(([, language]) => {
        const now = new Date()
        const diffInWeek = differenceInWeeks(now, date)
        if (diffInWeek > 0)
          return diffInWeek === 1
            ? `1 ${languageMapper[language].week.singular}`
            : `${diffInWeek} ${languageMapper[language].week.plural}`
        const diffInDay = differenceInDays(now, date)
        if (diffInDay > 0)
          return diffInDay === 1
            ? languageMapper[language].yesterday
            : `${diffInDay} ${languageMapper[language].day.plural}`
        const diffInHour = differenceInHours(now, date)
        if (diffInHour > 0)
          return diffInHour === 1
            ? `1 ${languageMapper[language].hour.singular}`
            : `${diffInHour} ${languageMapper[language].hour.plural}`
        const diffInMinute = differenceInMinutes(now, date)
        if (diffInMinute > 0)
          return diffInMinute === 1
            ? `1 ${languageMapper[language].minute.singular}`
            : `${diffInMinute} ${languageMapper[language].minute.plural}`
        return languageMapper[language].now
      })
    )
  }
}
