import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { RxState } from '@rx-angular/state'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { EMPTY, Subject } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import {
  AuthService,
  LoginSuccessResponse,
  RegisterResponse
} from 'src/app/data-access/generated/iam'
import { LoadingState, MyResponseModel } from 'src/app/data-access/missing.model'
import { SaveLoginInfo } from '../../store'
import {
  AuthDialogResult,
  AuthDialogType,
  NzModalContentType
} from '../../util/auth-dialog/auth-dialog.types'

@Component({
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class LoginEmailComponent implements OnInit {
  loading$ = this.state.select('loading')
  form = this.fb.group({
    email: [null, [Validators.required]],
    password: [null, [Validators.required, Validators.minLength(8)]]
  })
  passwordVisible = false

  private loginAction = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private messageService: NzMessageService,
    private modalRef: NzModalRef<NzModalContentType, AuthDialogResult>,
    private store: Store,
    private state: RxState<LoadingState>,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loginEffect()
  }

  onClickLogin() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
    if (this.form.valid) {
      this.loginAction.next()
    }
  }

  onClickRegister() {
    this.modalRef.close()
    this.router.navigate([], {
      queryParams: { dialog: AuthDialogType.REGISTER },
      queryParamsHandling: 'merge'
    })
  }

  onClickForgotPassword() {
    this.modalRef.close()
    const { email = '' } = this.form.value
    this.router.navigate([], {
      queryParams: { email, dialog: AuthDialogType.FORGOT_PASSWORD },
      queryParamsHandling: 'merge'
    })
  }

  private loginEffect() {
    const loginEffect$ = this.loginAction.pipe(
      switchMap(() => {
        const { email, password } = this.form.value
        const lowercaseEmail = email.toLowerCase()
        this.state.set({ loading: true })
        return this.authService
          .authControllerLogin({ loginDTO: { email: lowercaseEmail, password } })
          .pipe(
            tap((response: MyResponseModel<LoginSuccessResponse>) => {
              if (!response.data) throw new Error(response.message)
              this.state.set({ loading: false })
              this.modalRef.close()
              this.store.dispatch(new SaveLoginInfo(response.data))
            }),
            catchError((error: Error) => {
              if (error.message === 'Tài khoản chưa kích hoạt') {
                this.messageService.error(
                  'Tài khoản chưa xác thực email. TryMe sẽ tự động gửi lại và chuyển bạn đến nhập kích hoạt tài khoản nhé!',
                  { nzDuration: 3000, nzPauseOnHover: true }
                )
                return this.authService
                  .authControllerResendRegistrationOTP({
                    loginDTO: { email: lowercaseEmail, password }
                  })
                  .pipe(
                    tap((response: MyResponseModel<RegisterResponse>) => {
                      this.state.set({ loading: false })
                      this.router.navigate([], {
                        queryParams: {
                          requestId: response.data.requestId,
                          email: lowercaseEmail,
                          from: 'register'
                        }
                      })
                      this.modalRef.close({ type: AuthDialogType.OTP_VERIFICATION })
                    }),
                    catchError((resendError: Error) => {
                      this.state.set({ loading: false })
                      this.messageService.error(resendError.message)
                      return EMPTY
                    })
                  )
              } else {
                this.state.set({ loading: false })
                this.messageService.error(error.message)
                return EMPTY
              }
            })
          )
      })
    )
    this.state.hold(loginEffect$)
  }
}
