import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { PostDetailComponentStore } from './post-detail.store';
import { PostItemComponentStore } from '../post-item/post-item.store';
import { PostResponse } from 'src/app/data-access/generated/challenge';
import { toBackgroundCss } from 'src/app/util/helpers';

@Component({
  selector: 'post-detail',
  templateUrl: 'post-detail.component.html',
  styleUrls: ['./post-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PostItemComponentStore, PostDetailComponentStore]
})
export class PostDetailComponent {
  post$ = this.postDetailComponentStore.select('post')
  loading$ = this.postDetailComponentStore.select('loading')
  muted$ = this.activatedRoute.queryParams.pipe(map(({ muted }) => muted !== 'false'))

  @Input()
  set id(id: string) {
    if (id) {
      this.postDetailComponentStore.loadPostById(id)
    }
  }
  readonly challengeTypes = PostResponse.TypeEnum

  toBackgroundCss = toBackgroundCss

  constructor(
    private postDetailComponentStore: PostDetailComponentStore,
    private postItemComponentStore: PostItemComponentStore,
    private activatedRoute: ActivatedRoute
  ) {}

  onChangePost(post: PostResponse) {
    this.postDetailComponentStore.set({ post })
    this.postDetailComponentStore.loadComments({ limit: 20, page: 1, postId: post._id }, false)
  }

  onShare() {
    this.postItemComponentStore.set({ post: this.postDetailComponentStore.get('post') })
    this.postItemComponentStore.share()
  }
}
