<ng-container
  *ngIf="{
    post: post$ | async,
    loading: loading$ | async
  } as view"
>
  <ng-container *ngIf="!view.loading; else loadingTemplate">
    <section class="media relative overflow-hidden" *ngIf="view.post; else emptyTemplate">
      <section
        *ngIf="view.post.type !== challengeTypes.Quiz"
        class="bg-blur"
        [style.background-image]="view.post.medias[0].url | tuiMapper: toBackgroundCss"
      ></section>
      <figure class="media-wrapper" [class.bg-gray-900]="view.post.type !== challengeTypes.Quiz">
        <app-image-music-player
          *ngIf="view.post.type === challengeTypes.Image"
          [src]="view.post.medias[0].url"
          [sound]="view.post.sound"
          [paused]="false"
          [muted]="muted$ | async"
          class="h-full"
        ></app-image-music-player>

        <video-player
          *ngIf="view.post.type === challengeTypes.Video"
          [videoUrl]="view.post.medias[0].url"
          class="cursor-pointer"
        ></video-player>

        <app-quiz
          *ngIf="view.post.type === challengeTypes.Quiz"
          [post]="view.post"
          [questions]="view.post.questions"
          [color]="view.post.color"
          (changePost)="onChangePost($event)"
          (onShare)="onShare()"
        ></app-quiz>
      </figure>
    </section>
  </ng-container>
  <app-comments (onShare)="onShare()"></app-comments>
</ng-container>

<ng-template #emptyTemplate>
  <div class="flex flex-col items-center pb-4" *ngIf="id">
    <img src="assets/images/searc-not-found.png" class="w-3/4 max-w-xs" />
    <strong>{{ 'search.notFound' | translate }}</strong>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <section class="w-2/3 h-full bg-gray-900 bg-opacity-70">
    <nz-skeleton-element
      nzType="image"
      [nzActive]="true"
      class="!w-full !h-full"
    ></nz-skeleton-element>
  </section>
</ng-template>
