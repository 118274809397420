<ng-content></ng-content>
<section class="handler">
  <div
    class="controls justify-between"
    [ngClass]="controlsClass"
  >
    <i
      nz-icon
      nzType="icons/fill:{{ paused ? 'play' : 'pause' }}"
      nz-tooltip
      [nzTooltipTitle]="paused ? 'Play' : 'Pause'"
      (click)="onClickPause($event)"
      *ngIf="showPausedIcon"
    ></i>
    <ng-content select="[media-info]"></ng-content>
    <i
      nz-icon
      nzType="icons/outline:volume-{{ muted ? 'off' : 'high' }}"
      nz-tooltip
      [nzTooltipTitle]="muted ? 'Turn on' : 'Mute'"
      (click)="onClickMute($event)"
    ></i>
  </div>
  <progress [value]="percent$ | async" max="100" *ngIf="showProgress$ | async"></progress>
</section>
