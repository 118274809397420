import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LikedUsersDialogComponent } from './liked-users-dialog.component'
import { TranslateModule } from '@ngx-translate/core'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { SuggestItemModule } from '../../../user/ui/suggest-item'
import { FollowButtonModule } from 'src/app/ui/follow-button/follow-button.module'

const nzModules = [NzSkeletonModule, NzSpinModule]

@NgModule({
  declarations: [LikedUsersDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    InfiniteScrollModule,
    SuggestItemModule,
    FollowButtonModule,
    nzModules
  ],
  exports: [LikedUsersDialogComponent]
})
export class LikedUsersDialogModule {}
