import { CommonModule } from '@angular/common';
import { NgLetDirectiveModule } from 'src/app/util/directives/ng-let';
import { NgModule } from '@angular/core';
import { NumberToTextPipeModule, TuiFilterPipeModule } from 'src/app/util/pipes';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { PostQuizStatisticComponent } from './post-quiz-statistic.component';
import { PostQuizStatisticStore } from './post-quiz-statistic.store';

@NgModule({
  declarations: [PostQuizStatisticComponent],
  imports: [
    CommonModule,
    NzAvatarModule,
    NumberToTextPipeModule,
    NgLetDirectiveModule,
    TuiFilterPipeModule
  ],
  providers: [PostQuizStatisticStore],
  exports: [PostQuizStatisticComponent]
})
export class PostQuizStatisticModule { }
