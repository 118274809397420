import {
  ActivityControllerMakeAsReadRequestParams,
  ActivityControllerPaginateRequestParams
} from 'src/app/data-access/generated/activity'
import { ReasonControllerPaginateRequestParams } from 'src/app/data-access/generated/iam'
import { STATE_NAME } from './state.model'

const ACTIONS = {
  LOAD_UNREAD_NOTIFICATION_COUNT: `[${STATE_NAME}] Load unread notification count`,
  MARK_AS_READ_ALL: `[${STATE_NAME}] Mark as read all`,
  MARK_AS_READ: `[${STATE_NAME}] Mark as read`,
  LOAD_NOTIFICATIONS: `[${STATE_NAME}] Load notifications`,
  LOAD_MORE_NOTIFICATIONS: `[${STATE_NAME}] Load more notifications`,
  LOAD_REASONS: `[${STATE_NAME}] Load reasons`,
  LOAD_SETTINGS: `[${STATE_NAME}] Load settings`,
}

export class LoadUnreadNotificationCount {
  static readonly type = ACTIONS.LOAD_UNREAD_NOTIFICATION_COUNT
}
export class MarkAsReadAll {
  static readonly type = ACTIONS.MARK_AS_READ_ALL
}
export class MarkAsRead {
  static readonly type = ACTIONS.MARK_AS_READ
  constructor(public readonly params: ActivityControllerMakeAsReadRequestParams) {}
}
export class LoadNotifications {
  static readonly type = ACTIONS.LOAD_NOTIFICATIONS
  constructor(
    public readonly params: ActivityControllerPaginateRequestParams = { limit: 20, page: 1 }
  ) {}
}

export class LoadMoreNotifications {
  static readonly type = ACTIONS.LOAD_MORE_NOTIFICATIONS
}

export class LoadReasons {
  static readonly type = ACTIONS.LOAD_REASONS
  constructor(public readonly params: ReasonControllerPaginateRequestParams) {}
}

export class LoadSettings {
  static readonly type = ACTIONS.LOAD_SETTINGS;
}
