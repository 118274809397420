/**
 * User API
 * User API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateUserVerifyDTO { 
    certificates: Array<string>;
    countryCode: string;
    certificateType: CreateUserVerifyDTO.CertificateTypeEnum;
}
export namespace CreateUserVerifyDTO {
    export type CertificateTypeEnum = 'PASSPORT' | 'IDENTITY_CARD' | 'CITIZEN_IDENTIFICATION';
    export const CertificateTypeEnum = {
        PASSPORT: 'PASSPORT' as CertificateTypeEnum,
        IDENTITYCARD: 'IDENTITY_CARD' as CertificateTypeEnum,
        CITIZENIDENTIFICATION: 'CITIZEN_IDENTIFICATION' as CertificateTypeEnum
    };
}


