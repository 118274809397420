import { NzButtonModule } from 'ng-zorro-antd/button'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { LoginComponent } from './login.component'
import { TranslateModule } from '@ngx-translate/core'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NgLetDirectiveModule } from 'src/app/util/directives/ng-let/ng-let.module'

const nzModules = [NzButtonModule, NzIconModule, NzSpinModule]

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, NgLetDirectiveModule, nzModules],
  exports: [LoginComponent]
})
export class LoginModule {}
