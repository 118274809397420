import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MediaPlayerComponent } from './media-player.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'

const nzModules = [NzIconModule, NzToolTipModule]

@NgModule({
  declarations: [MediaPlayerComponent],
  imports: [CommonModule, nzModules],
  exports: [MediaPlayerComponent]
})
export class MediaPlayerModule {}
