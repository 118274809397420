import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChange,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core'
import { SoundResponse } from 'src/app/data-access/generated/challenge'
import { AudioPlayerService } from 'src/app/util/services/audio-player.service'

@Component({
  selector: 'app-image-music-player',
  templateUrl: './image-music-player.component.html',
  styleUrls: ['./image-music-player.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AudioPlayerService]
})
export class ImageMusicPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() src: string
  @Input() alt?: string
  @Input() sound?: SoundResponse

  @Input() paused = true
  @Input() muted = true

  @Output() clickOpenMedia = new EventEmitter<Event>()
  @Output() clickMute = new EventEmitter<boolean>()

  constructor(private audioPlayerService: AudioPlayerService) {}

  ngOnInit() {
    if (!this.sound) return
    if (this.audioPlayerService.sound?._id !== this.sound._id) {
      this.audioPlayerService.sound = this.sound
    }
  }

  ngOnChanges(changes: Record<'sound' | 'paused' | 'muted' | 'post', SimpleChange>) {
    if (changes.muted) {
      this.handleMute(changes.muted.currentValue)
    }
    if (changes.paused) {
      if (changes.paused.currentValue) this.audioPlayerService.pause()
      else this.audioPlayerService.play(!this.muted).catch(() => void 0)
    }
  }

  ngOnDestroy() {
    this.paused = true
    this.audioPlayerService.mute()
  }

  onClickMute(current: boolean) {
    if (this.audioPlayerService.isPaused) this.audioPlayerService.play(false).catch(() => void 0)
    this.handleMute(current)
    this.clickMute.emit(current)
  }

  private handleMute(muted: boolean) {
    if (muted) this.audioPlayerService.mute()
    else this.audioPlayerService.unmute()
  }
}
