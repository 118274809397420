import { ActionItem } from 'src/app/ui/more-actions-button';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PostActionType } from '../post-item/post-item-state.model';
import { PostResponse } from 'src/app/data-access/generated/challenge';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-post-actions-button',
  templateUrl: './post-actions-button.component.html',
  styleUrls: ['./post-actions-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostActionsButtonComponent {
  @Input() isMyPost = false
  @Input() postType: PostResponse.TypeEnum;
  @Input() downloadable = true

  @Output() clickAction = new EventEmitter<ActionItem>()

  readonly postActions: Array<ActionItem> = [
    { id: PostActionType.CopyLink, title: this.translateService.instant('copyLink'), iconType: 'icons/outline:copy' },
    { id: PostActionType.Download, title: this.translateService.instant('download'), iconType: 'icons/outline:download' },
    { id: PostActionType.ReportPost, title: this.translateService.instant('report'), iconType: 'icons/outline:flag' },
    { id: PostActionType.BlockUser, title: this.translateService.instant('blockUser'), iconType: 'icons/outline:stop' }
  ]

  readonly quizPostActions: Array<ActionItem> = [
    { id: PostActionType.CopyLink, title: this.translateService.instant('copyLink'), iconType: 'icons/outline:copy' },
    { id: PostActionType.ReportPost, title: this.translateService.instant('report'), iconType: 'icons/outline:flag' },
    { id: PostActionType.BlockUser, title: this.translateService.instant('blockUser'), iconType: 'icons/outline:stop' }
  ]

  readonly myPostActions: Array<ActionItem> = [
    { id: PostActionType.EditPost, title: this.translateService.instant('edit'), iconType: 'icons/outline:edit' },
    { id: PostActionType.RemovePost, title: this.translateService.instant('delete'), iconType: 'icons/outline:trash' },
    { id: PostActionType.Download, title: this.translateService.instant('download'), iconType: 'icons/outline:download' }
  ]

  readonly myQuizPostActions: Array<ActionItem> = [
    { id: PostActionType.RemovePost, title: this.translateService.instant('delete'), iconType: 'icons/outline:trash' },
  ]

  constructor(private translateService: TranslateService) { }

  get isQuiz() {
    return this.postType === PostResponse.TypeEnum.Quiz;
  }

  get actions() {
    if (this.isQuiz) {
      return this.isMyPost ? this.myQuizPostActions : this.quizPostActions;
    }

    return this.isMyPost ? this.myPostActions : this.postActions;
  }

  download = (item: ActionItem) => {
    if (this.downloadable) return true
    return item.id !== PostActionType.Download
  }
}
