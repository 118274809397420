import { AuthState } from '../../store';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserStatus } from '../enums/user.status';

@Injectable({ providedIn: 'root' })
export class IsLoggedInGuard implements CanActivate {
  constructor(private router: Router, private _store: Store) { }

  canActivate() {
    const profile = this._store.selectSnapshot(AuthState.profile)
    if (profile) {
      switch (profile.status) {
        case UserStatus.BLOCKED:
          this.router.navigate(['account', 'blocked']);
          break;

        case UserStatus.TEMPORARY_DELETED:
          this.router.navigate(['account', 'recovery']);
          break;
      }
      return true;
    }
    this.router.navigate([], { queryParams: { dialog: 'login' } })
  }
}
