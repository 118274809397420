import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { hasValue } from 'src/app/util/custom-rxjs';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PostDetailComponent } from './post-detail.component';
import { Subject } from 'rxjs';
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root';

@Injectable()
export class PostDetailDialogService {
  postClosed$ = new Subject<string>()

  constructor(
    private nzModalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.postFromRouteEffect()
  }

  expandPostInDialog(postId: string, muted?: boolean) {
    this.router.navigate([], { queryParams: { post: postId, muted } })
  }

  private postFromRouteEffect() {
    const postId$ = this.activatedRoute.queryParams.pipe(map(({ post }) => post as string))

    postId$
      .pipe(
        distinctUntilChanged(),
        hasValue(),
        switchMap(id =>
          this.nzModalService
            .create<PostDetailComponent, void>({
              nzContent: PostDetailComponent,
              nzComponentParams: { id },
              nzClassName: 'post-modal',
              nzCloseOnNavigation: true,
              nzMaskClosable: false,
              nzKeyboard: false,
              ...TRYME_MODAL_CONFIG
            })
            .afterClose.pipe(
              tap(() => {
                this.router.navigate([], { queryParams: { post: undefined } })
                this.postClosed$.next(id)
              })
            )
        )
      )
      .subscribe()
  }
}
