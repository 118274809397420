import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuizStatisticComponent } from './quiz-statistic.component'
import { NgLetDirectiveModule } from 'src/app/util/directives/ng-let'
import { NumberToTextPipeModule, TuiFilterPipeModule } from 'src/app/util/pipes'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'

@NgModule({
  declarations: [QuizStatisticComponent],
  imports: [
    CommonModule,
    NzAvatarModule,
    NumberToTextPipeModule,
    NgLetDirectiveModule,
    TuiFilterPipeModule
  ],
  exports: [QuizStatisticComponent]
})
export class QuizStatisticModule {}
