import { AnswerDTO, ChallengeService, ColorModel, PostResponse, QuestionResponseModel, QuestionStaticResponse } from 'src/app/data-access/generated/challenge';
import { AuthDialogService, AuthDialogType } from 'src/app/features/auth/util/auth-dialog';
import { AuthState } from 'src/app/features/auth/store';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadingState, MyResponseModel } from 'src/app/data-access/missing.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PageViewEvent, SegmentEvent, SegmentUserEvent } from 'src/app/util/services/constants/segment-event.constant';
import { precheckResponse as preCheckResponse } from 'src/app/util/custom-rxjs';
import { QuizComponentStore } from './quiz.store';
import { QuizStatisticDialogComponent } from '../quiz-statistic-dialog/quiz-statistic-dialog.component';
import { RxState } from '@rx-angular/state';
import { SegmentTrackingService } from 'src/app/util/services/segment.service';
import { Store } from '@ngxs/store';
import { TRYME_MODAL_CONFIG } from 'src/app/features/base/nz-root';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class QuizComponent {
  private _post: PostResponse
  @Input() set post(value: PostResponse) {
    this._post = value
    this.componentStore.set({ post: value })
  }
  get post() {
    return this._post
  }

  @Input() questions: Array<QuestionResponseModel>
  @Input() color: ColorModel

  @Output() changePost = new EventEmitter<PostResponse>()
  @Output() onShare = new EventEmitter<void>()

  readonly questionTypeEnum = QuestionResponseModel.TypeEnum
  selectedAnswerId: string = null
  randomNumberControl = new FormControl()
  loading$ = this.state.select('loading')
  answerControl = new FormControl('')

  toBackgroundStyle = (color: ColorModel) => {
    if (!color) return '#fd2a2a20'
    if (color.background.length === 1) return color.background[0]
    return `linear-gradient(180deg, ${color ? color.background.join() : ''})`
  }

  constructor(
    private challengeService: ChallengeService,
    private nzMessageService: NzMessageService,
    private nzModalService: NzModalService,
    private state: RxState<LoadingState>,
    private store: Store,
    private authDialogService: AuthDialogService,
    private componentStore: QuizComponentStore,
    private segmentTrackingService: SegmentTrackingService
  ) {}

  onClickSend(question: QuestionResponseModel) {
    const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn)
    if (!isLoggedIn) {
      this.segmentTrackingService.track(SegmentEvent.RequestLogin, {
        request: PageViewEvent.Quiz,
        action: SegmentUserEvent.JoinQuiz,
        eventRequest: SegmentUserEvent.JoinQuiz
      })
      return this.authDialogService.openDialog({ type: AuthDialogType.LOGIN })
    }
    this.state.set({ loading: true })
    let answers: AnswerDTO[] = []
    if (question.type === QuestionResponseModel.TypeEnum.Choice)
      answers = [{ _id: this.selectedAnswerId, questionId: question._id }]
    else answers = [{ questionId: question._id, answer: this.answerControl.value }]

    this.challengeService
      .challengeControllerJoinQuiz({
        joinQuizDTO: {
          postId: this.post._id,
          answers,
          random: parseInt(this.randomNumberControl.value) ?? undefined
        }
      })
      .pipe(preCheckResponse())
      .subscribe(
        (response: MyResponseModel<QuestionStaticResponse>) => {
          this.state.set({ loading: false })
          this.answerControl.setValue('')
          this.updatePost(response.data)
        },
        error => {
          this.state.set({ loading: false })
          this.nzMessageService.error(error.message)
        }
      )
  }

  onRandom() {
    this.segmentTrackingService.track(SegmentEvent.RandomNumberQuiz, {
      postId: this.post._id,
      challengeId: this.post.challengeId,
      action: SegmentUserEvent.JoinQuiz
    })
    const number =
      Math.floor(
        Math.random() *
          (this.post.challenge.giftConfig.endNumber - this.post.challenge.giftConfig.startNumber)
      ) + this.post.challenge.giftConfig.startNumber
    this.randomNumberControl.setValue(number)
  }

  onViewStatistic() {
    this.nzModalService.create<QuizStatisticDialogComponent, void>({
      nzContent: QuizStatisticDialogComponent,
      ...TRYME_MODAL_CONFIG,
      nzWidth: 706,
      nzCentered: true,
      nzComponentParams: {
        postId: this.post._id,
        joined: this.post.joined
      }
    })
  }

  private updatePost(joinResponse: QuestionStaticResponse) {
    this.post = { ...this.post, ...joinResponse.postReaction, isJoined: true }
    this.post.questions = joinResponse.questions.map((q, index) => ({
      ...this.post.questions[index],
      ...q
    }))
    this.changePost.emit(this.post)
  }
}
