<header class="mb-10 text-center">
  <h1 class="leading-none text-[32px] mb-2">Tạo mới thử thách</h1>
  <p class="text-gray-500 text-base leading-6 mb-0">Chọn cách mà bạn muốn thử thách cộng đồng</p>
</header>

<main class="px-6 space-x-8 mb-2 flex justify-between">
  <section>
    <article>
      <h4>Video/Hình ảnh</h4>
      <p>Sed varius faucibus sit malesuada fermentum in lacus, et hendrerit ultrices curabitur</p>
      <img
        src="assets/images/challenge/challenge-create-video.png"
        alt="Create challenge with video/image"
      />
    </article>
    <button nz-button nzType="primary" nzSize="large" routerLink="/challenge/create" (click)="nzModalRef.close()">
      Tạo mới
    </button>
  </section>
  <section>
    <article>
      <h4>Hỏi - Đáp</h4>
      <p>Sed varius faucibus sit malesuada fermentum in lacus, et hendrerit ultrices curabitur</p>
      <img
        src="assets/images/challenge/challenge-create-question.png"
        alt="Create challenge with video/image"
      />
    </article>
    <button nz-button nzType="primary" nzSize="large" routerLink="/challenge/create-question" (click)="nzModalRef.close()">
      Tạo mới
    </button>
  </section>
</main>
